import { Dropdown, Menu, PageHeader, Space } from 'antd';
import React from 'react';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import _get from 'lodash/get';
import moment from 'moment';
import { MenuOutlined } from '@ant-design/icons';
import { useTableUtil } from '../../../core/contexts';
import { useQuery } from 'react-query';
import { IResponseList } from '../../../core/models';
import { activityApi } from '../../../core/apis';
import { useNavigate, useParams } from 'react-router-dom';
import { saleRouter } from '../../../core/routes';

interface SaleLogActivityByEmployerIdProps {}

export const SaleLogActivityByEmployerId: React.FC<SaleLogActivityByEmployerIdProps> = () => {
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const { employerId = '' } = useParams<{ employerId: string }>();
	const navigate = useNavigate();
	const employerQuery = useQuery<IResponseList<any>>(
		[
			'employer-activity',

			{
				limit,
				page,
				filters,
			},
		],
		async () => {
			const { data } = await activityApi.v1Paging(employerId, {
				limit,
				page,
				...filters,
			});
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);

			setTotalItems(totalItems);
			return data.data;
		}
	);
	React.useEffect(() => {
		document.title = 'Sale - Log Active Employer Management';
	}, []);
	return (
		<>
			<div className='fade-in'>
				<PageHeader title='Activity Management' extra={[]} />
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Date</div>,
								width: 200,
								dataIndex: 'employer.emai',
								key: 'employer.emai',
								render: (text, record) => {
									const data = _get(record, 'date', 'Empty');

									return <>{data}</>;
								},
							},

							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY HH:MM:SS')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 50,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																navigate(
																	saleRouter.logActivityById.href({
																		employerId,
																		id: record.id,
																	})
																);
															}}
														>
															View Detail
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={employerQuery.data?.data || []}
						isLoading={employerQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
