import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';
import { CvAppliedCandidatesApi } from '../../../../../core/apis/cvApplied.api';
import { CONSTANT } from '../../../../../core/constants';
import { useModal } from '../../../../../core/contexts';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const UpdateFilePdfCVModal = () => {
	const updateProfilePdfMethods = useModal<any>('updateFilePdfCV');
	const [pdfFile, setPdfFile] = useState<any>(null);
	const [pdfFileError, setPdfFileError] = useState('');
	const [numPages, setNumPages] = useState<number>(0);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [filesCV, setFilesCV] = useState<any>(null);

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
	};

	const handlePdfFileChange = (e) => {
		const selectedFile = e.target.files[0];
		setFilesCV(selectedFile);
		if (selectedFile) {
			if (selectedFile.type === 'application/pdf') {
				const reader = new FileReader();
				reader.onloadend = () => {
					const base64Data = reader.result;
					setPdfFile(base64Data);
					setPdfFileError('');
				};
				reader.onerror = () => {
					setPdfFileError('Error occurred while reading file');
				};
				reader.readAsDataURL(selectedFile);
			} else {
				setPdfFileError('Please select a valid PDF file');
			}
		}
	};

	const handlePdfFileSubmit = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('file', filesCV);

		if (pdfFile !== null && updateProfilePdfMethods.modalValue?.submittedProfileUuid) {
			try {
				await CvAppliedCandidatesApi.v1UpdateCvPDFApply(
					updateProfilePdfMethods.modalValue?.submittedProfileUuid,
					formData
				);
				toast.success('Update PDF success');
				updateProfilePdfMethods.handleCloseModal();
				setPdfFile('');
				setPdfFileError('');
				window.location.reload();
			} catch (error: any) {
				toast.error('Update PDF error');
			}
		} else {
			setPdfFileError('No PDF file selected');
		}
	};

	const handleDownloadCV = async () => {
		if (updateProfilePdfMethods.modalValue?.path) {
			const response = await fetch(`${CONSTANT.IMAGE}${updateProfilePdfMethods.modalValue?.path}`);
			const blob = await response.blob();
			const blobURL = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = blobURL;
			link.setAttribute(
				'download',
				`${updateProfilePdfMethods.modalValue?.full_name.replaceAll(' ', '-') || 'CV'}.pdf`
			);
			document.body.appendChild(link);
			link.click();
		}
	};

	return (
		<Modal
			title={updateProfilePdfMethods.modalValue?.full_name || ''}
			visible={updateProfilePdfMethods.getIsOpen()}
			footer={null}
			centered
			onCancel={() => updateProfilePdfMethods.handleCloseModal()}
			width={'80%'}
		>
			<div className='container'>
				<h4 style={{ margin: '10px 0', fontWeight: 'bold' }}>Download CV Apply</h4>
				<button className='ant-btn ant-btn-default' onClick={handleDownloadCV}>
					Download CV {updateProfilePdfMethods.modalValue?.full_name}
				</button>
				<form className='form-group' onSubmit={handlePdfFileSubmit}>
					<h4 style={{ margin: '40px 0 10px 0', fontWeight: 'bold' }}>Upload new CV</h4>
					<input type='file' className='form-control' required onChange={handlePdfFileChange} />
					{pdfFileError && <div className='error-msg'>{pdfFileError}</div>}
					<button
						style={{
							display: 'block',
							marginTop: 20,
							background: '#40a9ff',
							color: '#fff',
							border: 0,
							padding: '8px 20px',
							borderRadius: 4,
						}}
						type='submit'
					>
						Save CV
					</button>
				</form>
				<div className='pdf-container'>
					{pdfFile && (
						<>
							<h4 style={{ margin: '20px 0', fontWeight: 'bold' }}>View PDF</h4>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<button onClick={() => setPageNumber((prev: number) => (prev > 1 ? prev - 1 : 1))}>
									<ArrowLeftOutlined />
								</button>
								<p style={{ margin: '0 10px' }}>
									{pageNumber}/{numPages}
								</p>
								<button
									onClick={() => setPageNumber((prev: number) => (prev >= Number(numPages) ? numPages : prev + 1))}
								>
									<ArrowRightOutlined />
								</button>
							</div>
							<Document
								file={pdfFile}
								onLoadSuccess={onDocumentLoadSuccess}
								className='pdf-document'
								onLoadError={() => {
									setPdfFileError('Failed to load PDF file');
								}}
							>
								<Page pageNumber={pageNumber} className='pdf-page' />
							</Document>
						</>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default UpdateFilePdfCVModal;
