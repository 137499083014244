import { Button, Drawer } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IV1UpdateGroupMember, groupMailApi } from '../../../../core/apis';
import { TextInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import { GroupMailMember } from '../../../../core/models';

const defaultValues: IV1UpdateGroupMember = {
	email: '',
	name: '',
	memberUuid: '',
};

interface UpdateGroupMailMemberDrawerProps {}

export const UpdateGroupMailMemberDrawer: React.FC<UpdateGroupMailMemberDrawerProps> = () => {
	const updateGroupMailMemberMethods = useModal<GroupMailMember>('updateGroupMailMember');
	const formMethods = useForm<IV1UpdateGroupMember>({
		defaultValues,
	});
	const params = useParams<{
		groupMailId: string;
	}>();

	React.useEffect(() => {
		if (updateGroupMailMemberMethods.modalValue) {
			formMethods.setValue('memberUuid', updateGroupMailMemberMethods.modalValue.uuid);
			formMethods.setValue('name', updateGroupMailMemberMethods.modalValue.name);
			formMethods.setValue('email', updateGroupMailMemberMethods.modalValue.email);
		}
	}, [updateGroupMailMemberMethods.modalValue]);

	const addGroupMailMemberMutation = useMutation(
		(data: IV1UpdateGroupMember) => {
			return groupMailApi.v1UpdateGroupMember(params.groupMailId || '', data);
		},
		{
			onSuccess: () => {
				toast.success('Update Group Mail Member Success');
				updateGroupMailMemberMethods.handleCloseModal();
			},
			onError: () => {
				toast.error('Update Group Mail Member Failed, Please Try Again Later');
			},
		}
	);

	return (
		<Drawer
			title='Update Member'
			open={updateGroupMailMemberMethods.getIsOpen()}
			onClose={updateGroupMailMemberMethods.handleCloseModal}
		>
			<FormProvider {...formMethods}>
				<form
					className='space-y-4'
					onSubmit={formMethods.handleSubmit((data) => {
						addGroupMailMemberMutation.mutate(data);
					})}
				>
					<TextInput name='name' label='Name' />
					<TextInput name='email' label='Email' />
					<Button type='primary' htmlType='submit' loading={addGroupMailMemberMutation.isLoading}>
						Update Member
					</Button>
				</form>
			</FormProvider>
		</Drawer>
	);
};
