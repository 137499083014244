import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthUser } from '../../contexts';

export const AuthRouterProtection: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const auth = useAuthUser();
	const navigate = useNavigate();
	React.useEffect(() => {
		if (!auth.isTryLogin) {
			auth.getCurrentUser();
		}
	}, [auth.isTryLogin, auth.currentUser, navigate]);

	return <>{children}</>;
};
