import { Space, Spin } from 'antd';
import React from 'react';
import './loading.css';
const LoadingComponents: React.FC = () => (
	<Space direction='vertical' style={{ width: '100%' }}>
		<Space>
			<Spin tip='Loading' size='large'>
				<div className='content' />
			</Spin>
		</Space>
	</Space>
);

export default LoadingComponents;
