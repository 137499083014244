/* eslint-disable @typescript-eslint/no-unused-vars */
import BlotFormatter from 'quill-blot-formatter';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CompanyApi } from '../../apis/company.api';
import { CONSTANT } from '../../constants';
import { FieldWrapper } from './FieldWrapper';

Quill.register('modules/blotFormatter', BlotFormatter);

interface InputRichEditorProps {
	name: string;
	label: string;
	disabled?: boolean;
	className?: string;
	placeholder?: string;
}

export const InputRichEditor: React.FC<InputRichEditorProps> = ({
	name,
	label,
	className = '',
	disabled,
	placeholder,
}) => {
	const editorRef = React.useRef<ReactQuill>(null);
	const [newVal, setNewVal] = React.useState('');

	const {
		control,
		formState: { errors },
		setValue,
		getValues,
	} = useFormContext();
	React.useEffect(() => {
		if (getValues(name)) {
			setNewVal(getValues(name) as string);
		}
	}, []);
	const handleImageUpload = (editor: any) => {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.click();

		input.onchange = () => {
			const file = input.files?.[0];

			if (file) {
				const reader = new FileReader();
				reader.onload = async (e) => {
					const range = editor.getSelection();

					if (input?.files && input.files[0]) {
						const file = input.files[0];
						const res = await CompanyApi.v1UploadImage(file);
						const imageUrl = CONSTANT.IMAGE + res.data;

						if (/^image\//.test(file.type)) {
							if (range) editor.insertEmbed(range.index, 'image', `${imageUrl}`);
						} else {
							alert('You could only upload images');
						}
					}
				};

				reader.readAsDataURL(file);
			}
		};
	};
	React.useEffect(() => {
		if (editorRef.current) {
			const editor = editorRef.current.getEditor();

			editor.getModule('toolbar').addHandler('image', () => {
				handleImageUpload(editor);
			});
		}
	}, []);
	return (
		<div className='mb-4'>
			<FieldWrapper name={name} label={label}>
				<Controller
					name={name}
					control={control}
					render={({ field: { value } }) => {
						return (
							<ReactQuill
								modules={{
									toolbar: [
										[{ header: [1, 2, 3, 4, false] }],
										[{ header: 1 }, { header: 2 }, { font: [] }], // custom button values
										[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
										[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
										[{ size: ['small', 'large', 'huge'] }], // custom dropdown
										[{ color: ['black'] }], // dropdown with defaults from theme
										['bold', 'italic', 'underline', 'strike', 'blockquote'],
										[{ list: 'ordered' }, { list: 'bullet' }],
										['link', 'image', 'video'],
										['clean'], // remove formatting button
									],
									blotFormatter: {
										// see config options below
									},
									// handlers: {
									//   image: this.quillImageCallback,
									// },
									clipboard: {
										matchVisual: false,
									},
								}}
								className={className}
								theme='snow'
								value={value?.replace(/&lt;/g, '<')?.replace(/\/&gt;/g, '/>')}
								onChange={(e: string) => {
									if (e == '<p><br></p>') {
										setValue(name, '');
									} else {
										setValue(name, e);
									}
								}}
								formats={[
									'script',
									'header',
									'bold',
									'italic',
									'underline',
									'strike',
									'code-block',
									'blockquote',
									'list',
									'bullet',
									'indent',
									'link',
									'image',
									'size',
									'color',
									'background',
									'width',
									'style',
									'align',
									'font',
									'data-align',
								]}
								ref={editorRef}
								readOnly={disabled}
								placeholder={placeholder}
							/>
						);
					}}
				/>
			</FieldWrapper>
		</div>
	);
};
