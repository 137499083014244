import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1StaffCreate, staffApi } from '../../../../core/apis/staff.api';
import { SelectInput, TextInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import { UserRole } from '../../../../core/models';
import { optionsUserRole } from '../models';

const defaultValues: IV1StaffCreate = {
	email: '',
	phone: '',
	role: UserRole.SALE,
	last_name: '',
	first_name: '',
};

const schema = yup.object().shape({
	email: yup.string().email().required('Email is required'),
	phone: yup.string().max(10).required('Phone is required'),
	role: yup.string().required('Role is required'),
	last_name: yup.string().required('Last name is required'),
	first_name: yup.string().required('First name is required'),
});

interface CreateSaleEmployerDrawerProps {}

export const CreateSaleEmployerDrawer: React.FC<CreateSaleEmployerDrawerProps> = () => {
	const createModalMethod = useModal('create');
	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});
	const createStaffMutation = useMutation((data: IV1StaffCreate) => staffApi.v1Create(data), {
		onSuccess: () => {
			toast.success('Create sale employer successfully');
			formMethods.reset({ ...defaultValues });
			createModalMethod.handleCloseModal();
		},
		onError: () => {
			toast.error('Create sale employer is already exist');
		},
	});

	const _handleOnSubmit = (data: IV1StaffCreate) => createStaffMutation.mutate(data);

	return (
		<Drawer
			open={createModalMethod.getIsOpen()}
			title='Create Sale Employer'
			onClose={() => createModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='email' label='Email' />
							<TextInput name='last_name' label='Last Name' />
							<TextInput name='first_name' label='First Name' />
							<TextInput name='phone' label='Phone' />
							<SelectInput name='role' label='Role' options={optionsUserRole} />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Create
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
