import Table, { ColumnsType } from 'antd/lib/table';
import { useTableUtil } from '../../contexts';
interface TableBuilderProps<T extends object> {
	data: T[];
	columns: ColumnsType<T>;
	isLoading: boolean;
	rowKey: string;
	size?: 'small' | 'middle' | 'large';
	isShowPagination?: boolean;
	rowClassName?: any;
}

export const TableBuilder = <T extends object>({
	data,
	columns,
	rowKey,
	isLoading,
	size = 'large',
	isShowPagination = true,
	rowClassName,
}: TableBuilderProps<T>) => {
	const { totalItems, page, handleOnChangePage } = useTableUtil();
	return (
		<Table
			rowKey={rowKey}
			dataSource={data}
			columns={columns}
			size={size}
			loading={isLoading}
			pagination={
				isShowPagination
					? {
							total: totalItems,
							current: Number(page),
							defaultPageSize: 50,
					  }
					: false
			}
			onChange={(pagination) => {
				handleOnChangePage(pagination.current || 0, pagination.pageSize || 50);
			}}
			rowClassName={rowClassName}
			scroll={{ x: 1100 }}
		/>
	);
};
