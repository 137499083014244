import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Modal, Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1AddPermissionUser, ProfileFindNewApi } from '../../../../core/apis/cvManagement.api';
import { SelectInput, TextInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import { adminRouter } from '../../../../core/routes';
import { optionsEditPermissionModule, optionsPermissionAction, optionsPermissionModule } from '../models';

interface SetPermissionModalProps {}

export const SetPermissionModal: React.FC<SetPermissionModalProps> = () => {
	const navigate = useNavigate();
	const modalMethods = useModal<any>('setPermission');
	const [valueType, setValueType] = useState(1);
	const typeInput = [
		{
			label: 'List',
			value: 1,
		},
		{
			label: 'Edit',
			value: 2,
		},
	];
	const onChange = (e: RadioChangeEvent) => {
		setValueType(e.target.value);
	};
	const defaultValues = {
		userUuid: modalMethods?.modalValue?.uuid || '',
		module: '',
		action: '',
	};
	const schema = yup.object().shape({
		userUuid: yup.string().required('required'),
	});
	const formMethods = useForm<any>({
		defaultValues: defaultValues,
		resolver: yupResolver(schema),
	});
	const mutation = useMutation((input: IV1AddPermissionUser) => ProfileFindNewApi.v1AddPermissionUser(input), {
		onSuccess: () => {
			navigate(adminRouter.permissionCv.href());
			toast.success('success');
		},
		onError: (error: any) => {
			toast.error(error?.response?.data?.message);
		},
	});
	const _handleOnSubmit = async (input: IV1AddPermissionUser) => mutation.mutate(input);
	useEffect(() => {
		if (modalMethods?.modalValue?.uuid) {
			formMethods.setValue('userUuid', modalMethods?.modalValue?.uuid);
		}
	}, [modalMethods?.modalValue?.uuid]);
	useEffect(() => {
		if (valueType) {
			formMethods.setValue('module', '');
			if (valueType == 1) {
				formMethods.setValue('action', 'GET_ALL');
			} else {
				formMethods.setValue('action', 'EDIT');
			}
		}
	}, [valueType]);
	return (
		<Modal
			title='Set Permission Cv'
			open={modalMethods.getIsOpen()}
			footer={null}
			centered
			onCancel={() => modalMethods.handleCloseModal()}
		>
			<FormProvider {...formMethods}>
				<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)} className='space-y-4'>
					<TextInput name='userUuid' label='User Id' />
					<Radio.Group onChange={onChange} value={valueType}>
						{typeInput.map((value) => {
							return <Radio value={value.value}>{value.label}</Radio>;
						})}
					</Radio.Group>
					<SelectInput
						name='module'
						label='Module'
						options={valueType == 1 ? optionsPermissionModule : optionsEditPermissionModule}
					/>
					<SelectInput name='action' label='Action' options={optionsPermissionAction} />
					<Button loading={mutation.isLoading} htmlType='submit'>
						Add
					</Button>
				</form>
			</FormProvider>
		</Modal>
	);
};
