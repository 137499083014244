import { stringify } from 'query-string';
import { IPagingProps } from '../models';
import { Employer } from '../models/employer';
import { http } from './http';

export interface IV1EmployerPagingFilters {
	status: string;
	scale: string;
	type: string;
	employerEmail: string;
	employerPhone: string;
	isActive: boolean;
}

export interface IV1EmployerPaging extends IPagingProps<IV1EmployerPagingFilters> {}
export interface IV1EmployerJoinGroup {
	groupUuid: string;
}

export interface IV1EmployerAssignToSale {
	groupUuid: string;
	saleUuid: string;
}
export interface IV1UpdateEmployer extends Pick<Employer, 'type' | 'scale' | 'other_note' | 'status' | 'callNote'> {}
export interface IV1SaleAdminUpdateEmployer extends Pick<Employer, 'other_note' | 'status'> {}

export const saleSystemEmployerApi = {
	v1Paging: async (query: IV1EmployerPaging) => {
		const res = await http.get(
			`/v1/api/sale/employers/paging?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res.data;
	},
	v1GetById: async (employerId: string) => {
		const res = await http.get(`/v1/api/sale/employers/${employerId}`);
		return res.data;
	},
	v1UpdateById: async (employerId: string, data: IV1UpdateEmployer) => {
		const res = await http.put(`/v1/api/sale/employers/${employerId}`, data);
		return res.data;
	},
	v1JoinGroup: async (employerId: string, data: IV1EmployerJoinGroup) => {
		const res = await http.put(`/v1/api/sale/employers/${employerId}`, data);
		return res.data;
	},
	v1AssignToSale: async (employerId: string, data: IV1EmployerAssignToSale) => {
		const res = await http.put(`/v1/api/sale/employers/${employerId}`, data);
		return res.data;
	},
	v1LeaveGroupById: async (employerId: string, groupId: string) => {
		const res = await http.delete(`/v1/api/sale/employers/${employerId}/group/${groupId}`);
		return res;
	},
};
