/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuOutlined, PlusCircleOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Space } from 'antd';
import _, { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { dataSaleApi } from '../../../core/apis/dataSale';
import { TextInput } from '../../../core/components/form';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal, useTableUtil } from '../../../core/contexts';
import { IResponseList } from '../../../core/models';
import { adminRouter } from '../../../core/routes';
import AddOrUpdateDataModal from './components/AddOrUpdateDataModal';

interface DataSaleManagementProps {}

export const DataSaleManagement: React.FC<DataSaleManagementProps> = () => {
	const uploadFileRef: any = useRef(null);
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { limit, page, setTotalItems, filters, setFilters, setPage } = useTableUtil();
	const addOrUpdateDataModal = useModal<any>('addOrUpdateData');
	const [loadingImport, setLoadingImport] = useState(false);
	const debouncedSetFilters = useCallback(
		debounce((_filters) => {
			setFilters(_filters);
			setPage('1');
		}, 500),
		[]
	);
	const dataSaleQuery = useQuery<IResponseList<any>>(
		[
			'employerPaging',

			{
				limit,
				page,
				filters,
			},
		],
		async () => {
			const queryParams = { ...filters, ...searchParams, page, limit };
			setSearchParams({ ...queryParams });

			const { data } = await dataSaleApi.v1Paging({
				page,
				limit,
				...filters,
			});
			const totalItems: number = data.data.meta.totalItems || 0;
			setTotalItems(totalItems);
			return data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const formMethods = useForm<any>({
		defaultValues: {
			'filter.lead.companyName': '',
			'filter.lead.email': '',
			'filter.lead.phone': '',
		},
	});
	const handleUploadFile = async (e: any) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('file', file);

		if (typeof file === 'undefined') return;

		const fileType = [
			'application/msword',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		];
		if (fileType?.includes(file?.type)) {
			setLoadingImport(true);
			try {
				const { data } = await dataSaleApi.v1Import(formData);
				dataSaleQuery.refetch();
				toast.success(data?.message || '');
				setLoadingImport(false);
			} catch (error: any) {
				setLoadingImport(false);
				toast.error(error?.response?.data?.message || 'error');
			}
		}
	};

	const handleDelete = async (id: string) => {
		try {
			const { data } = await dataSaleApi.v1DeleteById(id);
			setFilters({});
			dataSaleQuery.refetch();
			toast.success(data.message);
		} catch (error: any) {
			toast.error(error?.response?.data?.message || 'error');
		}
	};

	useEffect(() => {
		const params = {};

		searchParams.forEach((value, key) => {
			params[key] = value;
		});

		const filterCompanyName = params['filter.companyName']?.replace('$ilike:', '') || '';
		const filterEmail = params['filter.email']?.replace('$ilike:', '') || '';
		const filterPhone = params['filter.phone']?.replace('$ilike:', '') || '';
		const filterSource = params['filter.source']?.replace('$ilike:', '') || '';
		const filterDataType = params['filter.dataType']?.replace('$ilike:', '') || '';
		const filterPicName = params['filter.picName']?.replace('$ilike:', '') || '';

		formMethods.setValue('filter.companyName', filterCompanyName);
		formMethods.setValue('filter.email', filterEmail);
		formMethods.setValue('filter.phone', filterPhone);
		formMethods.setValue('filter.source', filterSource);
		formMethods.setValue('filter.dataType', filterDataType);
		formMethods.setValue('filter.picName', filterPicName);

		setFilters(params);
	}, []);

	useEffect(() => {
		const _filters = {
			...filters,
			'filter.companyName': formMethods.watch('filter.companyName')
				? `$ilike:${formMethods.watch('filter.companyName')}`
				: '',
			'filter.email': formMethods.watch('filter.email') ? `$ilike:${formMethods.watch('filter.email')}` : '',
			'filter.phone': formMethods.watch('filter.phone') ? `$ilike:${formMethods.watch('filter.phone')}` : '',
			'filter.source': formMethods.watch('filter.source') ? `$ilike:${formMethods.watch('filter.source')}` : '',
			'filter.dataType': formMethods.watch('filter.dataType') ? `$ilike:${formMethods.watch('filter.dataType')}` : '',
			'filter.picName': formMethods.watch('filter.picName') ? `$ilike:${formMethods.watch('filter.picName')}` : '',
		};

		if (!_.isEqual(_filters, filters)) {
			debouncedSetFilters(_filters);
		}
	}, [
		formMethods.watch('filter.companyName'),
		formMethods.watch('filter.email'),
		formMethods.watch('filter.phone'),
		formMethods.watch('filter.source'),
		formMethods.watch('filter.dataType'),
		formMethods.watch('filter.picName'),
	]);

	useEffect(() => {
		document.title = 'Data Sale Management';
	}, []);
	return (
		<>
			{addOrUpdateDataModal.getIsOpen() && <AddOrUpdateDataModal refetchData={() => dataSaleQuery.refetch()} />}
			<div className='fade-in mb-5'>
				<PageHeader
					title='Data Sale Management'
					extra={[
						<Button
							key={'upload'}
							icon={<UploadOutlined />}
							onClick={() => uploadFileRef.current.click()}
							loading={loadingImport}
						>
							Import
							<input
								id='chooseFileLocal'
								type='file'
								onChange={handleUploadFile}
								ref={uploadFileRef}
								accept='.xlsx, .xls, .csv'
								className='opacity-0 invisible fixed'
							/>
						</Button>,
						<Button
							key='1'
							icon={<PlusCircleOutlined />}
							type='primary'
							onClick={() => {
								addOrUpdateDataModal.handleOpenModal({});
							}}
						>
							Add new data
						</Button>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>No.</div>,
								width: 50,
								dataIndex: 'no',
								key: 'no',
								render: (text, record, index) => {
									return <>{index + 1}</>;
								},
							},
							{
								title: () => <div>Company Name</div>,
								width: 300,
								dataIndex: 'companyName',
								key: 'companyName',
								render: (value, record) => {
									return <>{value ?? '-'}</>;
								},
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput
													name='filter.companyName'
													label='Company'
													placeholder='Company...'
													className='w-52'
												/>
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Email</div>,
								width: 300,
								dataIndex: 'email',
								key: 'email',
								align: 'center',
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput name='filter.email' label='Email' placeholder='Email...' className='w-52' />
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Phone</div>,
								width: 150,
								dataIndex: 'phone',
								key: 'phone',
								render: (value, record) => {
									return <>{value ?? '-'}</>;
								},
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput name='filter.phone' label='Phone' placeholder='Phone...' className='w-52' />
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Other Phone</div>,
								width: 100,
								dataIndex: 'otherPhone',
								key: 'otherPhone',
								render: (value, record) => {
									return <>{value ?? '-'}</>;
								},
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput
													name='filter.otherPhone'
													label='Other Phone'
													placeholder='Other phone...'
													className='w-52'
												/>
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Source</div>,
								dataIndex: 'source',
								key: 'source',
								render: (value, record) => {
									return <>{value ?? '-'}</>;
								},

								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput name='filter.source' label='Source' placeholder='Source...' className='w-52' />
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Data Type</div>,
								dataIndex: 'dataType',
								key: 'dataType',
								render: (value, record) => {
									return <>{value ?? '-'}</>;
								},

								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput
													name='filter.dataType'
													label='Data type'
													placeholder='Data type...'
													className='w-52'
												/>
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>PIC Name</div>,
								width: 100,
								dataIndex: 'picName',
								key: 'picName',
								render: (value, record) => {
									return <>{value ?? '-'}</>;
								},

								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput name='filter.picName' label='PIC Name' placeholder='PIC Name..' className='w-52' />
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Note</div>,
								width: 150,
								dataIndex: 'note',
								key: 'note',
								render: (value, record) => {
									return <div className='max-h-[150px] overflow-y-auto'>{value ?? '-'}</div>;
								},
							},
							{
								title: () => <div>Note mail</div>,
								width: 150,
								dataIndex: 'mailingNote',
								key: 'mailingNote',
								render: (value, record) => {
									return <div className='max-h-[150px] overflow-y-auto'>{value ?? '-'}</div>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 50,
								dataIndex: 'action',
								key: 'action',
								align: 'center',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																const params = {};

																searchParams.forEach((value, key) => {
																	params[key] = value;
																});

																navigate({
																	pathname: adminRouter.dataSaleManagementId.href(record.uuid),
																	search: createSearchParams({ ...params }).toString(),
																});
															}}
														>
															View Detail
														</Menu.Item>
														<Menu.Item
															onClick={() => {
																handleDelete(record?.uuid);
															}}
														>
															Delete
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={dataSaleQuery.data?.data || []}
						isLoading={dataSaleQuery.isLoading}
						rowKey='uuid'
						size='large'
					/>
				</div>
			</div>
		</>
	);
};
