import { MenuOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Popconfirm, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendMailManagemntApi } from '../../../core/apis/sendMail.api';
import { TextInput } from '../../../core/components/form';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal, useTableUtil } from '../../../core/contexts';
import { EMailTemplate } from '../../../core/models/sendMail';
import { adminRouter } from '../../../core/routes';
import { CreateMailDrawer } from './createMail';
// import { CreateGroupMailDrawer } from './components/CreateGroupMailDrawer';

interface MailManagementPageProps {}

export const MailManagementPage: React.FC<MailManagementPageProps> = () => {
	const navigate = useNavigate();
	const [search, setSearch] = React.useState('');
	const [email, setEmail] = React.useState('');
	const createGroupMailMethods = useModal('createMailer');
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const mailerCampaignsQuery = useQuery(
		['mailerCampaigns', { limit, page, filters, search, email }, createGroupMailMethods.getIsOpen()],
		async () => {
			const { data } = await sendMailManagemntApi.v1MailerPaging({
				limit,
				page,
				search,
				email,
				...filters,
			});
			const totalItems: number = data.meta.totalItems;
			setTotalItems(totalItems);
			return data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const deleteMailerMutation = useMutation((mailerId: string) => sendMailManagemntApi.v1MailerDelete(mailerId), {
		onSuccess: () => {
			toast.success('Delete mail successfully!');
			mailerCampaignsQuery.refetch();
		},
		onError: () => {
			toast.error('Delete mail failed!');
		},
	});
	const formMethods = useForm<any>({
		defaultValues: {
			search: '',
		},
	});
	React.useEffect(() => {
		setSearch(formMethods.watch('search'));
		setEmail(formMethods.watch('email'));
	}, [formMethods.watch('search'), formMethods.watch('email')]);
	React.useEffect(() => {
		document.title = 'Mail Campaigns';
	}, []);
	return (
		<>
			<CreateMailDrawer />
			<div className='fade-in'>
				<PageHeader
					title='Mail Campaigns'
					extra={[
						<Button
							key='2'
							icon={<PlusCircleOutlined />}
							onClick={() => {
								createGroupMailMethods.handleOpenModal({});
							}}
						>
							Create Mail Campaigns
						</Button>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>No.</div>,
								width: 50,
								dataIndex: 'No',
								key: 'No',
								render: (text, record, index) => {
									return <>{index + 1}</>;
								},
							},
							{
								title: () => <div>Campaign Name</div>,
								width: 100,
								dataIndex: 'name',
								key: 'name',
								render: (text, record) => {
									const data = _get(record, 'name', 'Empty');

									return <>{data}</>;
								},
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput name='search' label='Search' placeholder='search..' className='w-52' />
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Description</div>,
								width: 200,
								dataIndex: 'description',
								key: 'description',
								render: (text, record) => {
									const data = _get(record, 'description', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Template name</div>,
								width: 200,
								dataIndex: 'template',
								key: 'template',
								render: (text, record) => {
									const data = _get(record, 'template', 'Empty') as any;
									const renderTemplate = (data: number) => {
										switch (data) {
											case EMailTemplate.INTRODUCE_NEW_FEATURE:
												return 'INTRODUCE NEW FEATURE';
											case EMailTemplate.INVITE_RECUIT:
												return 'INVITE RECUIT';
											case EMailTemplate.INTRODUCE_OLD_EMPLOYER:
												return 'INTRODUCE OLD EMPLOYER';
											case EMailTemplate.REMIND_CREATE_ACCOUNT:
												return 'REMIND CREATE ACCOUNT';
											case EMailTemplate.REMIND_USE_POINT:
												return 'REMIND USE POINT';
											case EMailTemplate.REMIND_WRITE_FORM:
												return 'REMIND WRITE FORM';
											case EMailTemplate.PRICE_TABLE_EMPLOYER:
												return 'PRICE TABLE EMPLOYER';
											case EMailTemplate.PRICE_TABLE_OLD_EMPLOYER:
												return 'PRICE TABLE OLD EMPLOYER';
											default:
												return '';
										}
									};

									return <div className='lowercase'>{renderTemplate(data)}</div>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 200,
								dataIndex: 'user.email',
								key: 'user.email',
								render: (text, record) => {
									const data = _get(record, 'user.email', 'Empty');

									return <>{data}</>;
								},
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput name='email' label='Email' placeholder='email..' className='w-52' />
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Created At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								align: 'center',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									const uuid = _get(record, 'uuid', '');
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																navigate(adminRouter.sendMailManagementById.href(uuid));
															}}
														>
															View Detail
														</Menu.Item>
														<Popconfirm
															title='Are you sure to delete this mail?'
															onConfirm={() => {
																deleteMailerMutation.mutate(uuid);
															}}
														>
															<Menu.Item danger>Delete</Menu.Item>
														</Popconfirm>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={mailerCampaignsQuery.data || []}
						isLoading={mailerCampaignsQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};

export default MailManagementPage;
