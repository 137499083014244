/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CvAppliedCandidatesApi } from '../../../../core/apis/cvApplied.api';
import { TableBuilder } from '../../../../core/components/table/TableBuilder';
import { TableUtilProvider, useAuthUser, useModal, useTableUtil } from '../../../../core/contexts';
import { IResponseList, UserRole } from '../../../../core/models';
import { adminRouter, saleAdminRouter, saleRouter } from '../../../../core/routes';
import AnswerById from './Answers';
import QuestionById from './Questions';
import { toast } from 'react-toastify';

interface CvAppliedProps {}

export const CvApplied: React.FC<CvAppliedProps> = () => {
	const navigate = useNavigate();
	const { currentUser } = useAuthUser();
	const { limit, page, setTotalItems, setPage } = useTableUtil();
	const QuestionMethods = useModal('questions');
	const AnsWerMethods = useModal('answers');
	const [search, setSearch] = useState('');
	const CvAppliedQuery = useQuery<IResponseList<any>>(
		[
			'cv',
			{
				limit,
				page,
				search,
			},
		],
		async () => {
			const { data } = await CvAppliedCandidatesApi.v1GetListWithAppiledCandidates(limit, page, search);
			const totalItems: number = data?.data?.meta?.totalItems || 0;
			setTotalItems(totalItems);
			setPage(data?.data?.meta?.currentPage || 0);
			return data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const handleCloneJob = async (id: string) => {
		try {
			const { data, message } = await CvAppliedCandidatesApi.v1CloneJob(id);
			CvAppliedQuery.refetch();
			toast.success(message || '');
		} catch (error: any) {
			toast.error(error?.data.message || 'error');
		}
	};

	React.useEffect(() => {
		document.title = 'Cv Applied';
	}, []);

	return (
		<>
			<TableUtilProvider>
				<QuestionById />
			</TableUtilProvider>
			<TableUtilProvider>
				<AnswerById />
			</TableUtilProvider>
			<div className='fade-in'>
				<PageHeader title='List Cv Applied Candidates' extra={[]} />
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>No</div>,
								width: 50,
								dataIndex: 'No',
								key: 'No',
								align: 'center',
								render: (text, record, index) => {
									return <>{index + 1}</>;
								},
							},
							{
								title: () => <div>Job Title</div>,
								width: 100,
								dataIndex: 'title',
								key: 'title',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'title', 'Empty');
									const uuid = _get(record, 'uuid', 'Empty');
									return (
										<a href={`https://jobfi.vn/job-detail/${uuid}`} target='_blank'>
											{data}
										</a>
									);
								},
							},
							{
								title: () => <div>Email</div>,
								width: 80,
								dataIndex: 'user.email',
								key: 'user.email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'user.email', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Number of CVs applied</div>,
								width: 50,
								dataIndex: 'profileCount',
								key: 'profileCount',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'profileCount', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Questions</div>,
								width: 50,
								align: 'center',
								dataIndex: 'questions',
								key: 'questions',
								render: (text, record) => {
									return record.exam ? (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																QuestionMethods.handleOpenModal(record.uuid);
															}}
															key={record.uuid}
														>
															View Questions
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									) : (
										'-'
									);
								},
							},
							{
								title: () => <div>Answers</div>,
								width: 50,
								align: 'center',
								dataIndex: 'answers',
								key: 'answers',
								render: (text, record) => {
									return record.exam ? (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																AnsWerMethods.handleOpenModal(record);
															}}
															key={record.uuid}
														>
															View Answers
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									) : (
										'-'
									);
								},
							},
							{
								title: () => <div>Question from candidate</div>,
								width: 50,
								align: 'center',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return record?.hasQnA ? <Space size='middle'>
										<Dropdown
											overlay={
												<Menu>
													<Menu.Item
														onClick={() => {
															switch (currentUser?.role) {
																case UserRole.SALE:
																	navigate(saleRouter.jobCommentId.href(record.uuid));
																	break;
																case UserRole.SALE_ADMIN:
																	navigate(saleAdminRouter.jobCommentId.href(record.uuid));
																	break;
																case UserRole.ADMIN_OF_SALE:
																	navigate(adminRouter.jobCommentId.href(record.uuid));
																	break;
																default:
																	break;
															}
														}}
														key={record.uuid}
													>
														View Detail
													</Menu.Item>
												</Menu>
											}
										>
											<a>
												<MenuOutlined />
											</a>
										</Dropdown>
									</Space> : <>-</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 50,
								align: 'center',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																switch (currentUser?.role) {
																	case UserRole.SALE:
																		navigate(saleRouter.jobDetailById.href(record.uuid));
																		break;
																	case UserRole.SALE_ADMIN:
																		navigate(saleAdminRouter.jobDetailById.href(record.uuid));
																		break;
																	case UserRole.ADMIN_OF_SALE:
																		navigate(adminRouter.jobDetailById.href(record.uuid));
																		break;
																	default:
																		break;
																}
															}}
															key={record.uuid}
														>
															View Detail
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
							{
								title: () => <div>Guarantee</div>,
								width: 50,
								dataIndex: 'guarantee',
								key: 'guarantee',
								align: 'center',
								render: (text, record) => {
									const isShow = record?.postedWithPayment && record?.status === 'J_EXPPIRED' && !record?.isClone;

									return <>{isShow ? <Button onClick={() => handleCloneJob(record.uuid)}>Clone</Button> : '-'}</>;
								},
							},
						]}
						data={CvAppliedQuery.data?.data || []}
						isLoading={CvAppliedQuery.isLoading}
						rowKey='uuid'
						rowClassName={(record) =>
							record?.postedWithPayment && record?.isClone === 1 ? 'clone-job' : record?.postedWithPayment ? 'cost' : ''
						}
					/>
				</div>
			</div>
		</>
	);
};
