import { IOptionItem } from './interface';

export enum ESaleOrder {
	BUY_CV = 'BUY_CV',
	POST_NEWS = 'POST_NEWS',
	SUGGEST = 'SUGGEST',
	POST_JOB = 'POST_JOB',
	TRADING = 'TRADING',
	TAG = 'TAG',
	BUY_APPLICATION = 'BUY_APPLICATION',
	JOB_EXTENSION = 'JOB_EXTENSION',
	JOB_GUARANTEE = 'JOB_GUARANTEE',
}
export const typeSaleOrder: IOptionItem[] = [
	{
		value: ESaleOrder.BUY_CV,
		label: 'Buy CV',
		data: ESaleOrder.BUY_CV,
	},
	{
		value: ESaleOrder.POST_NEWS,
		label: 'Post News',
		data: ESaleOrder.POST_NEWS,
	},
	{
		value: ESaleOrder.SUGGEST,
		label: 'Suggest',
		data: ESaleOrder.SUGGEST,
	},
	{
		value: ESaleOrder.POST_JOB,
		label: 'Post job',
		data: ESaleOrder.POST_JOB,
	},
	{
		value: ESaleOrder.TRADING,
		label: 'Trading',
		data: ESaleOrder.TRADING,
	},
	{
		value: ESaleOrder.TAG,
		label: 'Tag',
		data: ESaleOrder.TAG,
	},
	{
		value: ESaleOrder.BUY_APPLICATION,
		label: 'Buy Application',
		data: ESaleOrder.BUY_APPLICATION,
	},
	{
		value: ESaleOrder.JOB_EXTENSION,
		label: 'Job extend',
		data: ESaleOrder.JOB_EXTENSION,
	},
	{
		value: ESaleOrder.JOB_GUARANTEE,
		label: 'Post job guarantee',
		data: ESaleOrder.JOB_GUARANTEE,
	},
];