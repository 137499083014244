export const CompanySizeOptions = [
	{
		value: 'MIN_0_MAX_10',
		label: 'Từ 0 đến 10 nhân viên',
		data: 'MIN_0_MAX_10',
	},
	{
		value: 'MIN_10_MAX_50',
		label: 'Từ 10 đến 50 nhân viên',
		data: 'MIN_10_MAX_50',
	},
	{
		value: 'MIN_50_MAX_100',
		label: 'Từ 50 đến 100 nhân viên',
		data: 'MIN_50_MAX_100',
	},
	{
		value: 'MIN_100_MAX_500',
		label: 'Từ 100 đến 500 nhân viên',
		data: 'MIN_100_MAX_500',
	},
	{
		value: 'MIN_500_MAX_1000',
		label: 'Từ 500 đến 1000 nhân viên',
		data: 'MIN_500_MAX_1000',
	},
	{
		value: 'OVER_1000',
		label: 'Hơn 1000 nhân viên',
		data: 'OVER_1000',
	},
];

export interface CompanyProps {
	name: string;
	address: string;
	fileBusinessContract: File | undefined;
	taxNumber: string;
	email: string;
	hiringEmail: string;
	description: string;
	overview: string;
	companySize: string;
	cityId: string;
	wardId: string;
	districtId: string;
	industries: any[];
}
