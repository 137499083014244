/* eslint-disable @typescript-eslint/no-unused-vars */
import { stringify } from 'query-string';
import { IPagingProps } from '../models';
import { http } from './http';
import { CompanyProps } from '../../pages/admin/company/models';

export interface IV1CompanyFilters {}
export interface IV1Company extends IPagingProps<IV1CompanyFilters> {}

export const CompanyApi = {
	v1CompanyPaging: async (limit: any, page: any, search: string) => {
		const res = await http.get('/v1/api/company/admin/paging', {
			params: {
				limit,
				page,
				['search']: search,
			},
		});
		return res;
	},
	v1CompanyById: async (uuid: string) => {
		const res = await http.get(`/v1/api/company/${uuid}`);
		return res.data;
	},
	v1PostUploadBackgroundCompany: async (uuid: string, formData: any) => {
		return http.post(`/v1/api/company/background/${uuid}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},
	v1PostUploadLogoCompany: async (uuid: string, formData: any) => {
		return http.post(`/v1/api/company/logo/${uuid}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},
	v1UpdateCompany(uuid: string, objectSubmit: any) {
		const formData = new FormData();
		formData.append('name', objectSubmit.name);
		formData.append('nameEn', objectSubmit.nameEn);
		formData.append('cityId', objectSubmit.cityId);
		formData.append('districtId', objectSubmit.districtId);
		formData.append('wardId', objectSubmit.wardId);
		formData.append('address', objectSubmit.address);
		if (objectSubmit.fileBusinessContract) {
			formData.append('fileBusinessContract', objectSubmit.fileBusinessContract);
		}
		formData.append('hiringEmail', objectSubmit.hiringEmail);
		formData.append('taxNumber', objectSubmit.taxNumber);
		formData.append('email', objectSubmit.email);
		formData.append('description', objectSubmit.description);
		formData.append('companySize', objectSubmit.companySize);
		formData.append('overview', objectSubmit.overview);
		formData.append('industries', JSON.stringify(objectSubmit.industries));

		const url = `/v1/api/company/${uuid}`;
		return http.put(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},
	v1UploadImage(file: File) {
		const formData = new FormData();
		formData.append('file', file);
		const res = http.post('/v1/api/news/upload-banner', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		return res as any;
	},
};
