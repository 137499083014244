import { IOptionItem } from '../../../../core/models';

export enum EPermissionModule {
  CV = 'CV',
  JOB_LIST = 'JOB_LIST',
  COMPANY = 'COMPANY',
}

export enum EPermissionAction {
  GET_ALL = 'GET_ALL',
  GET_ONE = 'GET_ONE',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export const optionsPermissionModule: IOptionItem[] = [
  {
    data: EPermissionModule.CV,
    label: 'SEE LIST CV',
    value: EPermissionModule.CV,
  },
  {
    data: EPermissionModule.JOB_LIST,
    label: 'SEE JOB LIST',
    value: EPermissionModule.JOB_LIST,
  },
];

export const optionsEditPermissionModule: IOptionItem[] = [

  {
    data: EPermissionModule.COMPANY,
    label: 'EDIT COMPANY',
    value: EPermissionModule.COMPANY,
  },
];

export const optionsPermissionAction: IOptionItem[] = [
  {
    data: EPermissionAction.GET_ALL,
    label: 'GET_ALL',
    value: EPermissionAction.GET_ALL,
  },
  {
    data: EPermissionAction.EDIT,
    label: 'EDIT',
    value: EPermissionAction.EDIT,
  },
];
