import { FilterOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Space, Tag } from 'antd';
import _get from 'lodash/get';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userApi } from '../../../core/apis';
import { whiteListApi } from '../../../core/apis/whileList.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal, useTableUtil } from '../../../core/contexts';
import { IResponseList, User } from '../../../core/models';
import { adminRouter } from '../../../core/routes';
import { FilterAddWhiteListDrawer } from './components/FilterAddWhiteListDrawer';

interface AddNewWhiteListProps {}

export const AddNewWhiteList: React.FC<AddNewWhiteListProps> = () => {
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const filterModalMethods = useModal('filter');
	const navigate = useNavigate();
	const userQuery = useQuery<IResponseList<User>>(['user', { limit, page, filters }], async () => {
		const { data } = await userApi.v1PagingWhitelist({ limit, page, ...filters, 'filter.role': 'U_RECRUITER' });
		const totalItems: number = _get(data, 'data.meta.totalItems', 0);

		setTotalItems(totalItems);
		return data.data;
	});

	const addWhiteList = useMutation(
		async (userUuid: string) => {
			const { data } = await whiteListApi.v1Post(userUuid);
			return data;
		},
		{
			onSuccess: () => {
				toast.success('Add new white list successfully');
				userQuery.refetch();
			},

			onError: () => {
				toast.error('Add new white list failed');
			},
		}
	);
	React.useEffect(() => {
		document.title = 'Add New White List Management';
	}, []);
	return (
		<>
			<FilterAddWhiteListDrawer />
			<div className='fade-in'>
				<PageHeader
					title='Add New White List'
					onBack={() => {
						navigate(adminRouter.whiteListManagement.href());
					}}
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Name</div>,
								width: 200,
								dataIndex: 'full_name',
								key: 'full_name',
								render: (text, record) => {
									const data = _get(record, 'full_name', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 200,
								dataIndex: 'email',
								key: 'email',
								render: (text, record) => {
									const data = _get(record, 'email', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Is White List</div>,
								width: 200,
								dataIndex: 'email',
								key: 'email',
								render: (text, record) => {
									const data = Boolean(_get(record, 'isInWhiteList', false));

									if (!data) {
										return <Tag color='red'>No</Tag>;
									}

									return <Tag color='success'>Yes</Tag>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																addWhiteList.mutate(record.uuid);
															}}
														>
															Add to White List
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={userQuery.data?.data || []}
						isLoading={userQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
