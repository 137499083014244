/* eslint-disable @typescript-eslint/no-unused-vars */
import { RiseOutlined } from '@ant-design/icons';
import { Button, Col, PageHeader, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { homeApi } from '../../../core/apis';
import { useModal } from '../../../core/contexts';
import { CountCvPerLevel, CountCvPerMajors } from '../../../core/models/home';
import LoadingComponents from './components/Loading';
import PieChartComponents from './components/PieChart';
import { TopCountCv } from './components/topChart';

const Homepage = ({}: {}) => {
	const [top, setTop] = useState(10);
	const [loading, setLoading] = useState(false);
	const topModalMethods = useModal('filter');
	const levelChartQuery = useQuery(
		['levelChart', { top }],
		async () => {
			setLoading(true);
			const res = await homeApi.v1CountCvPerLevel(top, false);
			return res.data;
		},
		{
			onSuccess: () => {
				setLoading(false);
			},
			onError: () => {
				setLoading(false);
			},
		}
	);
	const levelChartActiveQuery = useQuery(
		['levelChartAcitve', { top }],
		async () => {
			setLoading(true);
			const res = await homeApi.v1CountCvPerLevel(top, true);
			return res.data;
		},
		{
			onSuccess: () => {
				setLoading(false);
			},
			onError: () => {
				setLoading(false);
			},
		}
	);
	const majorChartQuery = useQuery(
		['majorChart', { top }],
		async () => {
			setLoading(true);
			const res = await homeApi.v1CountCvPerMajors(top, false);
			return res.data;
		},
		{
			onSuccess: () => {
				setLoading(false);
			},
			onError: () => {
				setLoading(false);
			},
		}
	);
	const majorChartActiveQuery = useQuery(
		['majorChartActive', { top }],
		async () => {
			setLoading(true);
			const res = await homeApi.v1CountCvPerMajors(top, true);
			return res.data;
		},
		{
			onSuccess: (data) => {
				setLoading(false);
			},
			onError: () => {
				setLoading(false);
			},
		}
	);
	useEffect(() => {
		document.title = 'Home';
	}, []);
	return (
		<>
			<TopCountCv setTop={setTop} />
			<div className='fade-in'>
				<PageHeader
					title='Home'
					extra={[
						<Button
							key='1'
							icon={<RiseOutlined />}
							type='primary'
							onClick={() => {
								topModalMethods.handleOpenModal({});
							}}
						>
							Top
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4'>
					<Row gutter={16}>
						<Col className='gutter-row' xs={24} md={12} lg={12} span={6}>
							{loading ? (
								<LoadingComponents />
							) : (
								levelChartQuery.data && (
									<PieChartComponents
										title={'Level Chart'}
										data={levelChartQuery.data?.map((elm: CountCvPerLevel) => {
											return {
												label: elm.level,
												value: Number(elm.value),
											};
										})}
										valueField={'value'}
										typeField={'label'}
									/>
								)
							)}
						</Col>
						<Col className='gutter-row' xs={24} md={12} lg={12} span={6}>
							{loading ? (
								<LoadingComponents />
							) : (
								majorChartQuery.data && (
									<PieChartComponents
										title={'Major Chart'}
										data={majorChartQuery.data?.map((elm: CountCvPerMajors) => {
											return {
												label: elm.specialized,
												value: Number(elm.value),
											};
										})}
										valueField={'value'}
										typeField={'label'}
									/>
								)
							)}
						</Col>
						<Col className='gutter-row' xs={24} md={12} lg={12} span={6}>
							<div className='mt-10'>
								{loading ? (
									<LoadingComponents />
								) : (
									levelChartActiveQuery.data && (
										<PieChartComponents
											title={'Level Chart Active'}
											data={levelChartActiveQuery.data?.map((elm: CountCvPerLevel) => {
												return {
													label: elm.level,
													value: Number(elm.value),
												};
											})}
											valueField={'value'}
											typeField={'label'}
										/>
									)
								)}
							</div>
						</Col>
						<Col className='gutter-row' xs={24} md={12} lg={12} span={6}>
							<div className='mt-10'>
								{loading ? (
									<LoadingComponents />
								) : (
									majorChartActiveQuery.data && (
										<PieChartComponents
											title={'Major Chart Active'}
											data={majorChartActiveQuery.data?.map((elm: CountCvPerMajors) => {
												return {
													label: elm.specialized,
													value: Number(elm.value),
												};
											})}
											valueField={'value'}
											typeField={'label'}
										/>
									)
								)}
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default Homepage;
