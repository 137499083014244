/* eslint-disable @typescript-eslint/no-unused-vars */
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Modal, Row, Space, Tooltip } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import AvatarDefault from '../../../asset/imgDefault/avatar-default.png';
import BackgroundDefault from '../../../asset/imgDefault/background-default.png';
import { CompanyApi } from '../../../core/apis/company.api';
import { SelectInput, TextInput } from '../../../core/components/form';
import { InputRichEditor } from '../../../core/components/form/InputRichEditor';
import { configs } from '../../../core/config';
import { useModal } from '../../../core/contexts';
import { SelectOption, useSelectOption } from '../../../core/hooks';
import { CompanySizeOptions } from './models';

const yupSchemaUpdateUser = yup.object().shape({
	name: yup.string().required('Bạn chưa nhập tên công ty'),
	nameEn: yup.string().required('Bạn chưa nhập tên tiếng anh'),
	address: yup.string().required('Bạn chưa nhập địa chỉ'),
	email: yup.string().email().required('Bạn chưa nhập email').nullable(),
	hiringEmail: yup.string().email().required('Bạn chưa nhập email tuyển dụng').nullable(),
	description: yup.string().required('Bạn chưa nhập mô tả').nullable(),
	// companySize: yup.string().required('Bạn chưa chọn quy mô').nullable(),
	overview: yup.string().required('Bạn chưa nhập tổng quan').nullable(),
	industries: yup.array().required('Bạn chưa chọn nghành nghề').min(1),
	fileBusinessContract: yup.mixed().notRequired(),
	cityId: yup.string().required('Bạn chưa chọn thành phố/tỉnh').nullable(),
	districtId: yup.string().required('Bạn chưa chọn quận/huyện').nullable(),
	wardId: yup.string().required('Bạn chưa chọn phường/xã').nullable(),
});

interface CompanyDetailModalProps {
	handleReset: any;
}

export const CompanyDetailModal: React.FC<CompanyDetailModalProps> = ({ handleReset }: { handleReset }) => {
	const modalMethods = useModal<any>('companyDetail');
	const uploadBackgroundRef: any = React.useRef(null);
	const uploadAvatarRef: any = React.useRef(null);
	const industryTermOptions = useSelectOption(SelectOption.INDUSTRY_BY_ID, 2);

	const formMethods = useForm<any>({
		defaultValues: {
			name: '',
			nameEn: '',
			email: null,
			hiringEmail: null,
			description: '',
			overview: null,
			address: '',
			fileBusinessContract: null,
			taxNumber: null,
			format: null,
			companySize: null,
			industries: [
				{
					industryid: 0,
					languageid: 2,
				},
			],
			cityId: null,
			districtId: null,
			wardId: null,
		},
		resolver: yupResolver(yupSchemaUpdateUser),
	});
	const conpanyByIdQuery = useQuery<any>(
		['comapny-detail', modalMethods.modalValue],
		async () => {
			const { data } = await CompanyApi.v1CompanyById(modalMethods.modalValue);
			return data;
		},
		{
			enabled: modalMethods.modalValue != undefined,
			onSuccess: (data) => {
				const {
					name,
					nameEn,
					address,
					email,
					taxNumber,
					hiringEmail,
					description,
					overview,
					companySize,
					industries,
					businessContract,
				} = data;
				formMethods.setValue('name', name);
				formMethods.setValue('nameEn', nameEn);
				formMethods.setValue('address', address);
				formMethods.setValue('email', email);
				formMethods.setValue('fileBusinessContract', businessContract);
				formMethods.setValue('taxNumber', taxNumber);
				formMethods.setValue('hiringEmail', hiringEmail);
				formMethods.setValue('description', description);
				formMethods.setValue('overview', overview);
				formMethods.setValue(
					'industries',
					industries.map((industriesItem: any) => ({
						label: industryTermOptions.find((item: any) => item.value === industriesItem.industryid)?.label,
						value: industriesItem.industryid,
						data: industriesItem.industryid,
					}))
				);
			},
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	);
	const cityOptions = useSelectOption(SelectOption.CITY);
	const districtOptions = useSelectOption(SelectOption.DISTRICT_BY_ID, formMethods.watch('cityId'), false);
	const wardOptions = useSelectOption(SelectOption.WARD_BY_ID, formMethods.watch('districtId'), false);
	const _handleOnSubmit = (data: any) => {
		const objNew = {
			...data,
			industries: data.industries.map((item: any) => ({
				industryid: Object?.keys(item).length ? item.value : item,
				languageid: 2,
			})),
		};
		mutation.mutate(objNew);
	};
	const mutation = useMutation((input: any) => CompanyApi.v1UpdateCompany(modalMethods.modalValue, input), {
		onSuccess: () => {
			modalMethods.handleCloseModal();
			window.location.reload();
			handleReset();
			toast.success('success');
		},
		onError: (error: any) => {
			toast.error(error?.response?.data?.message || 'error');
		},
	});
	const handleUploadFileBackground = (e: any) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('file', file);
		const reader = new FileReader();

		if (typeof file === 'undefined') return;
		if (modalMethods.modalValue) {
			for (var [key, value] of formData.entries() as any) {
				//Read the contents of Image File.
				reader.readAsDataURL(file);
				reader.onload = function (e: any) {
					const _loadedImageUrl = e.target.result;
					const image = document.createElement('img');
					image.src = _loadedImageUrl;
					image.addEventListener('load', async () => {
						// set image width and height to your state here
						try {
							const { data } = await CompanyApi.v1PostUploadBackgroundCompany(modalMethods.modalValue, formData);
							handleReset();
							conpanyByIdQuery.refetch();
							toast.success(data?.message || '');
						} catch (error: any) {
							toast.error(error?.response?.data?.message || 'error');
						}
					});
				};
			}
		}
	};
	const handleUploadFileAvatar = async (e: any) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('file', file);
		const reader = new FileReader();

		if (typeof file === 'undefined') return;

		if (modalMethods.modalValue) {
			for (var [key, value] of formData.entries() as any) {
				reader.readAsDataURL(file);
				reader.onload = function (e: any) {
					const _loadedImageUrl = e.target.result;
					const image = document.createElement('img');
					image.src = _loadedImageUrl;
					image.addEventListener('load', async () => {
						// set image width and height to your state here
						try {
							const { data } = await CompanyApi.v1PostUploadLogoCompany(modalMethods.modalValue, formData);
							handleReset();
							conpanyByIdQuery.refetch();
							toast.success(data?.message || '');
						} catch (error: any) {
							toast.error(error?.response?.data?.message || 'error');
						}
					});
				};
			}
		}
	};
	return (
		<>
			{conpanyByIdQuery.data && (
				<Modal
					open={modalMethods.getIsOpen()}
					title={'Detail Company'}
					onCancel={() => modalMethods.handleCloseModal()}
					width={'100%'}
					footer={null}
				>
					<Space direction='vertical' className='w-full relative'>
						<img
							src={
								conpanyByIdQuery.data?.background
									? `${configs.REACT_APP_IMAGE_URL}${conpanyByIdQuery.data?.background || ''}`
									: BackgroundDefault
							}
							className='object-cover w-full'
							height={412}
						/>
						<Space className='absolute top-2.5 right-2.5'>
							<Button onClick={() => uploadBackgroundRef.current.click()}>Update background</Button>
						</Space>
						<input
							onChange={handleUploadFileBackground}
							ref={uploadBackgroundRef}
							type='file'
							accept='.png, .jpg, .jpeg'
							className='opacity-0 invisible fixed'
						/>
						<Space className='absolute left-4 top-[340px]'>
							<Space className={'relative'}>
								<img
									className={'object-cover rounded-full bg-white overflow-hidden shadow-lg cursor-pointer'}
									src={
										conpanyByIdQuery.data?.logo
											? `${configs.REACT_APP_IMAGE_URL}${conpanyByIdQuery.data?.logo || ''}`
											: AvatarDefault
									}
									alt='JobfiCompany'
									width={154}
									height={154}
									onClick={() => uploadAvatarRef.current.click()}
								/>
								<input
									onChange={handleUploadFileAvatar}
									ref={uploadAvatarRef}
									type='file'
									accept='.png, .jpg, .jpeg'
									className={'opacity-0 invisible fixed'}
								/>
								<Space className={'cursor-pointer absolute bottom-0 right-10'}>
									<FontAwesomeIcon icon={faCameraRetro} onClick={() => uploadAvatarRef.current.click()} size='2x' />
								</Space>
							</Space>
							<Space className={'mt-20 text-lg'}>
								<Tooltip placement='bottom' title={conpanyByIdQuery.data?.name || ''}>
									<h4>{conpanyByIdQuery.data?.name || ''}</h4>
								</Tooltip>
							</Space>
						</Space>
					</Space>
					<FormProvider {...formMethods}>
						<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
							<Space direction='vertical' className='w-full'>
								<Row gutter={16} className='mt-20'>
									<Col className={'gutter-row'} xs={24} md={12} lg={12}>
										<TextInput name='name' label='Company Name' placeholder='name...' />
										<TextInput name='nameEn' label='Company English Name' placeholder='English name...' />
										<TextInput
											name='email'
											label='Email'
											type='email'
											placeholder='Ex: @gmail.com,...'
											autoComplete='false'
										/>
										<TextInput
											name='businessContract'
											label='Business Contact'
											placeholder='Ex: .png,...'
											autoComplete='false'
										/>
										<TextInput
											name='overview'
											label='Overviews of company'
											placeholder='overview...'
											autoComplete='false'
										/>
										<SelectInput
											name='industries'
											label='Career'
											options={industryTermOptions}
											mode='multiple'
											maxTagCount={5}
										/>
										<InputRichEditor name='description' label='Company Description' className='' />
									</Col>
									<Col className={'gutter-row'} xs={24} md={12} lg={12}>
										<SelectInput
											name='companySize'
											label='Company Size'
											options={CompanySizeOptions}
											placeholder='company sizes...'
											defaultValue={conpanyByIdQuery.data?.companySize}
										/>
										<TextInput name='hiringEmail' label='Hiring email' type='email' placeholder='Ex: @gmail.com,...' />
										<TextInput name='taxNumber' label='Company tax number' placeholder='Ex: 090...,' />
										<SelectInput
											name='cityId'
											label='City/Province'
											options={cityOptions}
											showSearch
											defaultValue={conpanyByIdQuery.data?.city ? conpanyByIdQuery.data?.city.id : null}
										/>
										<SelectInput
											name='districtId'
											label='District'
											options={districtOptions}
											showSearch
											defaultValue={conpanyByIdQuery.data?.district ? conpanyByIdQuery.data?.district.id : null}
										/>
										<SelectInput
											name='wardId'
											label='Ward'
											options={wardOptions}
											showSearch
											defaultValue={conpanyByIdQuery.data?.ward ? conpanyByIdQuery.data?.ward.id : null}
										/>
										<TextInput name='address' label='Address (apartment number)' placeholder='Ex: 20 Hoang Dieu...' />
									</Col>
								</Row>
							</Space>
							<Button htmlType='submit' className='mt-6'>
								Update
							</Button>
						</form>
					</FormProvider>
				</Modal>
			)}
		</>
	);
};
