import { FilterOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { saleSystemGroupApi } from '../../../core/apis/saleSystemGroup.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { useModal, useTableUtil } from '../../../core/contexts';
import { colorOptionsUserRole, Group, IResponseList } from '../../../core/models';
import { saleAdminRouter } from '../../../core/routes';
import { FilterGroupSaleDrawer } from './components/FilterGroupSaleDrawer';

interface SaleAdminGroupSalePageProps {}

export const SaleAdminGroupSalePage: React.FC<SaleAdminGroupSalePageProps> = () => {
	const filterModalMethods = useModal('filter');
	const navigate = useNavigate();
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const groupQuery = useQuery<IResponseList<Group>>(['group', { limit, page, filters }], async () => {
		const { data } = await saleSystemGroupApi.v1GroupMe({
			limit,
			page,
			...filters,
		});

		const totalItems: number = _get(data, 'data.totalItems', 0);

		setTotalItems(totalItems);
		return data;
	});
	React.useEffect(() => {
		document.title = 'Sale Admin Group Management';
	}, []);
	return (
		<>
			<FilterGroupSaleDrawer />

			<div className='fade-in'>
				<PageHeader
					title='Group Management'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Name</div>,
								width: 100,
								dataIndex: 'group.name',
								key: 'group.name',
								render: (text, record) => {
									const data = _get(record, 'group.name', 'No One');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Role In Group</div>,
								width: 200,
								dataIndex: 'role',
								key: 'role',
								render: (text, record) => {
									const data = _get(record, 'role', 'Empty');

									return <>{<StatusTag data={data} options={colorOptionsUserRole} />}</>;
								},
							},

							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																navigate(
																	saleAdminRouter.viewGroupDetailById.href(
																		record.group.uuid
																	)
																);
															}}
														>
															View Detail
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={groupQuery.data?.data || []}
						isLoading={groupQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
