import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AdminLayout, AuthLayout, SaleAdminLayout, SaleLayout } from './core/components/layouts';
import { adminRouter, routes, saleAdminRouter, saleRouter } from './core/routes';

function App() {
	const canSeeCv = Boolean(localStorage.getItem('canViewCVs'));
	const canSeeJob = Boolean(localStorage.getItem('canViewJobList'));
	const canEditCompany = Boolean(localStorage.getItem('canEditCompany'));
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={'6LdDCMEnAAAAAFxozSaoqXhCNu4TtrCy2ryk7EqC'}
			scriptProps={{
				async: false,
				defer: false,
				appendTo: 'head',
				nonce: undefined,
			}}
		>
			<BrowserRouter>
				<Routes>
					<Route element={<AdminLayout />}>
						<Route path={adminRouter.home.href()} element={<adminRouter.home.Component />} />
						<Route
							path={adminRouter.dataSaleManagement.href()}
							element={<adminRouter.dataSaleManagement.Component />}
						/>
						<Route
							path={adminRouter.dataSaleManagementId.href(':id')}
							element={<adminRouter.dataSaleManagementId.Component />}
						/>
						<Route
							path={adminRouter.saleOrderManagement.href()}
							element={<adminRouter.saleOrderManagement.Component />}
						/>
						<Route
							path={adminRouter.purchaseOrderManagement.href()}
							element={<adminRouter.purchaseOrderManagement.Component />}
						/>
						<Route
							path={adminRouter.viewInvoicePurchaseDetailById.href(':purchaseId')}
							element={<adminRouter.viewInvoicePurchaseDetailById.Component />}
						/>
						<Route
							path={adminRouter.saleMemberManagement.href()}
							element={<adminRouter.saleMemberManagement.Component />}
						/>
						<Route
							path={adminRouter.viewSaleMemberById.href(':saleId')}
							element={<adminRouter.viewSaleMemberById.Component />}
						/>
						<Route
							path={adminRouter.groupSaleManagement.href()}
							element={<adminRouter.groupSaleManagement.Component />}
						/>
						<Route
							path={adminRouter.employerManagement.href()}
							element={<adminRouter.employerManagement.Component />}
						/>
						<Route path={adminRouter.employerById.href(':id')} element={<adminRouter.employerById.Component />} />
						<Route
							path={adminRouter.addMemberByGroupId.href(':groupId')}
							element={<adminRouter.addMemberByGroupId.Component />}
						/>
						<Route
							path={adminRouter.viewGroupDetailById.href(':groupId')}
							element={<adminRouter.viewGroupDetailById.Component />}
						/>
						<Route
							path={adminRouter.addEmployerToGroup.href(':id')}
							element={<adminRouter.addEmployerToGroup.Component />}
						/>
						<Route
							path={adminRouter.whiteListManagement.href()}
							element={<adminRouter.whiteListManagement.Component />}
						/>
						<Route path={adminRouter.addNewWhiteList.href()} element={<adminRouter.addNewWhiteList.Component />} />
						<Route
							path={adminRouter.groupMailManagement.href()}
							element={<adminRouter.groupMailManagement.Component />}
						/>
						<Route
							path={adminRouter.groupMailDetailById.href(':groupMailId')}
							element={<adminRouter.groupMailDetailById.Component />}
						/>
						<Route path={adminRouter.cvManagement.href()} element={<adminRouter.cvManagement.Component />} />
						<Route
							path={adminRouter.cvManagementById.href(':cvId')}
							element={<adminRouter.cvManagementById.Component />}
						/>
						<Route path={adminRouter.permissionCv.href()} element={<adminRouter.permissionCv.Component />} />
						<Route
							path={adminRouter.AddPermissionUserCv.href()}
							element={<adminRouter.AddPermissionUserCv.Component />}
						/>
						<Route path={adminRouter.CvApplied.href()} element={<adminRouter.CvApplied.Component />} />
						<Route path={adminRouter.jobDetailById.href(':cvId')} element={<adminRouter.jobDetailById.Component />} />
						<Route path={adminRouter.jobCommentId.href(':cvId')} element={<adminRouter.jobCommentId.Component />} />
						<Route path={adminRouter.companyPage.href()} element={<adminRouter.companyPage.Component />} />
						<Route path={adminRouter.feedBackPage.href()} element={<adminRouter.feedBackPage.Component />} />
						<Route path={adminRouter.cvPoint.href()} element={<adminRouter.cvPoint.Component />} />
						<Route path={adminRouter.reportCv.href()} element={<adminRouter.reportCv.Component />} />
						<Route
							path={adminRouter.sendMailManagement.href()}
							element={<adminRouter.sendMailManagement.Component />}
						/>
						<Route
							path={adminRouter.sendMailManagementById.href(':id')}
							element={<adminRouter.sendMailManagementById.Component />}
						/>
					</Route>

					<Route element={<SaleAdminLayout />}>
						<Route path={saleAdminRouter.home.href()} element={<saleAdminRouter.home.Component />} />
						<Route
							path={saleAdminRouter.dataSaleManagement.href()}
							element={<saleAdminRouter.dataSaleManagement.Component />}
						/>
						<Route
							path={saleAdminRouter.dataSaleManagementId.href(':id')}
							element={<saleAdminRouter.dataSaleManagementId.Component />}
						/>
						<Route
							path={saleAdminRouter.groupSaleManagement.href()}
							element={<saleAdminRouter.groupSaleManagement.Component />}
						/>
						<Route
							path={saleAdminRouter.saleOrderManagement.href()}
							element={<saleAdminRouter.saleOrderManagement.Component />}
						/>
						<Route
							path={saleAdminRouter.purchaseOrderManagement.href()}
							element={<saleAdminRouter.purchaseOrderManagement.Component />}
						/>
						<Route
							path={saleAdminRouter.viewInvoicePurchaseDetailById.href(':purchaseId')}
							element={<saleAdminRouter.viewInvoicePurchaseDetailById.Component />}
						/>
						<Route path={saleAdminRouter.feedBackPage.href()} element={<saleAdminRouter.feedBackPage.Component />} />
						<Route
							path={saleAdminRouter.viewGroupDetailById.href(':groupId')}
							element={<saleAdminRouter.viewGroupDetailById.Component />}
						/>
						<Route
							path={saleAdminRouter.viewEmployerByGroupId.href(':groupId')}
							element={<saleAdminRouter.viewEmployerByGroupId.Component />}
						/>
						<Route
							path={saleAdminRouter.pointRequestByGroupIdManagement.href(':groupId')}
							element={<saleAdminRouter.pointRequestByGroupIdManagement.Component />}
						/>
						<Route
							path={saleAdminRouter.employerSaleManagement.href()}
							element={<saleAdminRouter.employerSaleManagement.Component />}
						/>
						<Route
							path={saleAdminRouter.employerById.href(':saleEmployerGroupUuid')}
							element={<saleAdminRouter.employerById.Component />}
						/>
						<Route
							path={saleAdminRouter.viewEmployerDetailById.href({
								groupId: ':groupId',
								employerId: ':employerId',
							})}
							element={<saleAdminRouter.viewEmployerDetailById.Component />}
						/>
						<Route
							path={saleAdminRouter.addSaleToEmployer.href({
								groupId: ':groupId',
								employerId: ':employerId',
							})}
							element={<saleAdminRouter.addSaleToEmployer.Component />}
						/>
						<Route
							path={saleAdminRouter.viewPointRequestDetailById.href({
								groupId: ':groupId',
								pointRequestId: ':pointRequestId',
							})}
							element={<saleAdminRouter.viewPointRequestDetailById.Component />}
						/>
						{canSeeCv && (
							<>
								<Route
									path={saleAdminRouter.cvManagement.href()}
									element={<saleAdminRouter.cvManagement.Component />}
								/>
								<Route
									path={saleAdminRouter.cvManagementById.href(':cvId')}
									element={<saleAdminRouter.cvManagementById.Component />}
								/>
							</>
						)}
						{canSeeJob && (
							<>
								<Route path={saleAdminRouter.CvApplied.href()} element={<saleAdminRouter.CvApplied.Component />} />
								<Route
									path={saleAdminRouter.jobDetailById.href(':cvId')}
									element={<saleAdminRouter.jobDetailById.Component />}
								/>
							</>
						)}
						{canEditCompany && (
							<Route path={saleAdminRouter.companyPage.href()} element={<saleAdminRouter.companyPage.Component />} />
						)}
						<Route
							path={saleAdminRouter.sendMailManagement.href()}
							element={<saleAdminRouter.sendMailManagement.Component />}
						/>
						<Route
							path={saleAdminRouter.sendMailManagementById.href(':id')}
							element={<saleAdminRouter.sendMailManagementById.Component />}
						/>
						<Route path={saleAdminRouter.reportCv.href()} element={<saleAdminRouter.reportCv.Component />} />
					</Route>

					<Route element={<SaleLayout />}>
						<Route path={saleRouter.home.href()} element={<saleRouter.home.Component />} />
						<Route
							path={saleRouter.dataSaleManagement.href()}
							element={<saleRouter.dataSaleManagement.Component />}
						/>
						<Route
							path={saleRouter.dataSaleManagementId.href(':id')}
							element={<saleRouter.dataSaleManagementId.Component />}
						/>
						<Route
							path={saleRouter.groupSaleManagement.href()}
							element={<saleRouter.groupSaleManagement.Component />}
						/>
						<Route
							path={saleRouter.saleOrderManagement.href()}
							element={<saleRouter.saleOrderManagement.Component />}
						/>
						<Route
							path={saleRouter.purchaseOrderManagement.href()}
							element={<saleRouter.purchaseOrderManagement.Component />}
						/>
						<Route
							path={saleRouter.viewInvoicePurchaseDetailById.href(':purchaseId')}
							element={<saleRouter.viewInvoicePurchaseDetailById.Component />}
						/>
						<Route path={saleRouter.feedBackPage.href()} element={<saleRouter.feedBackPage.Component />} />
						<Route
							path={saleRouter.viewGroupDetailById.href(':groupId')}
							element={<saleRouter.viewGroupDetailById.Component />}
						/>
						<Route
							path={saleRouter.pointRequestManagement.href()}
							element={<saleRouter.pointRequestManagement.Component />}
						/>
						<Route
							path={saleRouter.viewPointRequestDetailById.href(':pointRequestId')}
							element={<saleRouter.viewPointRequestDetailById.Component />}
						/>
						<Route
							path={saleRouter.employerSaleManagement.href()}
							element={<saleRouter.employerSaleManagement.Component />}
						/>
						<Route
							path={saleRouter.viewEmployerDetailById.href(':saleEmployerGroupUuid')}
							element={<saleRouter.viewEmployerDetailById.Component />}
						/>
						<Route
							path={saleRouter.logActivityByEmployerId.href(':employerId')}
							element={<saleRouter.logActivityByEmployerId.Component />}
						/>
						<Route
							path={saleRouter.logActivityById.href({ employerId: ':employerId', id: ':id' })}
							element={<saleRouter.logActivityById.Component />}
						/>
						{canSeeCv && (
							<>
								<Route path={saleRouter.cvManagement.href()} element={<saleRouter.cvManagement.Component />} />
								<Route
									path={saleRouter.cvManagementById.href(':cvId')}
									element={<saleRouter.cvManagementById.Component />}
								/>
							</>
						)}
						{canSeeJob && (
							<>
								<Route path={saleRouter.CvApplied.href()} element={<saleRouter.CvApplied.Component />} />
								<Route path={saleRouter.jobDetailById.href(':cvId')} element={<saleRouter.jobDetailById.Component />} />
							</>
						)}
						{canEditCompany && (
							<Route path={saleRouter.companyPage.href()} element={<saleRouter.companyPage.Component />} />
						)}
						<Route path={saleRouter.sendMailManagement.href()} element={<saleRouter.sendMailManagement.Component />} />
						<Route
							path={saleRouter.sendMailManagementById.href(':id')}
							element={<saleRouter.sendMailManagementById.Component />}
						/>
						<Route path={saleRouter.reportCv.href()} element={<saleRouter.reportCv.Component />} />
					</Route>

					<Route element={<AuthLayout />}>
						<Route path={routes.authLogin.href()} element={<routes.authLogin.Component />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</GoogleReCaptchaProvider>
	);
}

export default App;
