import { Button, Descriptions, Modal } from 'antd';
import moment from 'moment';
import { useModal } from '../../../../core/contexts';

const ViewProfileApplyCv = ({}: {}) => {
	const profileCvApplyByIdMethods = useModal<any>('profileCvById');

	return (
		<>
			{profileCvApplyByIdMethods.modalValue && (
				<Modal
					title={profileCvApplyByIdMethods.modalValue?.full_name || ''}
					open={profileCvApplyByIdMethods.getIsOpen()}
					footer={null}
					centered
					onCancel={() => profileCvApplyByIdMethods.handleCloseModal()}
					width={900}
				>
					<Descriptions bordered title={''} column={3} layout='vertical'>
						<Descriptions.Item label='Email'>{profileCvApplyByIdMethods.modalValue?.email}</Descriptions.Item>
						<Descriptions.Item label='Phone'>{profileCvApplyByIdMethods.modalValue?.phone}</Descriptions.Item>
						<Descriptions.Item label='Gender'>{profileCvApplyByIdMethods.modalValue?.gender}</Descriptions.Item>
						<Descriptions.Item label='birth_day'>
							{moment(profileCvApplyByIdMethods.modalValue?.birth_day).format('DD-MM-YYYY')}
						</Descriptions.Item>
						<Descriptions.Item label='Salary'>
							{profileCvApplyByIdMethods.modalValue?.salary.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} VND
						</Descriptions.Item>
						<Descriptions.Item label='Specialized'>
							{profileCvApplyByIdMethods.modalValue?.specialized}
						</Descriptions.Item>
						<Descriptions.Item label='workplace'>
							{profileCvApplyByIdMethods.modalValue?.workplace?.name
								? profileCvApplyByIdMethods.modalValue?.workplace?.name
								: 'no data'}
						</Descriptions.Item>

						<Descriptions.Item label='Type Job'>
							{profileCvApplyByIdMethods.modalValue?.type_job?.length > 0 &&
								profileCvApplyByIdMethods.modalValue?.type_job?.map((values, index) => {
									return `${values}${index !== profileCvApplyByIdMethods.modalValue?.type_job?.length - 1 ? ', ' : ''}`;
								})}
						</Descriptions.Item>
						<Descriptions.Item label='Address'>
							{profileCvApplyByIdMethods.modalValue?.address
								? `${profileCvApplyByIdMethods.modalValue?.address}, `
								: ''}
							{profileCvApplyByIdMethods.modalValue?.ward?.name
								? `${profileCvApplyByIdMethods.modalValue?.ward?.name} ,`
								: ''}
							{profileCvApplyByIdMethods.modalValue?.district?.name
								? `${profileCvApplyByIdMethods.modalValue?.district?.name} `
								: ''}
						</Descriptions.Item>
					</Descriptions>
					<Descriptions bordered title={''} column={1} layout='vertical'>
						<Descriptions.Item label='Career goals'>
							<div
								dangerouslySetInnerHTML={{
									__html: profileCvApplyByIdMethods.modalValue?.career_goals
										? profileCvApplyByIdMethods.modalValue?.career_goals
										: 'no data',
								}}
							></div>
						</Descriptions.Item>
						{profileCvApplyByIdMethods.modalValue?.work_experience?.length > 0 ? (
							<Descriptions.Item label='Work Experience'>
								<div className='grid grid-cols-2 gap-4'>
									{profileCvApplyByIdMethods.modalValue?.work_experience?.map((values, index) => {
										return (
											<div className='mb-2 p-4 border-solid border-1 border-slate-300 rounded-md' key={index}>
												<p>{values.company_name}</p>
												<p>{values.position}</p>
												<p>
													{moment(values.startAt).format('MM/YYYY')} -{' '}
													{values.isCurrent == 0 ? moment(values.endAt).format('MM/YYYY') : 'Now'}
												</p>
												<p dangerouslySetInnerHTML={{ __html: values.description }}></p>
											</div>
										);
									})}
								</div>
							</Descriptions.Item>
						) : (
							''
						)}
						{profileCvApplyByIdMethods.modalValue?.educations?.length > 0 ? (
							<Descriptions.Item label='Education'>
								<div className='grid grid-cols-2 gap-4'>
									{profileCvApplyByIdMethods.modalValue?.educations?.map((values, index) => {
										return (
											<div className='mb-2 p-4 border-solid border-1 border-slate-300 rounded-md' key={index}>
												<p>{values.school_name}</p>
												<p>{values.major}</p>
												<p>
													{moment(values.startAt).format('MM/YYYY')} - {moment(values.endAt).format('MM/YYYY')}
												</p>
												<p dangerouslySetInnerHTML={{ __html: values.description }}></p>
											</div>
										);
									})}
								</div>
							</Descriptions.Item>
						) : (
							''
						)}
						{profileCvApplyByIdMethods.modalValue?.skills?.length > 0 ? (
							<Descriptions.Item label='Skill'>
								{profileCvApplyByIdMethods.modalValue?.skills?.map((values, index) => {
									return (
										<Button key={index} className='m-2'>
											{values.name} ({values.level}/5)
										</Button>
									);
								})}
							</Descriptions.Item>
						) : (
							''
						)}
						{profileCvApplyByIdMethods.modalValue?.certifications?.length > 0 ? (
							<Descriptions.Item label='Certification'>
								{profileCvApplyByIdMethods.modalValue?.certifications?.map((values, index) => {
									return <div dangerouslySetInnerHTML={{ __html: values.certification }} key={index}></div>;
								})}
							</Descriptions.Item>
						) : (
							''
						)}
						{profileCvApplyByIdMethods.modalValue?.activities?.length > 0 ? (
							<Descriptions.Item label='Activities'>
								<div className='grid grid-cols-2 gap-4'>
									{profileCvApplyByIdMethods.modalValue?.activities?.map((values, index) => {
										return (
											<div className='mb-2 p-4 border-solid border-1 border-slate-300 rounded-md' key={index}>
												<p>{values.activity}</p>
												<div dangerouslySetInnerHTML={{ __html: values.description }}></div>
											</div>
										);
									})}
								</div>
							</Descriptions.Item>
						) : (
							''
						)}
						{profileCvApplyByIdMethods.modalValue?.languages?.length > 0 ? (
							<Descriptions.Item label='Languages'>
								{profileCvApplyByIdMethods.modalValue?.languages?.map((values, index) => {
									return (
										<Button key={index} className='m-2'>
											{values.language.value_en} ({values.languageLevel.name})
										</Button>
									);
								})}
							</Descriptions.Item>
						) : (
							''
						)}
					</Descriptions>
				</Modal>
			)}
		</>
	);
};

export default ViewProfileApplyCv;
