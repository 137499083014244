import { EditOutlined } from '@ant-design/icons';
import { Button, Descriptions, PageHeader, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { salePointApi } from '../../../core/apis';
import { StatusTag } from '../../../core/components/tag';
import { useModal } from '../../../core/contexts';
import { colorOptionsPointStatus, EEmployerPointRequestStatus, PointRequest } from '../../../core/models';
import { saleAdminRouter } from '../../../core/routes';
import { UpdatePointRequestDrawer } from './components/UpdatePointRequestDrawer';

interface SaleAdminViewPointRequestByIdProps {}

export const SaleAdminViewPointRequestById: React.FC<SaleAdminViewPointRequestByIdProps> = () => {
	const { pointRequestId = '', groupId = '' } = useParams<{ pointRequestId: string; groupId: string }>();
	const updateModalMethod = useModal('update');
	const navigate = useNavigate();
	const pointRequestQuery = useQuery<PointRequest>(
		['point-request-detail', pointRequestId, updateModalMethod.getIsOpen()],
		async () => {
			const { data } = await salePointApi.v1GetById(pointRequestId);
			return data;
		}
	);
	React.useEffect(() => {
		document.title = 'Sale Admin - Point Request Management';
	}, []);
	return (
		<>
			<UpdatePointRequestDrawer />
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(saleAdminRouter.pointRequestByGroupIdManagement.href(groupId));
					}}
					title='Point Request Detail'
					extra={[
						pointRequestQuery.data?.status === EEmployerPointRequestStatus.pending && (
							<Space>
								<Button
									key='1'
									type='primary'
									icon={<EditOutlined />}
									onClick={() => {
										updateModalMethod.handleOpenModal({});
									}}
								>
									Update
								</Button>
							</Space>
						),
					]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className=''>
						<Descriptions bordered title='Request Detail'>
							<Descriptions.Item label='Point'>{pointRequestQuery.data?.point}</Descriptions.Item>
							<Descriptions.Item label='Status'>
								<StatusTag data={pointRequestQuery.data?.status} options={colorOptionsPointStatus} />
							</Descriptions.Item>
							<Descriptions.Item label='Create At'>
								{moment(pointRequestQuery.data?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
							</Descriptions.Item>
							<Descriptions.Item label='Reason'>{pointRequestQuery.data?.reason}</Descriptions.Item>
						</Descriptions>
					</div>
					<div className=''>
						<Descriptions bordered title='Employer Detail'>
							<Descriptions.Item label='Name'>
								{pointRequestQuery.data?.employer.full_name}
							</Descriptions.Item>
							<Descriptions.Item label='Email'>
								{pointRequestQuery.data?.employer.email}
							</Descriptions.Item>
							<Descriptions.Item label='Phone'>
								{pointRequestQuery.data?.employer.phone}
							</Descriptions.Item>
						</Descriptions>
					</div>
				</div>
			</div>
		</>
	);
};
