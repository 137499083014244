/* eslint-disable @typescript-eslint/no-unused-vars */
import { Space } from 'antd';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ProfileFindNewApi } from '../../../../core/apis/cvManagement.api';
import { SelectInput, TextInput } from '../../../../core/components/form';
import { IResponseList } from '../../../../core/models';
import { optionsCvStatusJob, optionsCvTypeJob } from '../../../../core/models/cv';
import { addAllOptions } from '../../../../core/utils';

interface FilterCVListProps {
	formMethods: any;
}

export const FilterListCV: React.FC<FilterCVListProps> = ({ formMethods }) => {
	const navigate = useNavigate();
	const [searchIndustry, setSearchIndustry] = React.useState('');
	const [industryData, setIndustryData] = React.useState<any>('');
	const [searchLevelJob, setSearchLevelJob] = React.useState('');
	const [levelJObData, setLevelJObData] = React.useState<any>('');

	useQuery<IResponseList<any>>(
		['level', { searchLevelJob }],
		async () => {
			const { data } = await ProfileFindNewApi.v1GetJobLevelPaging(searchLevelJob);
			return data.data;
		},
		{
			onSuccess: (data) => {
				const listLevelOptions = addAllOptions(
					data?.data?.map((item: any) => ({
						label: item.joblevelname,
						value: item.joblevelid,
						data: item,
					})) || []
				);
				setLevelJObData(listLevelOptions);
			},
		}
	);
	useQuery<IResponseList<any>>(
		['industry', { searchIndustry }],
		async () => {
			const { data } = await ProfileFindNewApi.v1GetIndustryPaging(searchIndustry);
			return data.data;
		},
		{
			onSuccess: (data) => {
				const lisIndustryOptions = addAllOptions(
					data.data?.map((item: any) => ({
						label: item.industryname,
						value: item.industryid,
						data: item,
					})) || []
				);
				setIndustryData(lisIndustryOptions);
			},
		}
	);
	const topSlugs = ['Nationwide', 'HANOI', 'HOCHIMINH', 'DANANG', 'CANTHO', 'HAIPHONG'];
	const listCityQuery = useQuery<any>(['city'], async () => {
		const { data } = await ProfileFindNewApi.getAllCityAreas();
		const topAreas = data.data
			?.filter((ele: any) => topSlugs.includes(ele.slug))
			.sort((a: any, b: any) => {
				return topSlugs.indexOf(a.slug) - topSlugs.indexOf(b.slug);
			});
		const mappingAreas: any[] = data.data.filter((ele: any) => !topSlugs.includes(ele.slug));
		const mapAreas = [...topAreas, ...mappingAreas].map((ele: any) => {
			return {
				name: ele.name,
				id: ele.id,
				data: ele,
			};
		});
		return [...mapAreas];
	});

	const listCityQueryOptions = addAllOptions(
		listCityQuery.data?.map((item: any) => ({
			label: item.name,
			value: item.id,
			data: item.data,
		})) || []
	);

	const listCvTypeJobOptions = addAllOptions(optionsCvTypeJob);
	const listStatusOptions = addAllOptions(optionsCvStatusJob);

	return (
		<div className='w-full filter-CV-list'>
			<FormProvider {...formMethods}>
				<form className='w-full'>
					<Space className='w-full flex flex-wrap'>
						<TextInput name='search' label='Search' placeholder='search..' />
						<SelectInput
							showSearch
							className='w-full'
							name='filter.industryid'
							label='Specialized'
							options={industryData}
							onSearch={(e) => setSearchIndustry(e)}
						/>
						<SelectInput
							showSearch
							className='w-full'
							name='filter.joblevelid'
							label='Level'
							options={levelJObData}
							onSearch={(e) => setSearchLevelJob(e)}
						/>
						<SelectInput
							showSearch
							className='w-full'
							name='filter.city.id'
							label='City'
							options={listCityQueryOptions}
							filterOption={(input, option: any) =>
								option.props.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
								option.props.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						/>
						<SelectInput
							showSearch
							className='w-full'
							name='filter.type_job'
							label='Type Job'
							options={listCvTypeJobOptions}
						/>
						<SelectInput
							showSearch
							className='w-full'
							name='filter.status'
							label='Status'
							options={listStatusOptions}
						/>
					</Space>
				</form>
			</FormProvider>
		</div>
	);
};
