/* eslint-disable @typescript-eslint/no-unused-vars */
import { http } from './http';
import _get from 'lodash/get';

export const homeApi = {
	v1CountCvPerLevel: async (top: number, onlyActiveUser?: boolean): Promise<any> => {
		const res = await http.get(`/v1/api/analytics/count-cv-per-level?top=${top}&onlyActiveUser=${onlyActiveUser}`);
		return res.data;
	},
	v1CountCvPerMajors: async (top: number, onlyActiveUser?: boolean): Promise<any> => {
		const res = await http.get(`/v1/api/analytics/count-cv-per-majors?top=${top}&onlyActiveUser=${onlyActiveUser}`);
		return res.data;
	},
};
