import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Drawer, Space } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1UpdateEmployerGroup, groupEmployerApi } from '../../../../core/apis/groupEmployer';
import { SelectInput } from '../../../../core/components/form';
import { InputRichEditor } from '../../../../core/components/form/InputRichEditor';
import { useModal } from '../../../../core/contexts';
import {
	EEmployerScale,
	EEmployerStatus,
	EEmployerType,
	EmployerId,
	optionsEmployerScale,
	optionsEmployerStatus,
	optionsEmployerType,
} from '../../../../core/models/employer';

const defaultValues: IV1UpdateEmployerGroup = {
	scale: EEmployerScale.MIN_0_TO_10,
	status: EEmployerStatus.NEW,
	type: EEmployerType.SMALL,
	other_note: '',
};

const schema = yup.object().shape({
	name: yup.string().required('Name is required'),
});

interface UpdateEmployerDrawerProps {
	refreshData: () => void;
}

export const UpdateEmployerDrawer: React.FC<UpdateEmployerDrawerProps> = ({ refreshData }) => {
	const updateModalMethod = useModal('update');
	const formMethods = useForm<IV1UpdateEmployerGroup>({
		defaultValues,
		resolver: yupResolver(schema),
	});
	const { saleEmployerGroupUuid = '' } = useParams<{ saleEmployerGroupUuid: string }>();
	const employerGroupByIdQuery = useQuery<EmployerId>(
		['employerById'],
		async () => {
			const res = await groupEmployerApi.v1ById(saleEmployerGroupUuid);

			return res.data.data;
		},
		{
			onSuccess: (data) => {
				if (data) {
					const { scale, status, type, other_note } = data;
					formMethods.setValue('scale', scale);
					formMethods.setValue('status', status);
					formMethods.setValue('type', type);
					formMethods.setValue('other_note', other_note);
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const updateEmployerMutation = useMutation(
		async (data: IV1UpdateEmployerGroup) => {
			const res = await groupEmployerApi.v1UpdateById(saleEmployerGroupUuid, data);

			return res.data;
		},
		{
			onSuccess: (data: any) => {
				toast.success(data.message);
				refreshData();
				updateModalMethod.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);

	const _handleOnSubmit = (data: IV1UpdateEmployerGroup) => {
		updateEmployerMutation.mutate(data);
	};

	return (
		<>
			{employerGroupByIdQuery.data && (
				<Drawer
					open={updateModalMethod.getIsOpen()}
					title='Update Employer'
					onClose={() => updateModalMethod.handleCloseModal()}
					width={800}
				>
					<div>
						<FormProvider {...formMethods}>
							<form id='updateForm' onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
								<Space direction='vertical' className='w-full'>
									<SelectInput name='scale' label='Scale' options={optionsEmployerScale} />
									<SelectInput name='status' label='Status' options={optionsEmployerStatus} />
									<SelectInput name='type' label='Type' options={optionsEmployerType} />
									<InputRichEditor name='other_note' label='Note' placeholder='note..' className='' />
								</Space>

								<Button
									className='mt-6'
									onClick={() => {
										_handleOnSubmit({ ...formMethods.getValues() });
									}}
								>
									Update
								</Button>
							</form>
						</FormProvider>
					</div>
				</Drawer>
			)}
		</>
	);
};
