import { stringify } from 'query-string';
import { EEmployerPointRequestStatus, IPagingProps, PointRequest } from '../models';
import { http } from './http';

export interface IV1PointRequestMeFilters {}
export interface IV1PointRequestMe extends IPagingProps<IV1PointRequestMeFilters> {}

export interface IV1PointRequestPagingFilters {}
export interface IV1PointRequestPaging extends IPagingProps<IV1PointRequestPagingFilters> {}
export interface IV1CreatePointRequest extends Pick<PointRequest, 'point' | 'reason'> {
	groupUuid: string;
	employerUuid: string;
	purchaseOrderUuids: string[];
}
export interface IV1UpdatePointRequest extends Pick<PointRequest, 'reason'> {
	status: EEmployerPointRequestStatus;
}

export const salePointApi = {
	v1PointRequestMe: async (query: IV1PointRequestMe) => {
		const res = await http.get(
			`/v1/api/sale/point-requests/me?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res.data;
	},
	v1PointRequestPaging: async (query: IV1PointRequestPaging) => {
		const res = await http.get(
			`/v1/api/sale/point-requests/paging?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res.data;
	},
	v1CreatePointRequest: async (data: IV1CreatePointRequest) => {
		const res = await http.post('/v1/api/sale/point-requests', data);
		return res;
	},
	v1UpdatePointRequest: async (pointRequestId: string, data: IV1UpdatePointRequest) => {
		const res = await http.put(`/v1/api/sale/point-requests/${pointRequestId}`, data);
		return res;
	},
	v1GetById: async (pointRequestId: string) => {
		const res = await http.get(`/v1/api/sale/point-requests/${pointRequestId}`);
		return res.data;
	},
};
