/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { SelectInput, TextInput } from '../../../../core/components/form';
import { useModal, useTableUtil } from '../../../../core/contexts';
import { Comparator, User } from '../../../../core/models';
import { optionsEmployerScale, optionsEmployerStatus, optionsEmployerType } from '../../../../core/models/employer';
import { addAllOptions, filterMapper } from '../../../../core/utils';
import { IV1EmployerPagingFilters } from '../../../../core/apis/saleOrder.api';
import { typeSaleOrder } from '../../../../core/models/saleOrder';

interface FilterEmployerDrawerProps { }

export const FilterEmployerDrawer: React.FC<FilterEmployerDrawerProps> = () => {
	const filterModalMethod = useModal<User>('filter');
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const tableMethods = useTableUtil();
	const formMethods = useForm<IV1EmployerPagingFilters>({
		defaultValues: {
			employerEmail: '',
			employerPhone: '',
			type: '',
		},
	});
	const _handleOnSubmit = (data: IV1EmployerPagingFilters) => {
		const filters = filterMapper<IV1EmployerPagingFilters>(
			data,
			{
				employerEmail: Comparator.EQUAL,
				employerPhone: Comparator.EQUAL,
				type: Comparator.EQUAL,
			},
			{
				employerEmail: 'filter.email',
				employerPhone: 'filter.phone',
			}
		);
		tableMethods.setFilters(filters);
		tableMethods.setPage(1);
		const queryParams = { ...filters, page: '1', limit: tableMethods.limit };
		navigate({
			search: createSearchParams(queryParams).toString(),
		});
	};

	React.useEffect(() => {
		const params = {};

		searchParams.forEach((value, key) => {
			params[key] = value;
		});

		const filterEmail = params['filter.email']?.replace('$eq:', '') || '';
		const filterPhone = params['filter.phone']?.replace('$eq:', '') || '';
		const filterType = params['filter.type']?.replace('$eq:', '') || '';

		formMethods.setValue('employerEmail', filterEmail)
		formMethods.setValue('employerPhone', filterPhone)
		formMethods.setValue('type', filterType)

		tableMethods.setFilters(params);

	}, []);

	return (
		<Drawer
			open={filterModalMethod.getIsOpen()}
			title='Filter Sale Order'
			onClose={() => filterModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='employerEmail' label='Email' />
							<TextInput name='employerPhone' label='Phone' />
							<SelectInput name='type' label='Type' options={addAllOptions(typeSaleOrder)} />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Apply
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
