import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1UpdateEmployer, saleSystemEmployerApi } from '../../../../core/apis';
import { SelectInput, TextareaInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import {
	EEmployerScale,
	EEmployerStatus,
	EEmployerType,
	Employer,
	optionsEmployerScale,
	optionsEmployerStatus,
	optionsEmployerType,
} from '../../../../core/models/employer';

const defaultValues: IV1UpdateEmployer = {
	scale: EEmployerScale.MIN_0_TO_10,
	status: EEmployerStatus.NEW,
	type: EEmployerType.SMALL,
	other_note: '',
	callNote: '',
};

const schema = yup.object().shape({
	name: yup.string().required('Name is required'),
});

interface UpdateEmployerDrawerProps {}

export const UpdateEmployerDrawer: React.FC<UpdateEmployerDrawerProps> = () => {
	const updateModalMethod = useModal('update');
	const formMethods = useForm<IV1UpdateEmployer>({
		defaultValues,
		resolver: yupResolver(schema),
	});
	const { employerId = '' } = useParams<{ employerId: string }>();
	useQuery<Employer>(
		['employer', updateModalMethod.modalValue],
		async () => {
			const res = await saleSystemEmployerApi.v1GetById(employerId);

			return res.data;
		},
		{
			enabled: updateModalMethod.getIsOpen(),
			onSuccess: (data) => {
				formMethods.setValue('scale', data.scale);
				formMethods.setValue('status', data.status);
				formMethods.setValue('type', data.type);
				formMethods.setValue('other_note', data.other_note);
			},
		}
	);

	const updateEmployerMutation = useMutation(
		async (data: IV1UpdateEmployer) => {
			const res = await saleSystemEmployerApi.v1UpdateById(employerId, data);

			return res;
		},
		{
			onSuccess: (data) => {
				toast.success(data.message);
				updateModalMethod.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);

	const _handleOnSubmit = (data: IV1UpdateEmployer) => {
		updateEmployerMutation.mutate(data);
	};

	return (
		<Drawer
			open={updateModalMethod.getIsOpen()}
			title='Update Employer'
			onClose={() => updateModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form id='updateForm' onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<SelectInput name='scale' label='Scale' options={optionsEmployerScale} />
							<SelectInput name='status' label='Status' options={optionsEmployerStatus} />
							<SelectInput name='type' label='Type' options={optionsEmployerType} />
							<TextareaInput name='other_note' label='Note' />
						</Space>

						<Button
							className='mt-6'
							onClick={() => {
								_handleOnSubmit({ ...formMethods.getValues() });
							}}
						>
							Update
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
