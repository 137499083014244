import { FilterOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Popconfirm, Space } from 'antd';
import _get from 'lodash/get';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { whiteListApi } from '../../../core/apis/whileList.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal, useTableUtil } from '../../../core/contexts';
import { adminRouter } from '../../../core/routes';
import { FilterWhiteListDrawer } from './components/FilterWhiteListDrawer';

interface WhiteListManagementProps {}

export const WhiteListManagement: React.FC<WhiteListManagementProps> = () => {
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const filterModalMethods = useModal('filter');
	const navigate = useNavigate();
	const userQuery = useQuery(['whiteList', { limit, page, filters }], async () => {
		const { data } = await whiteListApi.v1Get({
			limit,
			page,
			...filters,
		});

		const totalItems: number = _get(data, 'meta.totalItems', 0);

		setTotalItems(totalItems);
		return data;
	});

	const removeWhiteList = useMutation(
		(userUuid: string) => {
			return whiteListApi.v1Delete(userUuid);
		},
		{
			onSuccess: () => {
				toast.success('Remove white list successfully');
				userQuery.refetch();
			},

			onError: () => {
				toast.error('Remove white list failed');
			},
		}
	);
	React.useEffect(() => {
		document.title = 'White List Management';
	}, []);
	return (
		<>
			<FilterWhiteListDrawer />
			<div className='fade-in'>
				<PageHeader
					title='White List Exchange Employer'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
						<Button
							key='2'
							icon={<PlusOutlined />}
							onClick={() => {
								navigate(adminRouter.addNewWhiteList.href());
							}}
						>
							Add New
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Name</div>,
								width: 200,
								dataIndex: 'full_name',
								key: 'full_name',
								render: (text, record) => {
									const data = _get(record, 'user.full_name', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 200,
								dataIndex: 'email',
								key: 'email',
								render: (text, record) => {
									const data = _get(record, 'user.email', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Popconfirm
															title='Are you sure to remove this white list?'
															onConfirm={() => {
																const userUuid = _get(record, 'uuid', '');

																removeWhiteList.mutate(userUuid);
															}}
														>
															<Menu.Item danger>Remove from White List</Menu.Item>
														</Popconfirm>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={userQuery.data?.data || []}
						isLoading={userQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
