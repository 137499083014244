import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

export interface ITableUtilContext {
	limit: any;
	page: any;
	totalItems: number;
	handleOnChangePage: (page: number, pageSize: number) => void;
	setTotalItems: React.Dispatch<React.SetStateAction<number>>;
	handleOnReset: () => void;
	setFilters: React.Dispatch<React.SetStateAction<{}>>;
	setPage: any;
	setLimit: any;
	filters: {};

}

const TableUtilContext = React.createContext<ITableUtilContext>({
	limit: 50,
	page: 1,
	totalItems: 0,
	handleOnChangePage: () => { },
	setTotalItems: () => { },
	handleOnReset: () => { },
	setFilters: () => { },
	filters: {},
	setPage: () => { },
	setLimit: () => { },
});

export const TableUtilProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [limit, setLimit] = React.useState<any>(50);
	const [page, setPage] = React.useState<any>(1);
	const [totalItems, setTotalItems] = React.useState<number>(0);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [filters, setFilters] = React.useState({});

	React.useEffect(() => {
		const prams = {
			limit: Number(searchParams.get('limit')) || 50,
			page: Number(searchParams.get('page')) || 1,
		};

		setLimit(prams.limit);
		setPage(prams.page);
	}, []);

	const handleOnReset = () => {
		setSearchParams({ page: String(1), limit: String(50) });
	};

	const handleOnChangePage = (page: number, pageSize: number) => {


		let newPage = page;
		if (page < 0 || pageSize !== limit) {
			newPage = 0;
		} else if (page >= totalItems) {
			newPage = totalItems - 1;
		}
		const params = {}

		searchParams.forEach((value, key) => {
			params[key] = value;
		});

		setSearchParams({...filters, ...params, page: String(newPage), limit: String(pageSize)});
		setPage(newPage);
		setLimit(pageSize);
	};

	const handleSetFilters = (item: any) => {
		const { page, ...otherFilers } = item;
		setFilters(otherFilers);
		setPage(page || 1);
	};

	return (
		<TableUtilContext.Provider
			value={{
				limit,
				page,
				filters,
				setPage,
				setLimit,
				handleOnReset,
				setFilters: handleSetFilters,
				handleOnChangePage,
				totalItems,
				setTotalItems,
			}}
		>
			{children}
		</TableUtilContext.Provider>
	);
};

export const useTableUtil = () => {
	const context = React.useContext(TableUtilContext);

	return { ...context };
};
