import { Button, Drawer, Popconfirm } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IV1SendMail, groupMailApi } from '../../../../core/apis';
import { TextInput, TextareaInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';

const defaultValues: IV1SendMail = {
	metadata: '',
	subject: '',
	template: '',
	text: '',
};

interface SendMailDrawerProps {}

const SendMailDrawer: React.FC<SendMailDrawerProps> = () => {
	const sendMailMethods = useModal('sendMail');
	const formMethods = useForm<IV1SendMail>({
		defaultValues,
	});
	const params = useParams<{
		groupMailId: string;
	}>();

	const sendMailMutation = useMutation(
		(data: IV1SendMail) => {
			return groupMailApi.v1SendMail(params?.groupMailId || '', data);
		},
		{
			onSuccess: () => {
				toast.success('Send Mail Success');
				sendMailMethods.handleCloseModal();
			},
			onError: () => {
				toast.error('Send Mail Failed, Please Try Again Later');
			},
		}
	);

	return (
		<Drawer title='Send Mail' open={sendMailMethods.getIsOpen()} onClose={sendMailMethods.handleCloseModal}>
			<FormProvider {...formMethods}>
				<form>
					<div className='space-y-4'>
						<TextInput name='template' label='Template' />
						<TextInput name='subject' label='Subject' />
						<TextInput name='text' label='Text' />
						<TextareaInput name='metadata' label='Metadata' />
						<Popconfirm
							title='Are you sure to send this mail?'
							onConfirm={() => {
								sendMailMutation.mutate(formMethods.getValues());
							}}
						>
							<Button type='primary' htmlType='submit' loading={sendMailMutation.isLoading}>
								Send
							</Button>
						</Popconfirm>
					</div>
				</form>
			</FormProvider>
		</Drawer>
	);
};

export default SendMailDrawer;
