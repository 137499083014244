import { stringify } from 'query-string';
import { IPagingProps } from '../models';
import { http } from './http';

export interface IV1GroupEmployerPagingFilters {}
export interface IV1GroupEmployerPaging extends IPagingProps<IV1GroupEmployerPagingFilters> {}

export interface IV1GroupEmployerMeFilters {}
export interface IV1GroupEmployerMe {
	id: string;
}
export interface IV1UpdateEmployerGroup {
	groupUuid?: string;
	saleUuid?: string;
	scale: string;
	type: string;
	other_note: string;
	status: string;
}

export const groupEmployerApi = {
	v1Paging: async (query: IV1GroupEmployerPaging) => {
		const res = await http.get(
			`/v1/api/sale-employer-group/paging?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
	v1GroupPaging: async (groupId: string, query: IV1GroupEmployerPaging) => {
		const res = await http.get(
			`/v1/api/sale-employer-group/paging?${stringify({
				...query,
				...query.filters,
				'filter.group.uuid': groupId,
			})}`
		);
		return res;
	},
	v1GroupMe: async () => {
		const res = await http.get('/v1/api/sale/group/me');
		return res;
	},
	v1ById: async (id: string) => {
		const res = await http.get(`/v1/api/sale-employer-group/${id}`);
		return res;
	},
	v1Import: async (file: any) => {
		const res = await http.post('/v1/api/sale-employer-group/import', file, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return res;
	},
	v1NoteById: async (id: string, body: { note: string }) => {
		const res = await http.post(`/v1/api/sale-employer-group/${id}/notes`, body);
		return res;
	},
	v1UpdateById: async (noteId: string, body: IV1UpdateEmployerGroup) => {
		const res = await http.put(`/v1/api/sale-employer-group/${noteId}`, body);
		return res;
	},
	v1EditById: async (id: string, noteId: string, body: { note: string }) => {
		const res = await http.put(`/v1/api/sale-employer-group/${id}/notes/${noteId}`, body);
		return res;
	},
	v1NoteInGroupById: async (id: string, noteId: string) => {
		const res = await http.get(`/v1/api/sale-employer-group/${id}/notes/${noteId}`);
		return res;
	},
	v1DeleteNote: async (id: string, noteId: string) => {
		const res = await http.delete(`/v1/api/sale-employer-group/${id}/notes/${noteId}`);
		return res;
	},
	v1DeleteEmployerInGroup: async (id: string) => {
		const res = await http.delete(`/v1/api/sale-employer-group/${id}/group`);
		return res;
	},
};
