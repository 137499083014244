import { Select, SelectProps } from 'antd';
import clsx from 'clsx';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IOptionItem } from '../../models';
import { FieldWrapper } from './FieldWrapper';

interface SelectInputProps extends SelectProps {
	name: string;
	label: string;
	options: IOptionItem[];
}

export const SelectInput: React.FC<SelectInputProps> = ({
	name,
	label,
	options,
	mode,
	defaultValue,
	className,
	...rest
}) => {
	const { control, setValue } = useFormContext();
	const [optionData, setOptionData] = React.useState<IOptionItem[]>(options);

	React.useEffect(() => {
		if (mode !== 'multiple') {
			if (defaultValue) {
				setValue(name, defaultValue);
			} else if (options && options.length) {
				setValue(name, options[0].value);
			}
		}
	}, []);

	React.useEffect(() => {
		setOptionData(options);
	}, [options]);
	return (
		<div className='mb-4'>
			<FieldWrapper name={name} label={label}>
				<Controller
					control={control}
					name={name}
					render={({ field }) => (
						<Select {...field}
							filterOption={(input, option) =>
								(option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
							className={clsx([className, 'w-full'], {})} mode={mode} {...rest}>
							{optionData?.length > 0 &&
								optionData?.map((option) => (
									<Select.Option key={option.value} value={option.value}>
										{option.label}
									</Select.Option>
								))}
						</Select>
					)}
				/>
			</FieldWrapper>
		</div>
	);
};
