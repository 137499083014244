import { Button, Descriptions, Modal, Tag } from 'antd';
import { useModal } from '../../../../core/contexts';
import { useQuery } from 'react-query';
import { CvAppliedCandidatesApi } from '../../../../core/apis/cvApplied.api';
import { toast } from 'react-toastify';
import { EJobExamResultStatus } from '../../../../core/models/cv';

const AnswerById = ({}: {}) => {
	const modalMethods = useModal<any>('answers');

	const AnswerByIdQuery = useQuery<any>(
		['questions', {}],
		async () => {
			const { data } = await CvAppliedCandidatesApi.v1AnswerById(modalMethods.modalValue?.uuid);
			return data;
		},
		{
			enabled: modalMethods.modalValue != undefined,
			onError: (error: any) => {
				toast.error(error.response.message);
			},
		}
	);
	const formatExamResultStatus = (value: number) => {
		switch (value) {
			case EJobExamResultStatus.NOT_DO:
				return <>Not Do</>;
			case EJobExamResultStatus.NOT_SUBMIT:
				return <>Not Submit</>;
			case EJobExamResultStatus.SUBMITTED:
				return <>Submitted</>;
			default:
				return <></>;
		}
	};
	return (
		<>
			{modalMethods.modalValue && (
				<Modal
					title={''}
					open={modalMethods.getIsOpen()}
					footer={null}
					centered
					onCancel={() => modalMethods.handleCloseModal()}
					width={800}
				>
					{AnswerByIdQuery.data &&
						AnswerByIdQuery.data?.data?.map((value, index) => {
							return (
								<Descriptions
									bordered
									title={''}
									column={3}
									layout='vertical'
									key={index}
									style={{ marginBottom: '15px' }}
								>
									<Descriptions.Item label='Full name'>{value?.profile?.full_name}</Descriptions.Item>
									<Descriptions.Item label='email'>{value?.profile?.email}</Descriptions.Item>
									<Descriptions.Item label='Exam Result Status'>
										{formatExamResultStatus(value?.examResultStatus)}
									</Descriptions.Item>
									<Descriptions.Item label='Total Points'>
										<Button>{value?.examResult?.totalPoints ? value?.examResult?.totalPoints : 'Not Require'}</Button>
									</Descriptions.Item>
									<Descriptions.Item label='Status'>
										<Tag
											color={
												value?.examResult?.totalPoints >= modalMethods.modalValue?.exam?.minimumPoints ? 'green' : 'red'
											}
										>
											{value?.examResult?.totalPoints >= modalMethods.modalValue?.exam?.minimumPoints ? 'Pass' : 'Fail'}
										</Tag>
									</Descriptions.Item>
								</Descriptions>
							);
						})}
				</Modal>
			)}
		</>
	);
};
export default AnswerById;
