import { IconDefinition, IconPack, IconPrefix, config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import 'antd/dist/antd.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { AuthUserProvider } from './core/contexts';
import { store } from './core/stores';
import reportWebVitals from './reportWebVitals';
import './styles/global.css';

const { library } = require('@fortawesome/fontawesome-svg-core');

// Type that `library.add()` expects.
type IconDefinitionOrPack = IconDefinition | IconPack;
interface ImportedIcons {
	[key: string]: IconPrefix | IconDefinitionOrPack;
}
// Type `Icons` as a interface containing keys whose values are
// union of the resulting union type from above and `IconPrefix`.
const iconList = Object.keys(Icons)
	.filter((key) => key !== 'fas' && key !== 'far' && key !== 'fasr' && key !== 'prefix')
	.map((icon) => (Icons as ImportedIcons)[icon]);

library.add(...(iconList as IconDefinitionOrPack[]));
config.autoAddCss = false;

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<AuthUserProvider>
					<App />
				</AuthUserProvider>
			</QueryClientProvider>
		</Provider>
		<ToastContainer
			position='top-right'
			// theme=''
			hideProgressBar={false}
			autoClose={1000}
			draggable
			pauseOnHover
			closeOnClick
		/>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
