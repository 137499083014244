import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { IV1UserPagingFilter } from '../../../../core/apis';

import { SelectInput, TextInput } from '../../../../core/components/form';
import { useModal, useTableUtil } from '../../../../core/contexts';
import { Comparator, User, UserRole } from '../../../../core/models';
import { filterMapper } from '../../../../core/utils';
import { optionsUserRole } from '../models';

interface FilterSaleEmployerDrawerProps {}

export const FilterSaleEmployerDrawer: React.FC<FilterSaleEmployerDrawerProps> = () => {
	const filterModalMethod = useModal<User>('filter');
	const tableMethods = useTableUtil();
	const formMethods = useForm<IV1UserPagingFilter>({
		defaultValues: {
			name: '',
			role: [],
		},
	});

	const _handleOnSubmit = (data: IV1UserPagingFilter) => {
		if (!data.role.length) {
			data.role = [UserRole.SALE, UserRole.SALE_ADMIN];
		}

		tableMethods.setFilters(
			filterMapper<IV1UserPagingFilter>(
				data,
				{
					role: Comparator.IN,
					name: Comparator.NONE,
				},
				{
					name: 'search',
				}
			)
		);
	};

	return (
		<Drawer
			open={filterModalMethod.getIsOpen()}
			title='Filter Sale Employer'
			onClose={() => filterModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='name' label='Name' />
							<SelectInput name='role' label='Role' options={optionsUserRole} mode='multiple' />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Apply
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
