import Cookies from 'js-cookie';
import * as React from 'react';
import { userApi } from '../apis';
import { CONSTANTS } from '../constants';
import { User } from '../models';
import { routes } from '../routes';

interface IAuthUserContext {
	getCurrentUser: () => void;
	handleLogout: () => void;
	isTryLogin: boolean;
	currentUser: User | undefined;
}

const AuthUserContext = React.createContext<IAuthUserContext>({
	getCurrentUser: () => {},
	currentUser: undefined,
	handleLogout: () => {},
	isTryLogin: false,
});

export const AuthUserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [currentUser, setCurrentUser] = React.useState<User>();
	const [isTryLogin, setIsTryLogin] = React.useState<boolean>(false);

	const getCurrentUser = async () => {
		try {
			const user = await userApi.v1About();
			localStorage.setItem('canViewCVs', user?.canViewCVs.toString());
			localStorage.setItem('canViewJobList', user?.canViewJobList.toString());
			localStorage.setItem('canEditCompany', user?.canEditCompany.toString());
			localStorage.setItem('userId', user?.uuid.toString());
			setCurrentUser(() => ({
				...user,
			}));
		} catch (error) {}
		setIsTryLogin(true);
	};
	const handleLogout = () => {
		setCurrentUser(undefined);
		Cookies.remove(CONSTANTS.ACCESS_TOKEN_KEY);
		Cookies.remove(CONSTANTS.REFRESH_TOKEN_KEY);
		Cookies.remove(CONSTANTS.ROLE);
		localStorage.removeItem('canViewCVs');
		localStorage.removeItem('canViewJobList');
		localStorage.removeItem('canEditCompany');
		localStorage.removeItem('userId');
		window.location.pathname = routes.authLogin.href();
	};

	return (
		<AuthUserContext.Provider value={{ getCurrentUser, currentUser, handleLogout, isTryLogin }}>
			{children}
		</AuthUserContext.Provider>
	);
};

export const useAuthUser = () => {
	const context = React.useContext(AuthUserContext);

	return { ...context };
};
