/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { IV1EmployerPagingFilters } from '../../../../core/apis';
import { SelectInput, TextInput } from '../../../../core/components/form';
import { useModal, useTableUtil } from '../../../../core/contexts';
import { Comparator, User } from '../../../../core/models';
import { optionsEmployerScale, optionsEmployerStatus, optionsEmployerType } from '../../../../core/models/employer';
import { addAllOptions, filterMapper } from '../../../../core/utils';

interface FilterEmpGroupDrawerProps {}

export const FilterEmpGroupDrawer: React.FC<FilterEmpGroupDrawerProps> = () => {
	const filterModalMethod = useModal<User>('filter');
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const tableMethods = useTableUtil();
	const formMethods = useForm<IV1EmployerPagingFilters>({
		defaultValues: {
			employerEmail: '',
			employerPhone: '',
			scale: '',
			status: '',
			type: '',
			isActive: false,
		},
	});
	const _handleOnSubmit = (data: IV1EmployerPagingFilters) => {
		const filters = filterMapper<IV1EmployerPagingFilters>(
			data,
			{
				employerEmail: Comparator.LIKE,
				employerPhone: Comparator.LIKE,
				scale: Comparator.EQUAL,
				status: Comparator.EQUAL,
				type: Comparator.EQUAL,
				isActive: Comparator.EQUAL,
			},
			{
				employerEmail: 'filter.employer.email',
				employerPhone: 'filter.employer.phone',
			}
		);
		tableMethods.setFilters(filters);
		tableMethods.setPage(1);
		const queryParams = { ...filters, page: '1', limit: tableMethods.limit };
		navigate({
			search: createSearchParams(queryParams).toString(),
		});
	};

	React.useEffect(() => {
		const params = {};

		searchParams.forEach((value, key) => {
			params[key] = value;
		});

		const filterEmail = params['filter.employer.email']?.replace('$ilike:', '') || '';
		const filterPhone = params['filter.employer.phone']?.replace('$ilike:', '') || '';
		const filterScale = params['filter.scale']?.replace('$eq:', '') || '';
		const filterStatus = params['filter.status']?.replace('$eq:', '') || '';
		const filterType = params['filter.type']?.replace('$eq:', '') || '';
		const filterIsActive = params['filter.isActive']?.replace('$eq:', '') || '';

		formMethods.setValue('employerEmail', filterEmail);
		formMethods.setValue('employerPhone', filterPhone);
		formMethods.setValue('scale', filterScale);
		formMethods.setValue('status', filterStatus);
		formMethods.setValue('type', filterType);
		formMethods.setValue('isActive', filterIsActive);

		tableMethods.setFilters(params);
	}, []);

	return (
		<Drawer
			open={filterModalMethod.getIsOpen()}
			title='Filter Employer'
			onClose={() => filterModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='employerEmail' label='Employer Email' />
							<TextInput name='employerPhone' label='Employer Phone' />
							<SelectInput name='scale' label='Scale' options={addAllOptions(optionsEmployerScale)} />
							<SelectInput name='status' label='Employer Status' options={addAllOptions(optionsEmployerStatus)} />
							<SelectInput
								name='isActive'
								label='Lead Status'
								options={[
									{
										label: 'not Active',
										value: false,
										data: false,
									},
									{
										label: 'Activated',
										value: true,
										data: true,
									},
								]}
							/>
							<SelectInput name='type' label='Type' options={addAllOptions(optionsEmployerType)} />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Apply
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
