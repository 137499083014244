import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { IV1EmployerPagingFilters } from '../../../../core/apis';

import { SelectInput, TextInput } from '../../../../core/components/form';
import { useModal, useTableUtil } from '../../../../core/contexts';
import { Comparator, User } from '../../../../core/models';
import { optionsEmployerScale, optionsEmployerStatus, optionsEmployerType } from '../../../../core/models/employer';
import { addAllOptions, filterMapper } from '../../../../core/utils';

interface FilterEmployerDrawerProps {}

export const FilterEmployerDrawer: React.FC<FilterEmployerDrawerProps> = () => {
	const filterModalMethod = useModal<User>('filter');
	const tableMethods = useTableUtil();
	const formMethods = useForm<IV1EmployerPagingFilters>({
		defaultValues: {
			employerEmail: '',
			employerPhone: '',
			scale: '',
			status: '',
			type: '',
			isActive: false,
		},
	});

	const _handleOnSubmit = (data: IV1EmployerPagingFilters) => {
		tableMethods.setFilters(
			filterMapper<IV1EmployerPagingFilters>(
				data,
				{
					employerEmail: Comparator.LIKE,
					employerPhone: Comparator.LIKE,
					scale: Comparator.EQUAL,
					status: Comparator.EQUAL,
					type: Comparator.EQUAL,
					isActive: Comparator.EQUAL,
				},
				{
					employerEmail: 'filter.employer.email',
					employerPhone: 'filter.employer.phone',
				}
			)
		);
	};

	return (
		<Drawer
			open={filterModalMethod.getIsOpen()}
			title='Filter Employer'
			onClose={() => filterModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='employerEmail' label='Employer Email' />
							<TextInput name='employerPhone' label='Employer Phone' />
							<SelectInput name='scale' label='Scale' options={addAllOptions(optionsEmployerScale)} />
							<SelectInput name='status' label='Employer Status' options={addAllOptions(optionsEmployerStatus)} />
							<SelectInput
								name='isActive'
								label='Lead Status'
								options={[
									{
										label: 'not Active',
										value: false,
										data: false,
									},
									{
										label: 'Activated',
										value: true,
										data: true,
									},
								]}
							/>
							<SelectInput name='type' label='Type' options={addAllOptions(optionsEmployerType)} />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Apply
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
