/* eslint-disable quotes */
import { Button } from 'antd';
import { configs } from '../../../../../core/config';
import { useModal } from '../../../../../core/contexts';
import ModalReplies from './ModalReplies';

const QuestionCandidateItem = ({
	questionItem,
	statusCommentText,
	statusCommentClass
}: {
	questionItem: any,
	statusCommentText: any,
	statusCommentClass: any,
}) => {

	const repliesMethod = useModal<any>('replies');

	return (
		<>
			{repliesMethod?.getIsOpen() && <ModalReplies />}
			<div className='shadow-xl rounded-[8px] p-[24px] mb-[20px] w-full'>
				<div className='flex justify-between'>
					<div className='flex'>
						<img
							src={`${configs.REACT_APP_IMAGE_URL}${questionItem?.user?.avatar || ''}`}
							alt='avatar'
							className='object-cover rounded w-[40px] h-[40px]'
						/>
						<div className='ml-[16px]'>
							<div className='flex items-center mb-1'>
								<h3 className='text-[#787878] text-[14px] mb-0'>{questionItem?.user?.full_name}</h3>
								{questionItem?.status && <div className={`w-fit ml-[10px] rounded px-[8px] py-[2px] ${statusCommentClass[questionItem?.status]}`}>
									{statusCommentText[questionItem?.status]}
								</div>}
							</div>
							<p className='text-[16px]'>{questionItem?.comment}</p>
						</div>
					</div>
					<Button onClick={() => {
						repliesMethod.handleOpenModal(questionItem);
					}}>View Replies</Button>
				</div>
			</div>
		</>
	);
};
export default QuestionCandidateItem;
