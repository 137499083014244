import { FilterOutlined, MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from 'react-query';
import { IV1UserPagingFilter, userApi } from '../../../core/apis';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { TableUtilProvider, useModal, useTableUtil } from '../../../core/contexts';
import { Comparator, IResponseList, User, UserRole, colorOptionsUserRole } from '../../../core/models';
import { filterMapper } from '../../../core/utils';
import { CreateSaleEmployerDrawer } from './components/CreateSaleEmployerDrawer';
import { EditSaleEmployerDrawer } from './components/EditSaleEmployerDrawer';

import { useNavigate } from 'react-router-dom';
import { adminRouter } from '../../../core/routes';
import { FilterSaleEmployerDrawer } from './components/FilterSaleEmployerDrawer';
import { ViewJoinGroupByUserModal } from './components/ViewJoinGroupByUserModal';

interface SaleMemberManagementProps {}

export const SaleMemberManagement: React.FC<SaleMemberManagementProps> = () => {
	const { limit, page, setTotalItems, filters, setFilters } = useTableUtil();
	const navigator = useNavigate();
	const createModalMethods = useModal('create');
	const editModalMethods = useModal('edit');
	const viewGroupModalMethods = useModal('viewJoinGroupByUser');
	const filterModalMethods = useModal('filter');
	const saleEmployerQuery = useQuery<IResponseList<User>>(
		['saleEmployer', { limit, page, filters }, editModalMethods.getIsOpen(), createModalMethods.getIsOpen()],
		async () => {
			const { data } = await userApi.v1Paging({
				limit,
				page,
				...filters,
			});

			const totalItems: number = _get(data, 'data.meta.totalItems', 0);

			setTotalItems(totalItems);
			return data.data;
		},
		{
			enabled: !editModalMethods.getIsOpen() || !createModalMethods.getIsOpen(),
		}
	);

	React.useEffect(() => {
		setFilters(
			filterMapper<IV1UserPagingFilter>(
				{
					role: [UserRole.SALE, UserRole.SALE_ADMIN].join(','),
					name: '',
				},
				{
					role: Comparator.IN,
					name: Comparator.NONE,
				},
				{
					name: 'search',
				}
			)
		);
	}, []);
	React.useEffect(() => {
		document.title = 'Sale Member Management';
	}, []);
	return (
		<>
			<TableUtilProvider>
				<ViewJoinGroupByUserModal />
			</TableUtilProvider>
			<CreateSaleEmployerDrawer />
			<EditSaleEmployerDrawer />
			<FilterSaleEmployerDrawer />
			<div className='fade-in'>
				<PageHeader
					title='Sale Employer Management'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
						<Button
							key='2'
							icon={<PlusCircleOutlined />}
							onClick={() => {
								createModalMethods.handleOpenModal({});
							}}
						>
							Create Sale Employer
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Name</div>,
								width: 200,
								dataIndex: 'full_name',
								key: 'full_name',
								render: (text, record) => {
									const data = _get(record, 'full_name', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 200,
								dataIndex: 'email',
								key: 'email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'email', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Num Of Group</div>,
								width: 150,
								dataIndex: 'email',
								key: 'email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'joinedGroups', '');
									return (
										<Space>
											<div>{data.length}</div>
											<Button
												size='small'
												onClick={() => {
													viewGroupModalMethods.handleOpenModal(record);
												}}
											>
												Detail
											</Button>
										</Space>
									);
								},
							},
							{
								title: () => <div>Role</div>,
								width: 100,
								dataIndex: 'role',
								key: 'role',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'role', 'Empty');

									return <StatusTag data={data} options={colorOptionsUserRole} />;
								},
							},
							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								align: 'center',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																navigator(adminRouter.viewSaleMemberById.href(record.uuid));
															}}
														>
															View Detail
														</Menu.Item>
														<Menu.Item
															onClick={() => {
																editModalMethods.handleOpenModal(record);
															}}
														>
															Edit
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={saleEmployerQuery.data?.data || []}
						isLoading={saleEmployerQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
