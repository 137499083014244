import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IV1SaleSystemMemberPagingByGroupId } from '../../../../core/apis/saleSystemGroup.api';
import { SelectInput, TextInput } from '../../../../core/components/form';
import { useModal, useTableUtil } from '../../../../core/contexts';
import { Comparator, User, optionsEmployerIsLiked } from '../../../../core/models';
import { addAllOptions, filterMapper } from '../../../../core/utils';

interface FilterGroupSaleForEmployerProps { }

export const FilterGroupSaleForEmployer: React.FC<FilterGroupSaleForEmployerProps> = () => {
	const filterModalMethod = useModal<User>('filterEmployerGroup');
	const tableMethods = useTableUtil();
	const formMethods = useForm<IV1SaleSystemMemberPagingByGroupId>({
		defaultValues: {
			companyName: '',
			email: '',
			hasActivity: '',
		},
	});

	const _handleOnSubmit = (data: IV1SaleSystemMemberPagingByGroupId) => {
		tableMethods.setFilters(
			filterMapper<IV1SaleSystemMemberPagingByGroupId>(
				data,
				{
					companyName: Comparator.LIKE,
					email: Comparator.LIKE,
					hasActivity: Comparator.EQUAL,
				},
				{
					companyName: 'filter.company.name',
					email: 'filter.employer.email',
				}
			)
		);
	};

	return (
		<Drawer
			open={filterModalMethod.getIsOpen()}
			title='Filter Group Sale For Employer'
			onClose={() => filterModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='email' label='Email' />
						</Space>
						<Space direction='vertical' className='w-full'>
							<TextInput name='companyName' label='Company Name' />
						</Space>
						<SelectInput name='hasActivity' label='Activity (Buy CV, Post job 60đ, Post job free)' options={addAllOptions(optionsEmployerIsLiked)} />
						<Button htmlType='submit' className='mt-6'>
							Apply
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
