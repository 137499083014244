import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { truncate } from 'lodash';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface InputFileProps {
	name: string;
	className?: string;
	placeholder?: string;
	disable?: boolean;
	accept?: string;
}

const InputFile: React.FC<InputFileProps> = ({
	name,
	className = '',
	placeholder = '',
	accept = '',
	disable = false,
}) => {
	const {
		control,
		setValue,
		formState: { errors },
	} = useFormContext();
	const [fileName, setFileName] = React.useState('');

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value, ...field } }) => {
				return (
					<div className={errors[name]?.message ? 'border-2 border-rose-500' : ''}>
						<Upload
							{...field}
							disabled={disable}
							className={className}
							accept={accept}
							maxCount={1}
							showUploadList={false}
							beforeUpload={(file) => {
								setFileName(file.name);
								return false;
							}}
							onChange={(file) => {
								setValue(name, file.file);
							}}
						>
							<Button icon={<UploadOutlined />} value={value}>
								{Boolean(fileName) ? truncate(fileName, { length: 40 }) : placeholder}
							</Button>
						</Upload>
					</div>
				);
			}}
		/>
	);
};

export default InputFile;
