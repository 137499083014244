import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TextInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import { User } from '../../../../core/models';

interface TopCountCvProps {
	setTop: any;
}

export const TopCountCv: React.FC<TopCountCvProps> = ({ setTop }) => {
	const filterModalMethod = useModal<User>('filter');
	const formMethods = useForm<any>({
		defaultValues: {
			top: '',
		},
	});

	const _handleOnSubmit = (data: any) => {
		setTop(data.top);
	};

	return (
		<Drawer
			open={filterModalMethod.getIsOpen()}
			title='Top Element Chart'
			onClose={() => filterModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='top' label='Top' />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Apply
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
