import moment from 'moment';
import { UserRole } from '../models';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../constants';

export const uppercaseFirstLetter = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDateTime = (date: Date) => {
	return moment(date).format('DD/MM/YYYY HH:mm:ss');
};

export const formMoneyVnd = (value: number) => {
	return value.toLocaleString('it-IT', { style: 'currency', currency: 'VND' });
};

export const translator = (key: string) => {
	const dictionary: Record<string, string> = {};

	return dictionary[key];
};

export const authRoleUser = (role: string) => {
	switch (role) {
		case UserRole.USER:
			toast.error('user cant access !!!');
			Cookies.remove(CONSTANTS.ACCESS_TOKEN_KEY);
			Cookies.remove(CONSTANTS.REFRESH_TOKEN_KEY);
			Cookies.remove(CONSTANTS.ROLE);
			break;
		case UserRole.ADMIN:
			toast.error('admin cant access !!!');
			Cookies.remove(CONSTANTS.ACCESS_TOKEN_KEY);
			Cookies.remove(CONSTANTS.REFRESH_TOKEN_KEY);
			Cookies.remove(CONSTANTS.ROLE);
			break;
		default:
			break;
	}
};
