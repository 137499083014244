import { Button, Drawer } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IV1CreateGroupMail, groupMailApi } from '../../../../core/apis';
import { TextInput, TextareaInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';

const defaultValues: IV1CreateGroupMail = {
	name: '',
	description: '',
};

interface CreateGroupMailDrawerProps {}

export const CreateGroupMailDrawer: React.FC<CreateGroupMailDrawerProps> = () => {
	const formMethods = useForm<IV1CreateGroupMail>({
		defaultValues,
	});
	const createGroupMailMethods = useModal('createGroupMail');

	const createGroupMailMutation = useMutation(
		(data: IV1CreateGroupMail) => {
			return groupMailApi.v1CreateGroupMail(data);
		},
		{
			onSuccess: () => {
				toast.success('Create Group Mail Success');
				createGroupMailMethods.handleCloseModal();
			},
			onError: () => {
				toast.error('Create Group Mail Failed, Please Try Again Later');
			},
		}
	);

	return (
		<Drawer
			title='Create Group Mail'
			open={createGroupMailMethods.getIsOpen()}
			onClose={createGroupMailMethods.handleCloseModal}
		>
			<FormProvider {...formMethods}>
				<form
					className='space-y-4'
					onSubmit={formMethods.handleSubmit((data) => {
						createGroupMailMutation.mutate(data);
					})}
				>
					<TextInput name='name' label='Name' />
					<TextareaInput name='description' label='Description' />
					<Button type='primary' htmlType='submit' loading={createGroupMailMutation.isLoading}>
						Create
					</Button>
				</form>
			</FormProvider>
		</Drawer>
	);
};
