import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import { uppercaseFirstLetter } from '../utils';

export interface ApiState {
	errorDetails: Record<string, string>;
	isError: boolean;
	errorMessage: string;
	statusCode: number;
}

const initialState: ApiState = {
	errorDetails: {},
	isError: false,
	errorMessage: '',
	statusCode: 0,
};

const reducer = createSlice({
	name: 'api',
	initialState,
	reducers: {
		initReq: (state) => ({ ...state, errorDetails: {}, isError: false }),
		resetState: () => ({ ...initialState }),
		updateErrorDetails: (
			state,
			{ payload }: PayloadAction<Record<string, string>>
		) => {
			const newState: ApiState = { ...state };
			const statusCode = _get(payload, 'statusCode', 0) as number;
			const messageList = _get(payload, 'message', []) as Array<any>;
			const errorDetails: Record<string, string> = {};

			if (typeof messageList === 'string') {
				newState.errorMessage = messageList;
			} else {
				messageList.forEach((item) => {
					const keys = Object.keys(item);
					keys.forEach((key) => {
						errorDetails[key] = uppercaseFirstLetter(item[key]);
					});
				});
				newState.errorDetails = errorDetails;
			}

			newState.statusCode = statusCode;
			newState.isError = true;
			return newState;
		},
	},
});

export const apiActions = {
	...reducer.actions,
};
export const apiReducer = reducer.reducer;
