import { Modal } from 'antd';
import React from 'react';
import { TableBuilder } from '../../../../core/components/table/TableBuilder';
import { useModal } from '../../../../core/contexts';
import { User } from '../../../../core/models';
import _get from 'lodash/get';
import moment from 'moment';

interface ViewJoinGroupByUserModalProps {}

export const ViewJoinGroupByUserModal: React.FC<ViewJoinGroupByUserModalProps> = () => {
	const modalMethods = useModal<User>('viewJoinGroupByUser');
	return (
		<Modal
			title='Join Group'
			open={modalMethods.getIsOpen()}
			footer={null}
			onCancel={() => modalMethods.handleCloseModal()}
		>
			<TableBuilder
				size='small'
				isShowPagination={false}
				data={modalMethods?.modalValue?.joinedGroups}
				columns={[
					{
						title: () => <div>Group Name</div>,
						width: 200,
						dataIndex: 'email',
						key: 'email',
						render: (text, record) => {
							const data = _get(record, 'group.name', 'Empty');

							return <>{data}</>;
						},
					},
					{
						title: () => <div>Join At</div>,
						width: 200,
						dataIndex: 'email',
						key: 'email',
						render: (text, record) => {
							const data = _get(record, 'group.updatedAt', 'Empty');

							return <>{moment(data).format('DD/MM/YYYY HH:MM:SS')}</>;
						},
					},
				]}
				isLoading={false}
				rowKey='id'
			/>
		</Modal>
	);
};
