import { FilterOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { salePointApi } from '../../../core/apis';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { useModal, useTableUtil } from '../../../core/contexts';
import { colorOptionsPointStatus, IResponseList, PointRequest } from '../../../core/models';
import { saleRouter } from '../../../core/routes';

interface SalePointRequestManagementProps {}

export const SalePointRequestManagement: React.FC<SalePointRequestManagementProps> = () => {
	const filterModalMethods = useModal('filter');
	const navigate = useNavigate();
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const pointQuery = useQuery<IResponseList<PointRequest>>(['group', { limit, page, filters }], async () => {
		const { data } = await salePointApi.v1PointRequestMe({
			limit,
			page,
			...filters,
		});

		const totalItems: number = _get(data, 'data.totalItems', 0);

		setTotalItems(totalItems);
		return data;
	});
	React.useEffect(() => {
		document.title = 'Sale - Point Request Management';
	}, []);
	return (
		<>
			<div className='fade-in'>
				<PageHeader
					title='Point Request Management'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Email</div>,
								width: 100,
								dataIndex: 'email',
								key: 'email',
								render: (text, record) => {
									const data = _get(record, 'employer.email', 'No Data');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Point</div>,
								width: 100,
								dataIndex: 'point',
								key: 'point',
								render: (text, record) => {
									const data = _get(record, 'point', 'No Data');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Group</div>,
								width: 100,
								dataIndex: 'group',
								key: 'group',
								render: (text, record) => {
									const data = _get(record, 'group.name', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Status</div>,
								width: 100,
								dataIndex: 'status',
								key: 'status',
								render: (text, record) => {
									const data = _get(record, 'status', 'Empty');

									return <>{<StatusTag data={data} options={colorOptionsPointStatus} />}</>;
								},
							},
							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																navigate(
																	saleRouter.viewPointRequestDetailById.href(
																		record.uuid
																	)
																);
															}}
														>
															View Detail
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={pointQuery.data?.data || []}
						isLoading={pointQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
