import { FilterOutlined } from '@ant-design/icons';
import { Button, PageHeader } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IV1UserPagingFilter, userApi } from '../../../../core/apis';
import { TableBuilder } from '../../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../../core/components/tag';
import { TableUtilProvider, useModal, useTableUtil } from '../../../../core/contexts';
import { Comparator, IResponseList, User, UserRole, colorOptionsUserRole } from '../../../../core/models';
import { adminRouter } from '../../../../core/routes';
import { filterMapper } from '../../../../core/utils';
import { FilterPermisstionUser } from './FilterPermission';
import { SetPermissionModal } from './SetPermissionUser';

interface AddPermissionCvProps {}

export const AddPermissionCv: React.FC<AddPermissionCvProps> = () => {
	const navigate = useNavigate();
	const filterModalMethods = useModal('filter');
	const addPermissionMethods = useModal('setPermission');
	const { limit, page, filters, setTotalItems, setFilters } = useTableUtil();
	const saleEmployerQuery = useQuery<IResponseList<User>>(['saleEmployer', { limit, page, filters }], async () => {
		const { data } = await userApi.v1Paging({
			limit,
			page,
			...filters,
		});
		const totalItems: number = _get(data, 'data.meta.totalItems', 0);
		setTotalItems(totalItems);
		return data.data;
	});
	React.useEffect(() => {
		setFilters(
			filterMapper<IV1UserPagingFilter>(
				{
					role: [UserRole.SALE, UserRole.SALE_ADMIN].join(','),
					name: '',
				},
				{
					role: Comparator.IN,
					name: Comparator.NONE,
				},
				{
					name: 'search',
				}
			)
		);
	}, []);
	React.useEffect(() => {
		document.title = 'Add Permisson User';
	}, []);
	return (
		<>
			<TableUtilProvider>
				<SetPermissionModal />
			</TableUtilProvider>
			<FilterPermisstionUser />
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(adminRouter.permissionCv.href());
					}}
					title='Add Permission Cv'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Name</div>,
								width: 200,
								dataIndex: 'full_name',
								key: 'full_name',
								render: (text, record) => {
									const data = _get(record, 'full_name', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 200,
								dataIndex: 'email',
								key: 'email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'email', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Role</div>,
								width: 100,
								dataIndex: 'role',
								key: 'role',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'role', 'Empty');
									return <StatusTag data={data} options={colorOptionsUserRole} />;
								},
							},
							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');
									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								dataIndex: 'action',
								key: 'action',
								align: 'center',
								render: (text, record) => {
									return (
										<Button
											size='small'
											onClick={() => {
												addPermissionMethods.handleOpenModal(record);
											}}
										>
											Set Permission
										</Button>
									);
								},
							},
						]}
						data={saleEmployerQuery.data?.data || []}
						isLoading={saleEmployerQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
