import { Input, InputProps } from 'antd';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldWrapper } from './FieldWrapper';

interface TextInputProps extends InputProps {
	name: string;
	label: string;
	disabled?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({ name, label, type = 'text', disabled, ...rest }) => {
	const { control } = useFormContext();
	return (
		<div className='mb-4'>
			<FieldWrapper name={name} label={label}>
				<Controller
					name={name}
					control={control}
					render={({ field }) => {
						return <Input {...field} {...rest} type={type} disabled={disabled} />;
					}}
				/>
			</FieldWrapper>
		</div>
	);
};
