import { Descriptions, Modal } from 'antd';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { CvAppliedCandidatesApi } from '../../../../core/apis/cvApplied.api';
import { useModal } from '../../../../core/contexts';

const QuestionById = ({}: {}) => {
	const modalMethods = useModal<any>('questions');

	const QuestionsByIdQuery = useQuery<any>(
		['questions', {}],
		async () => {
			const { data } = await CvAppliedCandidatesApi.v1QuestionsById(modalMethods.modalValue);
			return data;
		},
		{
			enabled: modalMethods.modalValue != undefined,
			onError: (error: any) => {
				toast.error(error.response.message);
			},
		}
	);
	return (
		<>
			{modalMethods.modalValue && QuestionsByIdQuery.data && (
				<Modal
					title={''}
					open={modalMethods.getIsOpen()}
					footer={null}
					centered
					onCancel={() => modalMethods.handleCloseModal()}
					width={800}
				>
					{QuestionsByIdQuery.data?.questions?.map((value, index) => {
						return (
							<Descriptions bordered title={''} column={1} key={index} style={{ marginBottom: '15px' }}>
								<Descriptions.Item label='Question'>{value.question}</Descriptions.Item>
								<Descriptions.Item label='Answers'>
									{value.answers?.map((item, index) => {
										return (
											<p key={index}>
												{item.answer} ({item.points} point)
											</p>
										);
									})}
								</Descriptions.Item>
							</Descriptions>
						);
					})}
				</Modal>
			)}
		</>
	);
};
export default QuestionById;
