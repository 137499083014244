import { stringify } from 'query-string';
import { Group, IPagingProps, UserRole } from '../models';
import { http } from './http';
import { IV1EmployerJoinGroup } from './saleSystemEmployer.api';

export interface IV1SaleSystemGroupPagingFilters extends Pick<Group, 'name'> {}
export interface IV1SaleSystemGroupSale extends IPagingProps<IV1SaleSystemGroupPagingFilters> {}
export interface IV1SaleSystemGroupMeFilters extends Pick<Group, 'name'> {}
export interface IV1SaleSystemGroupMe extends IPagingProps<IV1SaleSystemGroupPagingFilters> {}
export interface IV1SaleSystemCreateGroup extends Pick<Group, 'name'> {}
export interface IV1SaleSystemUpdateGroup extends Pick<Group, 'name'> {}
export interface IV1SaleSystemMemberPagingByGroupIdFilters {}
export interface IV1SaleSystemMemberPagingByGroupId extends IPagingProps<IV1SaleSystemMemberPagingByGroupIdFilters> {}
export interface IV1SaleSystemEmployerPagingByGroupIdFilters {}
export interface IV1SaleSystemEmployerPagingByGroupId
	extends IPagingProps<IV1SaleSystemEmployerPagingByGroupIdFilters> {}
export interface IV1AdminChangeRole {
	role: UserRole;
}

export const saleSystemGroupApi = {
	v1GroupSale: async (saleId: string, query: IV1SaleSystemGroupSale) => {
		const res = await http.get(
			`/v1/api/sale/group/sale/${saleId}?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res.data;
	},
	v1GroupPaging: async (query: IV1SaleSystemGroupSale) => {
		const res = await http.get(
			`/v1/api/sale/group/paging?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
	v1GroupMe: async (query: IV1SaleSystemGroupMe) => {
		const res = await http.get(
			`/v1/api/sale/group/me?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res.data;
	},

	v1CreateGroup: async (data: IV1SaleSystemCreateGroup) => {
		const res = await http.post('/v1/api/sale/group', data);
		return res;
	},
	v1UpdateGroup: async (groupId: string, data: IV1SaleSystemUpdateGroup) => {
		const res = await http.put(`/v1/api/sale/group/${groupId}`, data);
		return res;
	},
	v1MemberPagingByGroupId: async (groupId: string, query: IV1SaleSystemMemberPagingByGroupId) => {
		const res = await http.get(
			`/v1/api/sale/group/${groupId}/members/paging?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res.data;
	},
	v1EmployeePagingByGroupId: async (groupId: string, query: IV1SaleSystemMemberPagingByGroupId) => {
		const res = await http.get(
			`/v1/api/sale-employer-group/paging?${stringify({
				...query,
				...query.filters,
				'filter.group.uuid': groupId,
			})}`
		);
		return res.data;
	},
	v1GroupById: async (groupId: string) => {
		const res = await http.get(`/v1/api/sale/group/${groupId}`);
		return res.data;
	},
	v1RemoveMemberByGroupId: async (groupId: string, userId: string) => {
		const res = await http.delete(`/v1/api/sale/group/${groupId}/members`, {
			data: {
				userUuids: [userId],
			},
		});
		return res.data;
	},
	v1SaleGroupChangeRole: async (groupId: string, userId: string, data: IV1AdminChangeRole) => {
		const res = await http.put(`/v1/api/sale/group/${groupId}/members/${userId}`, data);
		return res.data;
	},
	v1AddMemberByGroupId: async (groupId: string, userId: string, role: string) => {
		const res = await http.post(`/v1/api/sale/group/${groupId}/members`, {
			members: [
				{
					userUuid: userId,
					role,
				},
			],
		});
		return res.data;
	},
	v1AddLeadEmployer: async (body: {
		companyName: string;
		email: string;
		phone: string;
		hotline: string;
		source: string;
	}) => {
		const res = await http.post('/v1/api/sale-employer-group/lead', body);
		return res.data;
	},
	v1JoinGroup: async (employerGroupId: string, data: IV1EmployerJoinGroup) => {
		const res = await http.put(`/v1/api/sale-employer-group/${employerGroupId}`, data);
		return res.data;
	},
	v1ClaimEmpGroup: async (employerGroupId: string, data: { saleUuid: string; groupUuid: string }) => {
		const res = await http.put(`/v1/api/sale-employer-group/${employerGroupId}`, data);
		return res.data;
	},
};
