/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Menu, PageHeader, Select, Space } from 'antd';
import _ from 'lodash';
import _get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { useModal, useTableUtil } from '../../../core/contexts';
import { IResponseList } from '../../../core/models';
import { colorsCvStatus } from '../../../core/models/cv';
import { adminRouter } from '../../../core/routes';
import { FilterListCV } from './components/FilterListCV';
import { ProfileFindNewApi } from '../../../core/apis/cvManagement.api';
import TickAndNoteModal from './components/TickAndNote';

const { Option } = Select;

interface CvManagementProps {}

export const CvManagement: React.FC<CvManagementProps> = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const tickAndNoteMethods = useModal('tickAndNote');
	const [search, setSearch] = useState<string>('');
	const { limit, page, setTotalItems, handleOnReset, setPage, filters, totalItems, setFilters, handleOnChangePage } =
		useTableUtil();

	const formMethods = useForm<any>({
		defaultValues: {
			search: '',
			'filter.industryid': '',
			'filter.joblevelid': '',
			'filter.city.id': '',
			'filter.type_job': '',
			'filter.status': '',
		},
	});

	const cvManagementQuery = useQuery<IResponseList<any>>(
		[
			'cvManagementQuery',
			{
				limit,
				page,
				search,
				filters,
			},
		],
		async () => {
			const queryParams = { ...filters, ...searchParams, page, limit };
			setSearchParams({ ...queryParams });

			const { data } = await ProfileFindNewApi.v1ProfilePaging(limit, page, filters, search);

			return data.data;
		},
		{
			onSuccess: (data) => {
				const totalItems: number = _get(data, 'meta.totalItems', 0);
				setTotalItems(totalItems);
			},
		}
	);

	const handleDetail = (item: any) => {
		const params = {};

		searchParams.forEach((value, key) => {
			params[key] = value;
		});

		navigate({
			pathname: adminRouter.cvManagementById.href(item),
			search: `?${createSearchParams(params)}`,
		});
	};

	useEffect(() => {
		document.title = 'Cv Management';
	}, []);
	useEffect(() => {
		const params = {};

		searchParams.forEach((value, key) => {
			params[key] = value;
		});

		const filterSpecialized = params['filter.industryid']?.replace('$in:', '') || '';
		const filterLevel = params['filter.joblevelid']?.replace('$in:', '') || '';
		const filterCityId = params['filter.city.id']?.replace('$in:', '') || '';
		const filterType = params['filter.type_job']?.replace('$eq:', '') || '';
		const filterStatus = params['filter.status']?.replace('$eq:', '') || '';

		formMethods.setValue('filter.industryid', filterSpecialized);
		formMethods.setValue('filter.joblevelid', filterLevel);
		formMethods.setValue('filter.city.id', filterCityId);
		formMethods.setValue('filter.status', filterStatus);
		formMethods.setValue('filter.type_job', filterType);

		setFilters(params);
	}, []);
	useEffect(() => {
		const _filters = {
			...filters,
			'filter.industryid': `$in:${formMethods.watch('filter.industryid')}` || '',
			'filter.joblevelid': `$in:${formMethods.watch('filter.joblevelid')}` || '',
			'filter.city.id': `$in:${formMethods.watch('filter.city.id')}` || '',
			'filter.type_job': `$eq:${formMethods.watch('filter.type_job')}` || '',
			'filter.status': `$eq:${formMethods.watch('filter.status')}` || '',
		};
		if (_.isEqual(_filters, filters)) return;

		setFilters(_filters);
		setPage('1');
	}, [
		formMethods.watch('filter.industryid'),
		formMethods.watch('filter.joblevelid'),
		formMethods.watch('filter.city.id'),
		formMethods.watch('filter.type_job'),
		formMethods.watch('filter.status'),
	]);
	useEffect(() => {
		setSearch(formMethods.watch('search'));
	}, [formMethods.watch('search')]);
	return (
		<>
			<TickAndNoteModal refetchData={() => cvManagementQuery.refetch()} />
			<div className='fade-in'>
				<PageHeader title='List Cv' />
				<div className='px-4 space-y-4'>
					<FilterListCV formMethods={formMethods} />
					<TableBuilder
						columns={[
							{
								title: () => <div>Full Name</div>,
								width: 100,
								dataIndex: 'full_name',
								key: 'full_name',
								render: (text, record) => {
									const data = _get(record, 'full_name', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Birth Day</div>,
								width: 100,
								dataIndex: 'birth_day',
								key: 'birth_day',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'birth_day', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Type Job</div>,
								width: 100,
								dataIndex: 'type_job',
								key: 'type_job',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'type_job', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Specialized</div>,
								width: 100,
								dataIndex: 'specialized',
								key: 'specialized',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'specialized', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Level</div>,
								width: 100,
								dataIndex: 'level',
								key: 'level',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'level', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Tick</div>,
								width: 50,
								dataIndex: 'isSaleTicked',
								key: 'isSaleTicked',
								align: 'center',
								render: (text, record) => {
									const data = record?.isSaleTicked === true ? true : false;
									return (
										<>
											<Checkbox defaultChecked={data} disabled checked={data}></Checkbox>
										</>
									);
								},
							},
							{
								title: () => <div>Note</div>,
								width: 50,
								dataIndex: 'saleNote',
								key: 'saleNote',
								align: 'center',
								render: (text, record) => {
									const data = record?.saleNote ? record?.saleNote : '-';
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Status</div>,
								width: 50,
								dataIndex: 'status',
								key: 'status',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'status', '');

									return (
										<>
											<StatusTag data={data} options={colorsCvStatus} />
										</>
									);
								},
							},
							{
								title: () => <div>Action</div>,
								width: 50,
								align: 'center',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu
														items={[
															{
																key: '1',
																label: <div onClick={() => handleDetail(record.uuid)}>View Detail</div>,
															},
															{
																key: '2',
																label: (
																	<div
																		onClick={() =>
																			tickAndNoteMethods.handleOpenModal({
																				uuid: record.uuid,
																				saleNote: record.saleNote,
																				isSaleTicked: record.isSaleTicked,
																			})
																		}
																	>
																		Tick & Note
																	</div>
																),
															},
														]}
													/>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={cvManagementQuery.data?.data || []}
						isLoading={cvManagementQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
