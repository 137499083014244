import { MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Popconfirm, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { groupMailApi } from '../../../core/apis';
import { TextInput } from '../../../core/components/form';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal, useTableUtil } from '../../../core/contexts';
import { adminRouter } from '../../../core/routes';
import { CreateGroupMailDrawer } from './components/CreateGroupMailDrawer';

interface GroupMailPageProps {}

export const GroupMailPage: React.FC<GroupMailPageProps> = () => {
	const [search, setSearch] = React.useState('');
	const createGroupMailMethods = useModal('createGroupMail');
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const navigate = useNavigate();
	const groupMailQuery = useQuery(
		['groupMail', { limit, page, filters, search }, createGroupMailMethods.getIsOpen()],
		async () => {
			const { data } = await groupMailApi.v1GroupMailPaging({
				limit,
				page,
				search,
				...filters,
			});
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);

			setTotalItems(totalItems);
			return data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const deleteGroupMailMutation = useMutation((groupMailId: string) => groupMailApi.v1DeleteGroupMail(groupMailId), {
		onSuccess: () => {
			toast.success('Delete group mail successfully!');
			groupMailQuery.refetch();
		},
		onError: () => {
			toast.error('Delete group mail failed!');
		},
	});
	const formMethods = useForm<any>({
		defaultValues: {
			search: '',
		},
	});
	React.useEffect(() => {
		setSearch(formMethods.watch('search'));
	}, [formMethods.watch('search')]);
	React.useEffect(() => {
		document.title = 'Group Mail Management';
	}, []);
	return (
		<>
			<CreateGroupMailDrawer />
			<div className='fade-in'>
				<PageHeader
					title='Group Mail Management'
					extra={[
						<Button
							key='2'
							icon={<PlusCircleOutlined />}
							onClick={() => {
								createGroupMailMethods.handleOpenModal({});
							}}
						>
							Create Group Mail
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4'>
					<div className=''>
						<FormProvider {...formMethods}>
							<form className='w-full flex items-end justify-end'>
								<TextInput name='search' label='Search' placeholder='search..' className='w-52' />
							</form>
						</FormProvider>
					</div>
					<TableBuilder
						columns={[
							{
								title: () => <div>Group Name</div>,
								width: 100,
								dataIndex: 'name',
								key: 'name',
								render: (text, record) => {
									const data = _get(record, 'name', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Description</div>,
								width: 200,
								dataIndex: 'description',
								key: 'description',
								render: (text, record) => {
									const data = _get(record, 'description', 'Empty');

									return <>{data}</>;
								},
							},

							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},

							{
								title: () => <div>Action</div>,
								width: 100,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									const uuid = _get(record, 'uuid', '');
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																navigate(adminRouter.groupMailDetailById.href(uuid));
															}}
														>
															View Detail
														</Menu.Item>
														<Popconfirm
															title='Are you sure to delete this group mail?'
															onConfirm={() => {
																deleteGroupMailMutation.mutate(uuid);
															}}
														>
															<Menu.Item danger>Delete</Menu.Item>
														</Popconfirm>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={groupMailQuery.data || []}
						isLoading={groupMailQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};

export default GroupMailPage;
