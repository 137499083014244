import { Modal } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { saleSystemGroupApi } from '../../../../core/apis';
import { TextInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';

interface V1LeadEmployer {
	companyName: string;
	email: string;
	phone: string;
	hotline: string;
	source: string;
}

function AddNewLeadEmployerModal({ refetchData }: { refetchData: () => void }) {
	const addNewLeadEmployerModal = useModal<any>('addNewLeadEmployer');
	const formMethods = useForm<V1LeadEmployer>({});

	const addNewLeadEmpMutation = useMutation(
		(data: V1LeadEmployer) => {
			return saleSystemGroupApi.v1AddLeadEmployer(data);
		},
		{
			onSuccess: (data) => {
				toast.success(data.message);
				refetchData();
				addNewLeadEmployerModal.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);
	const _handleSubmit = (data: any) => {
		addNewLeadEmpMutation.mutate(data);
	};
	return (
		<>
			<Modal
				title={'Add New Lead Employer'}
				open={addNewLeadEmployerModal.getIsOpen()}
				onOk={formMethods.handleSubmit(_handleSubmit)}
				centered
				onCancel={() => {
					addNewLeadEmployerModal.handleCloseModal();
				}}
				width={800}
			>
				<FormProvider {...formMethods}>
					<div className='w-full'>
						<TextInput name='companyName' label='Company Name' placeholder='company name...' />
						<TextInput name='email' label='Email' placeholder='email...' />
						<TextInput name='phone' label='Phone' placeholder='phone...' />
						<TextInput name='hotline' label='Hot Line' placeholder='hotline...' />
						<TextInput name='source' label='Source' placeholder='source...' />
					</div>
				</FormProvider>
			</Modal>
		</>
	);
}

export default AddNewLeadEmployerModal;
