/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Descriptions, Modal, PageHeader, Popconfirm } from 'antd';
import _get from 'lodash/get';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1UpdateEmployerGroup, groupEmployerApi } from '../../../core/apis/groupEmployer';
import { InputRichEditor } from '../../../core/components/form/InputRichEditor';
import { StatusTag } from '../../../core/components/tag';
import { useModal } from '../../../core/contexts';
import { colorsEmployerStatus } from '../../../core/models/employer';
import { adminRouter } from '../../../core/routes';
import { UpdateEmpGroupDrawer } from './components/UpdateEmployerDrawer';
import { truncate } from 'lodash';

interface EmployerGroupByIdProps {}

function NoteModal({ refetchData }: { refetchData: () => void }) {
	const noteModalMethods = useModal<any>('note');
	const schema = yup.object().shape({});
	const formMethods = useForm<any>({
		defaultValues: {
			note: '',
		},
		resolver: yupResolver(schema),
	});
	const addNoteMutation = useMutation(
		(data: any) => groupEmployerApi.v1NoteById(noteModalMethods.modalValue?.id, data),
		{
			onSuccess: () => {
				toast.success('success');
				refetchData();
				noteModalMethods.handleCloseModal();
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const editNoteMutation = useMutation(
		(data: any) =>
			groupEmployerApi.v1EditById(noteModalMethods.modalValue?.id, noteModalMethods.modalValue?.noteId, data),
		{
			onSuccess: () => {
				toast.success('success');
				refetchData();
				noteModalMethods.handleCloseModal();
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const employerGroupNoteByIdQuery = useQuery<any>(
		['employerGroupNoteById', noteModalMethods.modalValue?.id, noteModalMethods.modalValue?.noteId],
		async () => {
			const res = await groupEmployerApi.v1NoteInGroupById(
				noteModalMethods.modalValue?.id,
				noteModalMethods.modalValue?.noteId
			);

			return res.data.data;
		},
		{
			enabled: noteModalMethods.modalValue != undefined && noteModalMethods.modalValue?.type === 'edit',
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				const { note } = data;
				formMethods.setValue('note', note);
			},
		}
	);
	const _handleSubmit = (data: any) => {
		if (noteModalMethods.modalValue?.type === 'edit') {
			editNoteMutation.mutate(data);
		} else {
			addNoteMutation.mutate(data);
		}
	};
	return (
		<>
			<Modal
				title={'Add Note'}
				open={noteModalMethods.getIsOpen()}
				onOk={formMethods.handleSubmit(_handleSubmit)}
				centered
				onCancel={() => {
					noteModalMethods.handleCloseModal();
				}}
				width={800}
			>
				<FormProvider {...formMethods}>
					<div className='w-full'>
						{noteModalMethods.modalValue?.type === 'edit' ? (
							employerGroupNoteByIdQuery.data && <InputRichEditor name='note' label='Note' placeholder='note..' />
						) : (
							<InputRichEditor name='note' label='Note' placeholder='note..' />
						)}
					</div>
				</FormProvider>
			</Modal>
		</>
	);
}

function UpdateCallNoteModal({ refetchData }: { refetchData: () => void }) {
	const updateCallNoteMethod = useModal<any>('callNote');
	const formMethods = useForm<any>({
		defaultValues: {
			groupUuid: undefined,
			saleUuid: undefined,
			scale: undefined,
			type: undefined,
			other_note: undefined,
			status: undefined,
			callNote: '',
		},
	});
	const employerGroupServiceIdQuery = useQuery<any>(
		['employer-group-service-id', updateCallNoteMethod.modalValue],
		async () => {
			const res = await groupEmployerApi.v1ById(updateCallNoteMethod.modalValue);
			return res.data.data;
		},
		{
			enabled: updateCallNoteMethod.modalValue != undefined,
			onSuccess: (data) => {
				const { callNote } = data;
				formMethods.setValue('callNote', callNote);
			},
		}
	);
	const updateCallNoteMutation = useMutation(
		async (data: IV1UpdateEmployerGroup) => {
			const res = await groupEmployerApi.v1UpdateById(updateCallNoteMethod.modalValue, data);

			return res;
		},
		{
			onSuccess: (data: any) => {
				refetchData();
				toast.success(data.message);
				updateCallNoteMethod.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);
	const _handleSubmit = (data: any) => {
		updateCallNoteMutation.mutate(data);
	};
	return (
		<>
			{employerGroupServiceIdQuery.data && (
				<Modal
					title={'Edit Call Note'}
					open={updateCallNoteMethod.getIsOpen()}
					onOk={formMethods.handleSubmit(_handleSubmit)}
					centered
					onCancel={() => {
						updateCallNoteMethod.handleCloseModal();
					}}
					width={800}
				>
					<FormProvider {...formMethods}>
						<div className='w-full'>
							<InputRichEditor name='callNote' label='Call Note' placeholder='note..' className='' />
						</div>
					</FormProvider>
				</Modal>
			)}
		</>
	);
}

const EmployerGroupById: React.FC<EmployerGroupByIdProps> = () => {
	const updateModalMethods = useModal('update');
	const noteModalMethods = useModal('note');
	const updateCallNoteMethod = useModal('callNote');
	const [shownDetail, setShownDetail] = useState<any>({});
	const [showLeadCallNote, setShowLeadCallNote] = useState(false);
	const handleSeeDetail = (id: any) => {
		setShownDetail((prevShownComments: any) => ({
			...prevShownComments,
			[id]: !prevShownComments[id],
		}));
	};
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();
	const { id = '' } = useParams<{ id: string }>();
	const employerGroupByIdQuery = useQuery<any>(
		['employerGroupById', id],
		async () => {
			const res = await groupEmployerApi.v1ById(id);
			return res.data.data;
		},
		{
			enabled: Boolean(id),
			refetchOnWindowFocus: false,
		}
	);
	const deleteNoteMutation = useMutation((noteId: string) => groupEmployerApi.v1DeleteNote(id, noteId), {
		onSuccess: () => {
			toast.success('delete success');
			employerGroupByIdQuery.refetch();
		},
		onError: (error: any) => {
			toast.error(error?.response?.data?.message || 'error');
		},
	});
	useEffect(() => {
		document.title = `Employer Group | ${employerGroupByIdQuery.data?.lead?.companyName}`;
	}, []);
	return (
		<>
			<UpdateEmpGroupDrawer refetch={() => employerGroupByIdQuery.refetch()} />
			{noteModalMethods.getIsOpen() && <NoteModal refetchData={() => employerGroupByIdQuery.refetch()} />}
			{updateCallNoteMethod.getIsOpen() && <UpdateCallNoteModal refetchData={() => employerGroupByIdQuery.refetch()} />}
			<div className='fade-in'>
				<PageHeader
					title={`${employerGroupByIdQuery.data?.lead?.companyName}`}
					onBack={() => {
						const params = {};

						searchParams.forEach((value, key) => {
							params[key] = value;
						});

						navigate({
							pathname: adminRouter.employerManagement.href(),
							search: createSearchParams(params).toString(),
						});
					}}
					extra={[
						<Button
							key={'add-note'}
							type='primary'
							icon={<PlusCircleOutlined />}
							onClick={() =>
								noteModalMethods.handleOpenModal({
									id: id,
									type: 'create',
								})
							}
						>
							Add Note
						</Button>,
						!employerGroupByIdQuery.data?.group && (
							<Button
								key='2'
								type='primary'
								icon={<PlusCircleOutlined />}
								onClick={() => {
									navigate(adminRouter.addEmployerToGroup.href(id));
								}}
							>
								Add Group Service
							</Button>
						),
						<Button
							key='1'
							type='default'
							icon={<EditOutlined />}
							onClick={() => {
								updateModalMethods.handleOpenModal({});
							}}
						>
							Update
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4 '>
					<Descriptions bordered className='bg-white'>
						<Descriptions.Item label='Lead Company Name'>
							{_get(employerGroupByIdQuery.data, 'lead.companyName', '')}
						</Descriptions.Item>
						<Descriptions.Item label='Lead Email'>{employerGroupByIdQuery.data ? employerGroupByIdQuery.data.lead.email : ''}</Descriptions.Item>
						<Descriptions.Item label='Lead Phone' span={3}>
							{_get(employerGroupByIdQuery.data, 'lead.phone', '')}
						</Descriptions.Item>
						<Descriptions.Item label='Lead Call Note' span={3}>
							<Card className='mt-4'>
								<div className='flex flex-row justify-between content-center w-full'>
									<div
										className={`w-3/4 ${showLeadCallNote ? 'see' : 'hide'}`}
										dangerouslySetInnerHTML={{
											__html: employerGroupByIdQuery.data
												? !showLeadCallNote
													? truncate(employerGroupByIdQuery.data.callNote, { length: 100 })
													: employerGroupByIdQuery.data.callNote
												: '',
										}}
									></div>
									<div className='w-1/4 flex justify-end'>
										<Button className='mx-2' onClick={() => setShowLeadCallNote(!showLeadCallNote)}>
											{showLeadCallNote ? <>hide note</> : <>see more</>}
										</Button>
										<Button className='mx-2'>
											<EditOutlined onClick={() => updateCallNoteMethod.handleOpenModal(id)} />
										</Button>
									</div>
								</div>
							</Card>
						</Descriptions.Item>
						<Descriptions.Item label='Lead Notes' span={3}>
							{employerGroupByIdQuery.data?.saleEmployerGroupNotes?.map((e, index) => {
								return (
									<Card key={index} className='mt-4'>
										<div className='flex flex-row justify-between content-center w-full'>
											<div
												className={`w-3/4 ${shownDetail[index] ? 'see' : 'hide'}`}
												dangerouslySetInnerHTML={{ __html: e.note }}
											></div>
											<div className='w-1/4 flex justify-end'>
												<Button className='mx-2' onClick={() => handleSeeDetail(index)}>
													{shownDetail[index] ? <>hide note</> : <>see more</>}
												</Button>
												<Button className='mx-2'>
													<EditOutlined
														onClick={() =>
															noteModalMethods.handleOpenModal({
																id: id,
																noteId: e.uuid,
																type: 'edit',
															})
														}
													/>
												</Button>
												<Popconfirm
													title='Are you sure to delete this note?'
													onConfirm={() => {
														deleteNoteMutation.mutate(e.uuid);
													}}
													placement='bottomLeft'
												>
													<Button className='mx-2'>
														<DeleteOutlined />
													</Button>
												</Popconfirm>
											</div>
										</div>
									</Card>
								);
							})}
						</Descriptions.Item>
						<Descriptions.Item label='Lead Source'>
							{_get(employerGroupByIdQuery.data, 'lead.source', '')}
						</Descriptions.Item>
					</Descriptions>

					<Descriptions bordered className='bg-white'>
						<Descriptions.Item label='Sale Name'>
							{employerGroupByIdQuery.data?.sale?.full_name || '-'}
						</Descriptions.Item>
						<Descriptions.Item label='Employer Full Name'>
							{employerGroupByIdQuery.data?.employer?.full_name || '-'}
						</Descriptions.Item>
						<Descriptions.Item label='Employer Email'>
							{employerGroupByIdQuery.data?.employer?.email || ''}
						</Descriptions.Item>
						<Descriptions.Item label='Employer Phone'>
							{employerGroupByIdQuery.data?.employer?.phone || ''}
						</Descriptions.Item>
						<Descriptions.Item label='Employer Status'>
							<StatusTag data={employerGroupByIdQuery.data?.status} options={colorsEmployerStatus} />
						</Descriptions.Item>
						<Descriptions.Item label='Employer Type'>
							<StatusTag data={employerGroupByIdQuery.data?.type || ''} options={colorsEmployerStatus} />
						</Descriptions.Item>
						<Descriptions.Item label='Group' span={3}>
							{employerGroupByIdQuery.data?.group?.name || ''}
						</Descriptions.Item>
						<Descriptions.Item label='Employer Note' span={3}>
							<div dangerouslySetInnerHTML={{ __html: employerGroupByIdQuery.data?.other_note || '' }}></div>
						</Descriptions.Item>
					</Descriptions>
				</div>
			</div>
		</>
	);
};

export default EmployerGroupById;
