import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { CvAppliedCandidatesApi } from '../../../../core/apis/cvApplied.api';
import { SelectInput } from '../../../../core/components/form';
import { EJobTag } from '../../../../core/models/cv';
import { useEffect } from 'react';

const listTag = [
	{
		label: 'none',
		value: EJobTag.NONE,
		data: '',
	},
	{
		label: 'Hot Here',
		value: EJobTag.HOT_HERE,
		data: '',
	},
	// {
	// 	label: 'For you',
	// 	value: EJobTag.FOR_YOU,
	// 	data: '',
	// },
	{
		label: 'Urgent',
		value: EJobTag.URGENT,
		data: '',
	},
];

const AddTag = ({
	openTag,
	setOpenTag,
	cvId,
	jobData,
}: {
	openTag: boolean;
	setOpenTag: React.Dispatch<React.SetStateAction<boolean>>;
	cvId: string;
	jobData: any;
}) => {
	const schema = yup.object().shape({});
	const formMethods = useForm<any>({
		defaultValues: {
			tag: jobData || '',
		},
		resolver: yupResolver(schema),
	});
	const jobTagMutiation = useMutation((data: any) => CvAppliedCandidatesApi.v1TagById(cvId, data), {
		onSuccess: () => {
			setOpenTag(false);
			toast.success('success');
		},
		onError: (error: any) => {
			toast.error(error?.response?.data?.message || 'error');
		},
	});
	const _handleSubmit = (data: any) => {
		jobTagMutiation.mutate(data);
	};
	useEffect(() => {
		if (jobData) {
			formMethods.setValue('tag', jobData);
		}
	}, [jobData]);
	return (
		<>
			<Modal
				title={'Add tag'}
				open={openTag}
				onOk={formMethods.handleSubmit(_handleSubmit)}
				centered
				onCancel={() => setOpenTag(false)}
				width={800}
			>
				<FormProvider {...formMethods}>
					<form className='w-full'>
						<SelectInput className='w-full text-sm' name='tag' label='' options={listTag} />
					</form>
				</FormProvider>
			</Modal>
		</>
	);
};
export default AddTag;
