import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Drawer, Space } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1UpdateEmployerGroup, groupEmployerApi } from '../../../../core/apis/groupEmployer';
import { SelectInput } from '../../../../core/components/form';
import { InputRichEditor } from '../../../../core/components/form/InputRichEditor';
import { useModal } from '../../../../core/contexts';
import {
	EEmployerScale,
	EEmployerStatus,
	EEmployerType,
	Employer,
	optionsEmployerScale,
	optionsEmployerStatus,
	optionsEmployerType,
} from '../../../../core/models/employer';

const schema = yup.object().shape({
	name: yup.string().required('Name is required'),
});

interface UpdateEmpGroupDrawerProps {
	refetch: () => void;
}

export const UpdateEmpGroupDrawer: React.FC<UpdateEmpGroupDrawerProps> = ({ refetch }) => {
	const updateModalMethod = useModal('update');
	const { id = '' } = useParams<{ id: string }>();

	const defaultValues: IV1UpdateEmployerGroup = {
		scale: EEmployerScale.MIN_0_TO_10,
		status: EEmployerStatus.NEW,
		type: EEmployerType.SMALL,
		other_note: '',
		groupUuid: undefined,
		saleUuid: undefined,
	};
	const formMethods = useForm<IV1UpdateEmployerGroup>({
		defaultValues,
		resolver: yupResolver(schema),
	});
	useQuery<Employer>(
		['employer-group-service-id', id],
		async () => {
			const res = await groupEmployerApi.v1ById(id);

			return res.data;
		},
		{
			enabled: updateModalMethod.getIsOpen(),
			onSuccess: (data: any) => {
				formMethods.setValue('scale', data.data?.scale);
				formMethods.setValue('status', data.data?.status);
				formMethods.setValue('type', data.data?.type);
				formMethods.setValue('other_note', data.data?.other_note);
			},
		}
	);

	const updateEmployerMutation = useMutation(
		async (data: IV1UpdateEmployerGroup) => {
			const res = await groupEmployerApi.v1UpdateById(id, data);

			return res;
		},
		{
			onSuccess: (data: any) => {
				refetch();
				toast.success(data.message);
				updateModalMethod.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);

	const _handleOnSubmit = (data: IV1UpdateEmployerGroup) => {
		updateEmployerMutation.mutate(data);
	};

	return (
		<Drawer
			open={updateModalMethod.getIsOpen()}
			title='Update Employer'
			onClose={() => updateModalMethod.handleCloseModal()}
			width={800}
			footer={
				<FormProvider {...formMethods}>
					<form id='updateForm' onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Button
							className='mt-6'
							onClick={() => {
								_handleOnSubmit({ ...formMethods.getValues() });
							}}
						>
							Update
						</Button>
					</form>
				</FormProvider>
			}
		>
			<div>
				<FormProvider {...formMethods}>
					<form id='updateForm' onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<SelectInput name='scale' label='Scale' options={optionsEmployerScale} />
							<SelectInput name='status' label='Status' options={optionsEmployerStatus} />
							<SelectInput name='type' label='Type' options={optionsEmployerType} />
							<InputRichEditor name='other_note' label='Employer Note' placeholder='note..' className='h-96' />
						</Space>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
