import { FilterOutlined } from '@ant-design/icons';
import { Button, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IV1UserPagingFilter, userApi } from '../../../core/apis';
import { saleSystemGroupApi } from '../../../core/apis/saleSystemGroup.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { useModal, useTableUtil } from '../../../core/contexts';
import { Comparator, GroupMember, IResponseList, User, UserRole, colorOptionsUserRole } from '../../../core/models';
import { adminRouter } from '../../../core/routes';
import { filterMapper } from '../../../core/utils';
import { FilterSaleEmployerDrawer } from '../saleMembers/components/FilterSaleEmployerDrawer';
interface AddMemberByGroupIdProps {}

export const AddMemberByGroupId: React.FC<AddMemberByGroupIdProps> = () => {
	const navigate = useNavigate();
	const filterModalMethods = useModal('filter');
	const { groupId = '' } = useParams<{ groupId: string }>();
	const { limit, page, setTotalItems, filters, setFilters } = useTableUtil();
	const memberQuery = useQuery<IResponseList<GroupMember>>(['member-group', groupId], async () => {
		const { data } = await saleSystemGroupApi.v1MemberPagingByGroupId(groupId, {
			limit: 100000,
			page: 1,
			...filters,
		});
		const totalItems: number = _get(data, 'data.meta.totalItems', 0);

		setTotalItems(totalItems);
		return data;
	});

	const addMemberMutation = useMutation(
		(user: User) => {
			return saleSystemGroupApi.v1AddMemberByGroupId(groupId, user.uuid, user.role);
		},
		{
			onSuccess: () => {
				addMemberQuery.refetch();
				memberQuery.refetch();
				toast.success('Add member success');
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);
	const addMemberQuery = useQuery<IResponseList<User>>(
		[
			'add-member-group',
			groupId,
			{
				limit,
				page,
				filters,
			},
			memberQuery.data,
		],
		async () => {
			const { data } = await userApi.v1Paging({
				limit,
				page,
				...filters,
			});

			data.data.data = data.data.data.filter((item) => {
				const isExist = memberQuery.data?.data.find((member) => member.user.uuid === item.uuid);
				return !isExist;
			});
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);

			setTotalItems(totalItems);
			return data.data;
		}
	);
	React.useEffect(() => {
		setFilters(
			filterMapper<IV1UserPagingFilter>(
				{
					role: [UserRole.SALE, UserRole.SALE_ADMIN].join(','),
					name: '',
				},
				{
					role: Comparator.IN,
					name: Comparator.NONE,
				},
				{
					name: 'search',
				}
			)
		);
	}, []);
	React.useEffect(() => {
		document.title = 'Add Member Group Sale';
	}, []);
	return (
		<>
			<FilterSaleEmployerDrawer />
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(adminRouter.viewGroupDetailById.href(groupId));
					}}
					title='Add Member To Group'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
					]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className='max-w-4xl'></div>
					<div>
						<TableBuilder
							columns={[
								{
									title: () => <div>Name</div>,
									width: 200,
									dataIndex: 'full_name',
									key: 'full_name',
									render: (text, record) => {
										const data = _get(record, 'full_name', 'Empty');

										return <>{data}</>;
									},
								},
								{
									title: () => <div>Email</div>,
									width: 200,
									dataIndex: 'email',
									key: 'email',
									render: (text, record) => {
										const data = _get(record, 'email', 'Empty');

										return <>{data}</>;
									},
								},
								{
									title: () => <div>Num Of Group</div>,
									width: 150,
									dataIndex: 'email',
									key: 'email',
									render: (text, record) => {
										const data = _get(record, 'joinedGroups', '');

										return <>{data.length}</>;
									},
								},
								{
									title: () => <div>Role</div>,
									width: 100,
									dataIndex: 'role',
									key: 'role',
									render: (text, record) => {
										const data = _get(record, 'role', 'Empty');

										return <StatusTag data={data} options={colorOptionsUserRole} />;
									},
								},

								{
									title: () => <div>Action</div>,
									width: 100,
									fixed: 'right',
									dataIndex: 'action',
									key: 'action',
									render: (text, record) => {
										return (
											<Space size='middle'>
												<Button
													onClick={() => {
														addMemberMutation.mutate(record);
													}}
												>
													Add Member
												</Button>
											</Space>
										);
									},
								},
							]}
							data={addMemberQuery.data?.data || []}
							isLoading={addMemberQuery.isLoading}
							rowKey='uuid'
						/>
					</div>
				</div>
			</div>
		</>
	);
};
