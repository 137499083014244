/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery } from 'react-query';
import { ProfileFindNewApi } from '../apis/cvManagement.api';
import { IOptionItem } from '../models';

export enum SelectOption {
	CITY = 'city',
	DISTRICT_BY_ID = 'DISTRICT_BY_ID',
	WARD_BY_ID = 'WARD_BY_ID',
	INDUSTRY_BY_ID = 'INDUSTRY_BY_ID',
	INDUSTRY_BY_NAME = 'INDUSTRY_BY_NAME',
}

export const useSelectOption = (option: SelectOption, query?: any, allOptions = false) => {
	const [options, setOptions] = React.useState<IOptionItem[]>([]);
	const city = useQuery(['city', query], () => ProfileFindNewApi.getAllCity(), {
		enabled: option === SelectOption.CITY,
	});
	const district = useQuery(['district', query], () => ProfileFindNewApi.getAllDistrictByID(query), {
		enabled: query != null && option === SelectOption.DISTRICT_BY_ID,
	});
	const ward = useQuery(['ward', query], () => ProfileFindNewApi.getAllWardByID(query), {
		enabled: query != null && option === SelectOption.WARD_BY_ID,
	});
	const industry = useQuery(['industry', query], () => ProfileFindNewApi.industry(query), {
		enabled: option === SelectOption.INDUSTRY_BY_NAME || option === SelectOption.INDUSTRY_BY_ID,
	});
	const _handleOnGetOptions = React.useCallback(async () => {
		let options: IOptionItem[] = [];
		switch (option) {
			case SelectOption.CITY:
				options =
					city.data?.data?.data?.map((item: any) => ({
						label: item.name,
						value: item.id,
						data: item.id,
					})) || [];
				break;
			case SelectOption.DISTRICT_BY_ID:
				options =
					district.data?.data?.data?.map((item: any) => ({
						label: item.name,
						value: item.id,
						data: item.id,
					})) || [];
				break;
			case SelectOption.WARD_BY_ID:
				options =
					ward.data?.data?.data?.map((item: any) => ({
						label: item.name,
						value: item.id,
						data: item.id,
					})) || [];
				break;
			case SelectOption.INDUSTRY_BY_NAME:
				options =
					industry.data?.data?.map((item: any) => ({
						label: item.industryname,
						value: item.industryname,
						data: item.industryname,
					})) || [];
				break;
			case SelectOption.INDUSTRY_BY_ID:
				options =
					industry.data?.data.map((item: any) => ({
						label: item.industryname,
						value: item.industryid,
						data: item.industryid,
					})) || [];
				break;
		}
		if (allOptions) {
			options.unshift({
				label: 'All',
				value: '',
				data: '',
			});
		}
		setOptions(options);
	}, [option, city.data, district.data, ward.data, industry.data]);

	React.useEffect(() => {
		_handleOnGetOptions();
	}, [_handleOnGetOptions]);

	return options;
};
