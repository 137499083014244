import { Button, Drawer, Popconfirm } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IV1ImportSheet, groupMailApi } from '../../../../core/apis';
import { TextInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';

const defaultValues: IV1ImportSheet = {
	sheetName: '',
};

interface ImportFromSheetDrawerProps {}

const ImportFromSheetDrawer: React.FC<ImportFromSheetDrawerProps> = () => {
	const formMethods = useForm<IV1ImportSheet>({
		defaultValues,
	});
	const params = useParams<{
		groupMailId: string;
	}>();
	const importSheetMethods = useModal('importSheet');

	const importSheetMutation = useMutation(
		(data: IV1ImportSheet) => {
			return groupMailApi.v1ImportSheet(params?.groupMailId || '', data);
		},
		{
			onSuccess: () => {
				toast.success('Import Sheet Success');
				importSheetMethods.handleCloseModal();
			},
			onError: () => {
				toast.error('Import Sheet Failed, Please Try Again Later');
			},
		}
	);

	return (
		<Drawer
			title='Import From Sheet'
			open={importSheetMethods.getIsOpen()}
			onClose={importSheetMethods.handleCloseModal}
		>
			<FormProvider {...formMethods}>
				<form className='space-y-2'>
					<TextInput name='sheetName' label='Sheet Name' />
					<Popconfirm
						title='Are you sure to import from sheet?'
						onConfirm={() => {
							importSheetMutation.mutate(formMethods.getValues());
						}}
					>
						<Button type='primary' loading={importSheetMutation.isLoading}>
							Import From Sheet
						</Button>
					</Popconfirm>
				</form>
			</FormProvider>
		</Drawer>
	);
};

export default ImportFromSheetDrawer;
