import { Descriptions, PageHeader, Tabs } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { userApi } from '../../../core/apis';
import { saleSystemGroupApi } from '../../../core/apis/saleSystemGroup.api';
import { StatusTag } from '../../../core/components/tag';
import { useTableUtil } from '../../../core/contexts';
import { colorOptionsUserRole, Group, IResponseList, User } from '../../../core/models';
import { adminRouter } from '../../../core/routes';
import _get from 'lodash/get';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import moment from 'moment';

interface ViewSaleMemberByIdProps {}

export const ViewSaleMemberById: React.FC<ViewSaleMemberByIdProps> = () => {
	const navigate = useNavigate();
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const { saleId = '' } = useParams<{ saleId: string }>();

	const userQuery = useQuery<User>(['user', saleId], async () => {
		return userApi.v1GetById(saleId);
	});

	const groupQuery = useQuery<IResponseList<Group>>(
		[
			'group',
			saleId,
			{
				limit,
				page,
				filters,
			},
		],
		async () => {
			const { data } = await saleSystemGroupApi.v1GroupSale(saleId, {
				limit,
				page,
				...filters,
			});
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);

			setTotalItems(totalItems);
			return data;
		}
	);
	React.useEffect(() => {
		document.title = 'Sale Member Management';
	}, []);
	return (
		<>
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(adminRouter.saleMemberManagement.href());
					}}
					title='Sale Employer Detail'
					extra={[]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className='max-w-4xl'>
						<Descriptions bordered title='Basic Information'>
							<Descriptions.Item label='Name'>{userQuery.data?.full_name}</Descriptions.Item>
							<Descriptions.Item label='Email'>{userQuery.data?.email}</Descriptions.Item>
							<Descriptions.Item label='Phone'>{userQuery.data?.phone}</Descriptions.Item>
							<Descriptions.Item label='Role'>
								<StatusTag data={userQuery.data?.role} options={colorOptionsUserRole} />
							</Descriptions.Item>
						</Descriptions>
					</div>
					<div>
						<Tabs defaultActiveKey='1'>
							<Tabs.TabPane tab='Group' key='2'>
								<TableBuilder
									columns={[
										{
											title: () => <div>Group Name</div>,
											width: 200,
											dataIndex: 'group_name',
											key: 'group_name',
											render: (text, record) => {
												const data = _get(record, 'group.name', 'Empty');

												return <>{data}</>;
											},
										},
										{
											title: () => <div>Create At</div>,
											width: 100,
											dataIndex: 'createdAt',
											key: 'createdAt',
											render: (text, record) => {
												const data = _get(record, 'createdAt', 'Empty');

												return <>{moment(data).format('DD/MM/YYYY HH:MM:SS')}</>;
											},
										},
										{
											title: () => <div>Join At</div>,
											width: 100,
											dataIndex: 'updatedAt',
											key: 'createdAupdatedAtt',
											render: (text, record) => {
												const data = _get(record, 'updatedAt', 'Empty');

												return <>{moment(data).format('DD/MM/YYYY HH:MM:SS')}</>;
											},
										},
									]}
									data={groupQuery.data?.data || []}
									isLoading={groupQuery.isLoading}
									rowKey='uuid'
								/>
							</Tabs.TabPane>
						</Tabs>
					</div>
				</div>
			</div>
		</>
	);
};
