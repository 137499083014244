import _get from 'lodash/get';
import { stringify } from 'query-string';
import { IPagingProps } from '../models';
import { http } from './http';

export interface IV1WhiteListFilters {
	search: string;
}
export interface IV1WhiteList extends IPagingProps<IV1WhiteListFilters> {}

export const whiteListApi = {
	v1Get: async (query: IV1WhiteList) => {
		const res = await http.get(
			`/v1/api/white-list/paging?${stringify({
				...query,
				...query.filters,
			})}`
		);

		return _get(res, 'data');
	},

	v1Post: async (userUuid: string) => {
		const res = await http.post('/v1/api/white-list', {
			userUuid,
		});
		return _get(res, 'data.data');
	},
	v1GetByUuid: async (uuid: string) => {
		const res = await http.get(`/v1/api/white-list/${uuid}`);
		return _get(res, 'data.data');
	},

	v1Put: async (uuid: string, userUuid: string) => {
		const res = await http.put(`/v1/api/white-list/${uuid}`, {
			userUuid,
		});
		return _get(res, 'data.data');
	},
	v1Delete: async (userUuid: string) => {
		const res = await http.delete(`/v1/api/white-list/${userUuid}`);
		return _get(res, 'data.data');
	},
};
