import { Modal } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { TextInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import { dataSaleApi } from '../../../../core/apis/dataSale';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

interface V1AddData {
	companyName: string;
	email: string;
	phone: string;
	otherPhone: string;
	source: string;
	dataType: string;
	picName: string;
	note: string;
	mailingNote: string;
}
const schema = yup
	.object()
	.shape({
		companyName: yup.string(),
		email: yup.string(),
		phone: yup.string(),
		otherPhone: yup.string(),
		source: yup.string(),
		dataType: yup.string(),
		picName: yup.string(),
		note: yup.string(),
		mailingNote: yup.string(),
	})
	.test('atLeastOneOf', '${path} must have at least one of these fields: ${fields}', function (value) {
		const fields = [
			'companyName',
			'email',
			'phone',
			'otherPhone',
			'source',
			'dataType',
			'picName',
			'note',
			'mailingNote',
		];
		return fields.some((field) => !!value[field]);
	});

function AddOrUpdateDataModal({ refetchData, record }: { refetchData: () => void; record?: any }) {
	const addOrUpdateData = useModal<any>('addOrUpdateData');

	const formMethods = useForm<V1AddData>(
		record
			? { defaultValues: record, resolver: yupResolver(schema) }
			: { defaultValues: {}, resolver: yupResolver(schema) }
	);

	const addOrUpdateDataMutation = useMutation(
		(data: V1AddData) => {
			if (record) {
				return dataSaleApi.v1EditById(record.uuid, data);
			} else {
				return dataSaleApi.v1AddData(data);
			}
		},
		{
			onSuccess: (data) => {
				if (record) {
					toast.success(data.data.message);
				} else {
					toast.success(data.message);
				}
				refetchData();
				addOrUpdateData.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);

	const _handleSubmit = (data: any) => {
		addOrUpdateDataMutation.mutate(data);
	};

	useEffect(() => {
		if (Object.keys(formMethods.formState.errors).length) {
			toast.warning('Please must have at least one of these fields');
		}
	}, [formMethods.formState.errors]);
	
	return (
		<>
			<Modal
				title={'Add Data'}
				open={addOrUpdateData.getIsOpen()}
				onOk={formMethods.handleSubmit(_handleSubmit)}
				centered
				onCancel={() => {
					addOrUpdateData.handleCloseModal();
				}}
				width={800}
			>
				<FormProvider {...formMethods}>
					<div className='w-full h-[700px] overflow-y-auto'>
						<TextInput name='companyName' label='Company Name' placeholder='company name...' />
						<TextInput name='email' label='Email' placeholder='email...' />
						<TextInput name='phone' label='Phone' placeholder='phone...' />
						<TextInput name='otherPhone' label='Other Phone' placeholder='Other phone...' />
						<TextInput name='source' label='Source' placeholder='source...' />
						<TextInput name='dataType' label='Data Type' placeholder='Data Type...' />
						<TextInput name='picName' label='PIC Name' placeholder='PIC Name...' />
						<TextInput name='note' label='Note' placeholder='Note...' />
						<TextInput name='mailingNote' label='Note Mail' placeholder='Note mail...' />
					</div>
				</FormProvider>
			</Modal>
		</>
	);
}

export default AddOrUpdateDataModal;
