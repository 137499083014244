export const CONSTANTS = Object.freeze({
	REFRESH_TOKEN_KEY: 'refresh_token',
	ACCESS_TOKEN_KEY: 'access_token',
	DEFAULT_SORT_FIELD: 'createAt',
	PAGING_DEFAULT_LIMIT: 5,
	ROLE: 'role',
});

export const CONSTANT = {
	IMAGE: process.env.REACT_APP_IMAGE_URL || 'https://dev-api.jobfi.vn/v1/api' || 'http://localhost:3000',
};
