/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, CheckboxProps } from 'antd';
import { ReactNode, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CheckBox = ({
	name,
	content,
	checkBoxProps,
}: {
	name: string;
	content: ReactNode;
	checkBoxProps: CheckboxProps;
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => {
				return (
					<div>
						<Checkbox {...checkBoxProps}>{content}</Checkbox>
					</div>
				);
			}}
		/>
	);
};
export default memo(CheckBox);
