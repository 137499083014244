import { Comparator, IOptionItem } from '../models';
import _get from 'lodash/get';
import color from 'color';

export const addAllOptions = (options: IOptionItem[]) => {
	return [
		{
			label: 'All',
			value: '',
			data: '',
		},
		...options,
	] as IOptionItem[];
};

export function filterMapper<T extends Object>(
	filters: T,
	comparator: Record<keyof T, Comparator>,
	transferKey?: Partial<Record<keyof T, string>>
) {
	const validFilters = Object.keys(filters).filter((item) => filters[item as keyof T] !== undefined);
	return validFilters.reduce<Record<string, any>>((pre, cur) => {
		const value: any = _get(filters, `${cur}`);
		const newKey = _get(transferKey, `${cur}`, cur);
		if (!value) {
			return pre;
		}

		return {
			[`${_get(transferKey, `${cur}`, '') ? `${newKey}` : `filter.${newKey}`}`]: `${
				comparator[cur as keyof T]
			}${value}`,
			...pre,
		};
	}, {});
}
function hex(str: string) {
	for (var i = 0, hash = 0; i < str.length; hash = str.charCodeAt(i++) + ((hash << 5) - hash));

	var color = Math.floor(Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216)).toString(16);

	return '#' + Array(6 - color.length + 1).join('0') + color;
}

export function colorspace(namespace: string, delimiter?: string) {
	if (!namespace) namespace = '';
	var split = namespace.split(delimiter || ':');
	var base = hex(split[0]);

	if (!split.length) return base;

	for (var i = 0, l = split.length - 1; i < l; i++) {
		base = color(base)
			.mix(color(hex(split[i + 1])))
			.saturate(1)
			.hex();
	}

	return base;
}
