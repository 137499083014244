import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldWrapper } from './FieldWrapper';

interface TextareaInputProps extends TextAreaProps {
	name: string;
	label: string;
}

export const TextareaInput: React.FC<TextareaInputProps> = ({ name, label, ...rest }) => {
	const { control } = useFormContext();

	return (
		<FieldWrapper name={name} label={label}>
			<Controller control={control} name={name} render={({ field }) => <TextArea {...field} {...rest} />} />
		</FieldWrapper>
	);
};
