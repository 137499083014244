import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Descriptions, PageHeader } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { saleSystemEmployerApi } from '../../../core/apis';
import { StatusTag } from '../../../core/components/tag';
import { useModal } from '../../../core/contexts';
import { colorsEmployerScale, colorsEmployerStatus, colorsEmployerType, Employer } from '../../../core/models/employer';
import { saleAdminRouter } from '../../../core/routes';
import { UpdateEmployerDrawer } from './components/UpdateEmployerDrawer';

interface SaleAdminViewEmployerByIdProps {}

export const SaleAdminViewEmployerById: React.FC<SaleAdminViewEmployerByIdProps> = () => {
	const navigate = useNavigate();
	const { employerId = '', groupId = '' } = useParams<{ employerId: string; groupId: string }>();
	const updateModalMethods = useModal('update');
	const employerQuery = useQuery<Employer>(['employer', employerId, updateModalMethods.getIsOpen()], async () => {
		const res = await saleSystemEmployerApi.v1GetById(employerId);

		return res.data;
	});
	React.useEffect(() => {
		document.title = 'Sale Admin - Employer Management';
	}, []);
	return (
		<>
			<UpdateEmployerDrawer />
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(saleAdminRouter.viewEmployerByGroupId.href(groupId));
					}}
					title='Employer Detail'
					extra={[
						<Button
							key='1'
							type='primary'
							icon={<PlusCircleOutlined />}
							onClick={() => {
								navigate(
									saleAdminRouter.addSaleToEmployer.href({
										employerId,
										groupId,
									})
								);
							}}
						>
							Add Sale To Employer
						</Button>,
						<Button
							key='1'
							type='default'
							icon={<EditOutlined />}
							onClick={() => {
								updateModalMethods.handleOpenModal({});
							}}
						>
							Update
						</Button>,
					]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className=''>
						<Descriptions bordered title='Employer Information'>
							<Descriptions.Item label='Name'>
								{_get(employerQuery.data, 'employer.full_name', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Email'>{employerQuery.data?.employer.email}</Descriptions.Item>
							<Descriptions.Item label='Phone'>{employerQuery.data?.employer.phone}</Descriptions.Item>
							<Descriptions.Item label='Status'>
								<StatusTag
									data={_get(employerQuery.data, 'status', '')}
									options={colorsEmployerStatus}
								/>
							</Descriptions.Item>
							<Descriptions.Item label='Scale'>
								<StatusTag data={_get(employerQuery.data, 'scale', '')} options={colorsEmployerScale} />
							</Descriptions.Item>
							<Descriptions.Item label='Type'>
								<StatusTag data={_get(employerQuery.data, 'type', '')} options={colorsEmployerType} />
							</Descriptions.Item>
							<Descriptions.Item label='Create At' span={1}>
								{moment(employerQuery.data?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
							</Descriptions.Item>
							<Descriptions.Item label='Update At' span={2}>
								{moment(employerQuery.data?.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
							</Descriptions.Item>
							<Descriptions.Item label='Note' span={3}>
								{employerQuery.data?.other_note}
							</Descriptions.Item>
						</Descriptions>
					</div>
					<div>
						<Descriptions bordered title='Current Sale Service'>
							<Descriptions.Item label='Sale Name' span={2}>
								{_get(employerQuery.data?.sale, 'full_name', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Sale Email' span={1}>
								{_get(employerQuery.data?.sale, 'email', 'No Data')}
							</Descriptions.Item>
						</Descriptions>
					</div>
				</div>
			</div>
		</>
	);
};
