import { stringify } from 'query-string';
import { GroupMail, GroupMailMember, IPagingProps } from '../models';
import { http } from './http';

export interface IV1GroupMailPagingFilters {}
export interface IV1GroupMailPaging extends IPagingProps<IV1GroupMailPagingFilters> {
	search: string;
}
export interface IV1CreateGroupMail extends Pick<GroupMail, 'name' | 'description'> {}
export interface IV1UpdateGroupMail extends Pick<GroupMail, 'name' | 'description'> {}
export interface IV1AddGroupMember extends Pick<GroupMailMember, 'name' | 'email'> {}
export interface IV1UpdateGroupMember extends Pick<GroupMailMember, 'name' | 'email'> {
	memberUuid: string;
}
export interface IV1SendMail {
	template: string;
	metadata: string;
	subject: string;
	text: string;
}

export interface IV1ImportSheet {
	sheetName: string;
}

export const groupMailApi = {
	v1GroupMailPaging: async (query: IV1GroupMailPaging) => {
		const res = await http.get(
			`/v1/api/group-mail?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res.data;
	},
	v1CreateGroupMail: async (data: IV1CreateGroupMail) => {
		const res = await http.post('/v1/api/group-mail', data);
		return res.data;
	},
	v1GetGroupMailById: async (id: string) => {
		const res = await http.get(`/v1/api/group-mail/${id}`);
		return res.data;
	},
	v1AddGroupMember: async (id: string, data: IV1AddGroupMember) => {
		const res = await http.post(`/v1/api/group-mail/member/${id}`, data);
		return res.data;
	},
	v1UpdateGroupMail: async (id: string, data: IV1UpdateGroupMail) => {
		const res = await http.patch(`/v1/api/group-mail/${id}`, data);
		return res.data;
	},
	v1UpdateGroupMember: async (id: string, data: IV1UpdateGroupMember) => {
		const res = await http.patch(`/v1/api/group-mail/member/${id}`, data);
		return res.data;
	},
	v1DeleteGroupMail: async (id: string) => {
		const res = await http.delete(`/v1/api/group-mail/${id}`);
		return res.data;
	},
	v1DeleteGroupMailMember: async (id: string, memberId) => {
		const res = await http.delete(`/v1/api/group-mail/member/${id}/${memberId}`);
		return res.data;
	},
	v1ImportSheet: async (id: string, data: IV1ImportSheet) => {
		const res = await http.post(`/v1/api/group-mail/sheet/${id}`, data);
		return res.data;
	},
	v1SendMail: async (id: string, data: IV1SendMail) => {
		const res = await http.post(`/v1/api/group-mail/send/${id}`, data);
		return res.data;
	},
};
