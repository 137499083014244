import { Button, Drawer } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IV1MailerCreate, sendMailManagemntApi } from '../../../core/apis/sendMail.api';
import { SelectInput, TextInput, TextareaInput } from '../../../core/components/form';
import { useModal } from '../../../core/contexts';
import { EMailTemplate } from '../../../core/models/sendMail';

const defaultValues: IV1MailerCreate = {
	name: '',
	template: 0,
	description: '',
};

const listTemplate = [
	{
		label: 'INTRODUCE_NEW_FEATURE',
		value: EMailTemplate.INTRODUCE_NEW_FEATURE,
	},
	{
		label: 'REMIND_CREATE_ACCOUNT',
		value: EMailTemplate.REMIND_CREATE_ACCOUNT,
	},
	{
		label: 'REMIND_WRITE_FORM',
		value: EMailTemplate.REMIND_WRITE_FORM,
	},
	{
		label: 'REMIND_USE_POINT',
		value: EMailTemplate.REMIND_USE_POINT,
	},
	{
		label: 'INTRODUCE_OLD_EMPLOYER',
		value: EMailTemplate.INTRODUCE_OLD_EMPLOYER,
	},
	{
		label: 'INVITE_RECUIT',
		value: EMailTemplate.INVITE_RECUIT,
	},
	{
		label: 'PRICE_TABLE_EMPLOYER',
		value: EMailTemplate.PRICE_TABLE_EMPLOYER,
	},
	{
		label: 'PRICE_TABLE_OLD_EMPLOYER',
		value: EMailTemplate.PRICE_TABLE_OLD_EMPLOYER,
	},
];

interface CreateMailDrawerProps {}

export const CreateMailDrawer: React.FC<CreateMailDrawerProps> = () => {
	const createGroupMailMethods = useModal('createMailer');
	const formMethods = useForm<IV1MailerCreate>({
		defaultValues,
	});

	const createGroupMailMutation = useMutation(
		(data: IV1MailerCreate) => {
			return sendMailManagemntApi.v1MailerCreate(data);
		},
		{
			onSuccess: () => {
				toast.success('Create Group Mail Success');
				createGroupMailMethods.handleCloseModal();
			},
			onError: () => {
				toast.error('Create Group Mail Failed, Please Try Again Later');
			},
		}
	);

	return (
		<Drawer
			title='Create Group Mail'
			open={createGroupMailMethods.getIsOpen()}
			onClose={createGroupMailMethods.handleCloseModal}
		>
			<FormProvider {...formMethods}>
				<form
					className='space-y-4'
					onSubmit={formMethods.handleSubmit((data) => {
						createGroupMailMutation.mutate(data);
					})}
				>
					<TextInput name='name' label='Campaign Name' />
					<SelectInput className='w-full' name='template' label='Template' options={listTemplate} />
					<TextareaInput name='description' label='Description' />
					<Button type='primary' htmlType='submit' loading={createGroupMailMutation.isLoading}>
						Create
					</Button>
				</form>
			</FormProvider>
		</Drawer>
	);
};
