import { IOptionItem } from './interface';

export interface Group {
	createdAt: string;
	updatedAt: string;
	uuid: string;
	name: string;
	group: {
		uuid: string;
		name: string;
	};
}

export interface GroupMember {
	createdAt: string;
	updatedAt: string;
	uuid: string;
	role: string;
	group: {
		uuid: string;
		name: string;
	};
	user: {
		uuid: string;
		email: string;
		full_name: string;
		phone: string;
		role: string;
	};
}

export const optionsEmployerIsLiked: IOptionItem[] = [
	{
		value: 'true',
		label: 'Active',
		data: 'true',
	},
	{
		value: 'false',
		label: 'Inactive',
		data: 'false',
	},
];
