import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1CreatePointRequest, salePointApi } from '../../../../core/apis';
import { TextInput, TextareaInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import { Employer } from '../../../../core/models/employer';

const defaultValues: IV1CreatePointRequest = {
	employerUuid: '',
	point: 0,
	groupUuid: '',
	reason: '',
	purchaseOrderUuids: [],
};

const schema = yup.object().shape({
	point: yup.number().min(1).max(1000).required('Point is required'),
	reason: yup.string().required('Reason is required'),
});

interface CreatePointRequestDrawerProps {}

export const CreatePointRequestDrawer: React.FC<CreatePointRequestDrawerProps> = () => {
	const createPointModalMethod = useModal<Employer>('createPoint');
	const formMethods = useForm<IV1CreatePointRequest>({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const updateEmployerMutation = useMutation(
		async (input: IV1CreatePointRequest) => {
			const { data } = await salePointApi.v1CreatePointRequest({
				...input,
				point: Number(input.point),
				groupUuid: createPointModalMethod.modalValue.group.uuid,
				employerUuid: createPointModalMethod.modalValue.employer.uuid,
			});

			return data;
		},
		{
			onSuccess: (data: any) => {
				toast.success(data.message);
				createPointModalMethod.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);

	const _handleOnSubmit = (data: IV1CreatePointRequest) => {
		updateEmployerMutation.mutate(data);
	};

	return (
		<Drawer
			open={createPointModalMethod.getIsOpen()}
			title='Create Point Request'
			onClose={() => createPointModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form id='updateForm' onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='point' label='Point' type='number' />
							<TextareaInput name='reason' label='Reason' />
						</Space>

						<Button
							className='mt-6'
							onClick={() => {
								_handleOnSubmit({ ...formMethods.getValues() });
							}}
						>
							Submit
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
