import { Modal } from 'antd';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CvAppliedCandidatesApi } from '../../../../../core/apis/cvApplied.api';
import { configs } from '../../../../../core/config';
import { useModal } from '../../../../../core/contexts';
import { EJobCommentStatus } from '../../../../../core/models/cv';
import AnswerCandidateItem from './AnswerCandidateItem';

const ModalReplies = () => {
	const repliesMethod = useModal<any>('replies');

	const jobCommentReplyById = useQuery<any>(['job-comment-reply', repliesMethod.modalValue?.uuid], async () => {
		if (repliesMethod.modalValue?.uuid) {
			const { data } = await CvAppliedCandidatesApi.JobCommentReplyById(repliesMethod.modalValue?.uuid);
			return data?.data;
		}
	});
	const statusCommentText: any = useMemo(
		() => ({
			[EJobCommentStatus.APPROVED]: 'Approved',
			[EJobCommentStatus.PENDING]: 'Pending',
			[EJobCommentStatus.REJECTED]: 'Rejected',
		}),
		[]
	);
	const statusCommentClass: any = useMemo(
		() => ({
			[EJobCommentStatus.APPROVED]: 'bg-[#cdffc8]',
			[EJobCommentStatus.PENDING]: 'bg-[#f9ffb6]',
			[EJobCommentStatus.REJECTED]: 'bg-[#ffadad]',
		}),
		[]
	);

	return (
		<Modal
			title={''}
			open={repliesMethod.getIsOpen()}
			footer={null}
			centered
			onCancel={() => repliesMethod.handleCloseModal()}
			width={800}
		>
			<div className='container'>
				<div>
					<div className='shadow-xl rounded-[8px] p-[24px] mb-[20px] w-full'>
						<div>
							<div className='flex'>
								<img
									src={`${configs.REACT_APP_IMAGE_URL}${jobCommentReplyById?.data?.user?.avatar || ''}`}
									alt='avatar'
									className='object-cover rounded w-[40px] h-[40px]'
								/>
								<div className='ml-[16px]'>
									<div className='flex items-center mb-1'>
										<h3 className='text-[#787878] text-[14px] mb-0'>{jobCommentReplyById?.data?.user?.full_name}</h3>
										{jobCommentReplyById?.data?.status && <div className={`w-fit ml-[10px] rounded px-[8px] py-[2px] ${statusCommentClass[jobCommentReplyById?.data?.status]}`}>
											{statusCommentText[jobCommentReplyById?.data?.status]}
										</div>}
									</div>
									<p className='text-[16px]'>{jobCommentReplyById?.data?.comment}</p>
								</div>
							</div>
							<div>
								{jobCommentReplyById?.data?.replies?.map((answerItem, index: number) => (
									<AnswerCandidateItem answerItem={answerItem} key={`answerItem-${index}`} statusCommentText={statusCommentText} statusCommentClass={statusCommentClass} />
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalReplies;
