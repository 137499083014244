/* eslint-disable @typescript-eslint/no-unused-vars */
import { FilterOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Descriptions, Dropdown, Input, Menu, Modal, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FeedBackApi } from '../../../core/apis/feedBack.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { TableUtilProvider, useModal, useTableUtil } from '../../../core/contexts';
import { IResponseList, ListResponse } from '../../../core/models';
import { colorFeedBackStatus } from '../../../core/models/employer';
import { saleOrderApi } from '../../../core/apis/saleOrder.api';
import { FilterEmployerDrawer } from './components/FilterSaleEmployerDrawer';
import { typeSaleOrder } from '../../../core/models/saleOrder';

interface SaleOrderMgtProps { }

export const SaleOrderAdminMgt: React.FC<SaleOrderMgtProps> = () => {
	const filterModalMethods = useModal('filter');
	const { limit, page, setTotalItems, filters } = useTableUtil();

	const saleOrderQuery = useQuery<IResponseList<any>>(
		[
			'saleOrder',
			{
				limit,
				page,
				filters,
			},
		],
		async () => {
			const { data } = await saleOrderApi.v1Paging({
				limit,
				page,
				...filters,
			});
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);

			setTotalItems(totalItems);
			return data.data;
		}
	);
	React.useEffect(() => {
		document.title = 'Sale Admin - Sale Order';
	}, []);

	return (
		<>
			<FilterEmployerDrawer />

			<div className='fade-in'>
				<PageHeader
					title='Sale Order Management'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Email</div>,
								width: 100,
								dataIndex: 'user.email',
								key: 'user.email',
								render: (text, record) => {
									const data = _get(record, 'user.email', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Type</div>,
								width: 100,
								dataIndex: 'type',
								key: 'type',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'type', 'Empty');
									const formatData = typeSaleOrder.find((item) => data.includes(item.value))?.label || ''

									return <>{formatData}</>;
								},
							},
							{
								title: () => <div>Before Point</div>,
								width: 100,
								dataIndex: 'point_before',
								key: 'point_before',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'point_before', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>After Point</div>,
								width: 100,
								dataIndex: 'point_current',
								key: 'point_current',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'point_current', 'Empty');

									return (
										<>
											{data}
										</>
									);
								},
							},
							{
								title: () => <div>Created At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
						]}
						data={saleOrderQuery.data?.data || []}
						isLoading={saleOrderQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
