/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Input, Menu, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { CompanyApi } from '../../../core/apis/company.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { TableUtilProvider, useModal, useTableUtil } from '../../../core/contexts';
import { IResponseList } from '../../../core/models';
import { CompanyDetailModal } from './ModalDetail';
interface CompanyPageProps {}

export const CompanyPage: React.FC<CompanyPageProps> = () => {
	const companyDetailModalMethods = useModal('companyDetail');
	const [searchParams, setSearchParams] = useSearchParams();
	const { limit, page, setTotalItems, handleOnReset } = useTableUtil();
	const [search, setSearch] = useState('');
	const companyPagingQuery = useQuery<IResponseList<any>>(
		[
			'company',
			{
				limit,
				page,
				search,
			},
		],
		async () => {
			const { data } = await CompanyApi.v1CompanyPaging(limit, page, search);
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);
			setTotalItems(totalItems);
			return data.data;
		}
	);
	React.useEffect(() => {
		document.title = 'Company Management';
	}, []);

	return (
		<>
			<TableUtilProvider>
				<CompanyDetailModal handleReset={handleOnReset} />
			</TableUtilProvider>
			<div className='fade-in'>
				<PageHeader
					title='List Company'
					extra={[
						<Input
							className='w-full'
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							placeholder='search...'
						/>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Name</div>,
								width: 100,
								dataIndex: 'name',
								key: 'name',
								render: (text, record) => {
									const data = _get(record, 'name', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Name En</div>,
								width: 100,
								dataIndex: 'nameEn',
								key: 'nameEn',
								render: (text, record) => {
									const data = _get(record, 'nameEn', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 100,
								dataIndex: 'email',
								key: 'email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'email', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>User Email</div>,
								width: 100,
								dataIndex: 'user.email',
								key: 'user.email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'user.email', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Address</div>,
								width: 50,
								dataIndex: 'address',
								key: 'address',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'address', '');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 50,
								align: 'center',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									const uuid = _get(record, 'uuid', 'Empty');
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																const params = {};
																searchParams.forEach((value, key) => {
																	params[key] = value;
																});
																if (uuid) {
																	companyDetailModalMethods.handleOpenModal(uuid);
																}
															}}
															key={record.uuid}
														>
															View Detail
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={companyPagingQuery.data?.data || []}
						isLoading={companyPagingQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
