import axios, { AxiosError } from 'axios';
import { configs } from '../config';
import _get from 'lodash/get';
import { store } from '../stores';
import { apiActions } from '../stores/api.reducer';
import Cookie from 'universal-cookie';
import { CONSTANTS } from '../constants';

const http = axios.create({
	baseURL: configs.API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});
http.interceptors.request.use(function (req) {
	store.dispatch(apiActions.initReq());
	const cookie = new Cookie();
	const accessToken = cookie.get(CONSTANTS.ACCESS_TOKEN_KEY) || '';
	if (req.headers && accessToken) req.headers['Authorization'] = `Bearer ${accessToken}`;

	return req;
});
http.interceptors.response.use(
	function (response) {
		store.dispatch(apiActions.resetState());

		return response;
	},
	function (error: AxiosError) {
		store.dispatch(apiActions.resetState());
		if (error.response?.status)
			store.dispatch(apiActions.updateErrorDetails(_get(error, 'response.data', {}) as any));

		return Promise.reject(error.response);
	}
);

export { http };
