import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IV1UpdatePointRequest, salePointApi } from '../../../../core/apis';
import { TextareaInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import { EEmployerPointRequestStatus, PointRequest } from '../../../../core/models';

const defaultValues: IV1UpdatePointRequest = {
	reason: '',
	status: EEmployerPointRequestStatus.pending,
};

interface UpdatePointRequestDrawerProps {}

export const UpdatePointRequestDrawer: React.FC<UpdatePointRequestDrawerProps> = () => {
	const createModalMethod = useModal('update');
	const { pointRequestId = '' } = useParams<{
		pointRequestId: string;
	}>();
	const formMethods = useForm<IV1UpdatePointRequest>({ defaultValues });
	useQuery<PointRequest>(
		['point-request-detail', pointRequestId],
		async () => {
			const { data } = await salePointApi.v1GetById(pointRequestId);
			return data;
		},
		{
			onSuccess: (data) => {
				formMethods.setValue('reason', data.reason);
				formMethods.setValue('status', data.status);
			},
		}
	);
	const updateRequestStatusMutation = useMutation(
		async (data: IV1UpdatePointRequest) => {
			const res = await salePointApi.v1UpdatePointRequest(pointRequestId, data);

			return res.data;
		},
		{
			onSuccess: () => {
				createModalMethod.handleCloseModal();
				toast.success('Update point request successfully');
			},
		}
	);

	const _handleOnSubmit = (data: IV1UpdatePointRequest) => {
		updateRequestStatusMutation.mutate(data);
	};

	return (
		<Drawer
			open={createModalMethod.getIsOpen()}
			title='Update Point Request'
			onClose={() => createModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							{/* <SelectInput name='status' label='Status' options={pointStatusOptions} /> */}
							<TextareaInput name='reason' label='Reason' />
						</Space>
						<Space>
							<Button
								type='primary'
								onClick={() => {
									_handleOnSubmit({
										reason: formMethods.getValues().reason,
										status: EEmployerPointRequestStatus.approved,
									});
								}}
								className='mt-6'
							>
								Accept
							</Button>
							<Button
								type='primary'
								danger
								onClick={() => {
									_handleOnSubmit({
										reason: formMethods.getValues().reason,
										status: EEmployerPointRequestStatus.denied,
									});
								}}
								className='mt-6'
							>
								Deny
							</Button>
						</Space>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
