import { BarsOutlined, SearchOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Modal, PageHeader } from 'antd';
import _get from 'lodash/get';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ProfileFindNewApi } from '../../../../core/apis/cvManagement.api';
import { SelectInput, TextInput, TextareaInput } from '../../../../core/components/form';
import { TableBuilder } from '../../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../../core/components/tag';
import { TableUtilProvider, useModal, useTableUtil } from '../../../../core/contexts';
import { useDebounce } from '../../../../core/hooks/useDebounce';
import { IResponseList } from '../../../../core/models';
import {
	EPointRefundReportStatus,
	colorOptionsRefundReport,
	colorOptionsRefundReportType,
} from '../../../../core/models/cv';
import { adminRouter } from '../../../../core/routes';

interface ReportCvProps {}

const listStatus = [
	{
		label: 'Pending',
		value: EPointRefundReportStatus.PENDING,
		data: '',
	},
	{
		label: 'Approve',
		value: EPointRefundReportStatus.APPROVED,
		data: '',
	},
	{
		label: 'Reject',
		value: EPointRefundReportStatus.REJECTED,
		data: '',
	},
];

const ModalActionReportCv = ({ refectData }: { refectData: () => void }) => {
	const method = useModal<any>('action');
	const schema = yup.object().shape({});
	const formMethods = useForm<any>({
		defaultValues: {
			status: method.modalValue?.status || '',
			approverAnswer: method.modalValue?.approverAnswer || '',
		},
		resolver: yupResolver(schema),
	});
	const pointRefundReportMutiation = useMutation(
		(data: any) => ProfileFindNewApi.v1PostPointRefundReport(method.modalValue?.uuid, data),
		{
			onSuccess: (data) => {
				method.handleCloseModal();
				refectData();
				toast.success(data.message);
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const _handleSubmit = (data: any) => {
		pointRefundReportMutiation.mutate(data);
	};
	useEffect(() => {
		if (method.modalValue) {
			formMethods.setValue('status', method.modalValue?.status);
			formMethods.setValue('approverAnswer', method.modalValue?.approverAnswer);
		}
	}, [method.modalValue]);
	return (
		<Modal
			title={'Action Resume Report'}
			open={method.getIsOpen()}
			centered
			onOk={formMethods.handleSubmit(_handleSubmit)}
			onCancel={() => method.handleCloseModal()}
			width={600}
		>
			<FormProvider {...formMethods}>
				<form className='w-full'>
					<SelectInput className='w-full text-sm' name='status' label='Status' options={listStatus} />
					<TextareaInput className='w-full text-sm h-80' name='approverAnswer' label='Approver Answer' />
				</form>
			</FormProvider>
		</Modal>
	);
};

function ReportCv({}: ReportCvProps) {
	const method = useModal('action');
	const navigate = useNavigate();
	const { limit, page, setTotalItems } = useTableUtil();
	const [email, setEmail] = useState('');
	const [companyName, setCompanyName] = useState('');

	const pointRefundReportCvQuery = useQuery<IResponseList<any>>(
		['pointRefundReport', { limit, page, email, companyName }],
		async () => {
			const { data } = await ProfileFindNewApi.v1GetPointRefundReportPaging(
				{
					page: page,
					limit: limit,
				},
				companyName,
				email
			);
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);
			setTotalItems(totalItems);
			return data.data;
		}
	);
	const formMethods = useForm<any>({
		defaultValues: {
			companyName: '',
			email: '',
		},
	});
	const handleDetail = (item: any) => {
		navigate({
			pathname: adminRouter.cvManagementById.href(item),
		});
	};

	useDebounce([formMethods.watch('companyName'), formMethods.watch('email')], 500, () => {
		setCompanyName(formMethods.watch('companyName'));
		setEmail(formMethods.watch('email'));
	});
	useEffect(() => {
		document.title = 'Resume Report';
	}, []);
	return (
		<>
			<TableUtilProvider>
				<ModalActionReportCv
					refectData={() => {
						pointRefundReportCvQuery.refetch();
					}}
				/>
			</TableUtilProvider>
			<div className='fade-in'>
				<PageHeader title='Point refund reports' extra={[]} />
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>No.</div>,
								width: 50,
								dataIndex: 'No',
								key: 'No',
								render: (text, record, index) => {
									return <>{index + 1}</>;
								},
							},
							{
								title: () => <div>Company name</div>,
								width: 200,
								dataIndex: 'requester.company.name',
								key: 'requester.company.name',
								render: (text, record) => {
									const data = _get(record, 'requester.company.name', 'Empty');
									return <>{data}</>;
								},
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput name='companyName' label='Company name' placeholder='name...' className='w-52' />
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Email user</div>,
								width: 200,
								dataIndex: 'requester.email',
								key: 'requester.email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'requester.email', 'Empty');
									return <>{data}</>;
								},
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput name='email' label='Email' placeholder='email..' className='w-52' />
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Email CV</div>,
								width: 100,
								dataIndex: 'profile.email',
								key: 'profile.email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'profile.email', 'Empty');
									return (
										<>
											<div onClick={() => handleDetail(record.profile.uuid)} className='cursor-pointer text-sky-400'>
												{data}
											</div>
										</>
									);
								},
							},
							{
								title: () => <div>Points</div>,
								width: 100,
								dataIndex: 'point',
								key: 'point',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'point', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Approver Answer</div>,
								width: 100,
								dataIndex: 'approverAnswer',
								key: 'approverAnswer',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'approverAnswer', '-');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Reason</div>,
								width: 100,
								dataIndex: 'reason',
								key: 'reason',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'reason', '-');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Refund Type</div>,
								width: 100,
								dataIndex: 'type',
								key: 'type',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'type', 'Empty');
									return <StatusTag data={data} options={colorOptionsRefundReportType} />;
								},
							},
							{
								title: () => <div>Status</div>,
								width: 100,
								dataIndex: 'status',
								key: 'status',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'status', 'Empty');
									return <StatusTag data={data} options={colorOptionsRefundReport} />;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								dataIndex: 'action',
								key: 'action',
								align: 'center',
								render: (text, record) => {
									return (
										<>
											<Button onClick={() => method.handleOpenModal(record)}>
												<BarsOutlined />
											</Button>
										</>
									);
								},
							},
						]}
						data={pointRefundReportCvQuery.data?.data || []}
						isLoading={pointRefundReportCvQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
}
export default ReportCv;
