import { BarsOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Modal, PageHeader } from 'antd';
import _get from 'lodash/get';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1CvPermissionFilter } from '../../../core/apis';
import { ProfileFindNewApi } from '../../../core/apis/cvManagement.api';
import { SelectInput } from '../../../core/components/form';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { TableUtilProvider, useModal, useTableUtil } from '../../../core/contexts';
import { Comparator, EEmployerPointRequestStatus, IResponseList, colorOptionsPointStatus } from '../../../core/models';
import { filterMapper } from '../../../core/utils';

const listStatus = [
	{
		label: 'Approve',
		value: EEmployerPointRequestStatus.approved,
		data: '',
	},
	{
		label: 'Reject',
		value: EEmployerPointRequestStatus.denied,
		data: '',
	},
];

const ApprovedModal = ({}: {}) => {
	const method = useModal<any>('status');
	const schema = yup.object().shape({});
	const formMethods = useForm<any>({
		defaultValues: {
			status: method.modalValue?.status || '',
		},
		resolver: yupResolver(schema),
	});
	const jobTagMutiation = useMutation(
		(data: any) => ProfileFindNewApi.v1PutStatusPoint(method.modalValue?.uuid, data),
		{
			onSuccess: () => {
				method.handleCloseModal();
				toast.success('success');
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const _handleSubmit = (data: any) => {
		jobTagMutiation.mutate(data);
	};
	useEffect(() => {
		if (method.modalValue) {
			formMethods.setValue('status', method.modalValue?.status);
		}
	}, [method.modalValue]);
	return (
		<Modal
			title={'Approve Point'}
			open={method.getIsOpen()}
			centered
			onOk={formMethods.handleSubmit(_handleSubmit)}
			onCancel={() => method.handleCloseModal()}
			width={800}
		>
			<FormProvider {...formMethods}>
				<form className='w-full'>
					<SelectInput className='w-full text-sm' name='status' label='' options={listStatus} />
				</form>
			</FormProvider>
		</Modal>
	);
};

interface PointCvProps {}

export const PointCv: React.FC<PointCvProps> = () => {
	const method = useModal('status');
	const { limit, page, filters, setTotalItems, setFilters } = useTableUtil();
	const pointOfCvQuery = useQuery<IResponseList<any>>(['point', { limit, page, filters }], async () => {
		const { data } = await ProfileFindNewApi.v1GetPointJobPaging();
		const totalItems: number = _get(data, 'data.meta.totalItems', 0);
		setTotalItems(totalItems);
		return data.data;
	});
	React.useEffect(() => {
		setFilters(
			filterMapper<IV1CvPermissionFilter>(
				{
					role: '',
					name: '',
					module: '',
				},
				{
					role: Comparator.IN,
					name: Comparator.NONE,
					module: Comparator.EQUAL,
				},
				{
					name: 'search',
				}
			)
		);
	}, []);
	React.useEffect(() => {
		document.title = 'Request points';
	}, []);
	return (
		<>
			<TableUtilProvider>
				<ApprovedModal />
			</TableUtilProvider>
			<div className='fade-in'>
				<PageHeader title='Request points' extra={[]} />
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>No</div>,
								width: 50,
								dataIndex: 'No',
								key: 'No',
								render: (text, record, index) => {
									return <>{index + 1}</>;
								},
							},
							{
								title: () => <div>Email sale</div>,
								width: 200,
								dataIndex: 'sale.email',
								key: 'sale.email',
								render: (text, record) => {
									const data = _get(record, 'sale.email', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email employer</div>,
								width: 200,
								dataIndex: 'employer.email',
								key: 'employer.email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'employer.email', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Points request</div>,
								width: 100,
								dataIndex: 'point',
								key: 'point',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'point', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Season</div>,
								width: 100,
								dataIndex: 'reason',
								key: 'reason',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'reason', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>PO-NO</div>,
								width: 100,
								dataIndex: 'poNumber',
								key: 'poNumber',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'poNumber', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Status</div>,
								width: 100,
								dataIndex: 'status',
								key: 'status',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'status', 'Empty');
									return <StatusTag data={data} options={colorOptionsPointStatus} />;
								},
							},
							{
								title: () => <div>Active</div>,
								width: 100,
								dataIndex: 'active',
								key: 'active',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'status', 'Empty');
									return data === 'pending' ? (
										<Button onClick={() => method.handleOpenModal(record)}>
											<BarsOutlined />
										</Button>
									) : (
										<MinusCircleOutlined />
									);
								},
							},
						]}
						data={pointOfCvQuery.data?.data || []}
						isLoading={pointOfCvQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
