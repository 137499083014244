import { FilterOutlined, MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Popconfirm, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IV1CvPermissionFilter } from '../../../core/apis';
import { ProfileFindNewApi } from '../../../core/apis/cvManagement.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { useModal, useTableUtil } from '../../../core/contexts';
import { Comparator, IResponseList, colorOptionsUserRole } from '../../../core/models';
import { adminRouter } from '../../../core/routes';
import { filterMapper } from '../../../core/utils';
import { FilterPermisstionUser } from './components/FilterPermission';

interface PermissionCvProps {}

export const PermissionCv: React.FC<PermissionCvProps> = () => {
	const filterModalMethods = useModal('filter');
	const navigate = useNavigate();
	const { limit, page, filters, setTotalItems, setFilters } = useTableUtil();
	const userPermissionQuery = useQuery<IResponseList<any>>(['userPermission', { limit, page, filters }], async () => {
		const { data } = await ProfileFindNewApi.v1UserPermissionPaging({
			limit,
			page,
			...filters,
		});
		const totalItems: number = _get(data, 'data.meta.totalItems', 0);
		setTotalItems(totalItems);
		return data.data;
	});
	React.useEffect(() => {
		setFilters(
			filterMapper<IV1CvPermissionFilter>(
				{
					role: '',
					name: '',
					module: '',
				},
				{
					role: Comparator.IN,
					name: Comparator.NONE,
					module: Comparator.EQUAL,
				},
				{
					name: 'search',
				}
			)
		);
	}, []);
	const removeWhiteList = useMutation(
		(userUuid: string) => {
			return ProfileFindNewApi.v1DeleteUserPermission(userUuid);
		},
		{
			onSuccess: () => {
				toast.success('Remove User successfully');
				userPermissionQuery.refetch();
			},

			onError: () => {
				toast.error('Remove User failed');
			},
		}
	);
	React.useEffect(() => {
		document.title = 'Cv Permisson';
	}, []);
	return (
		<>
			<FilterPermisstionUser />
			<div className='fade-in'>
				<PageHeader
					title='Cv Permission'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
						<Button
							key='2'
							icon={<PlusCircleOutlined />}
							onClick={() => {
								navigate(adminRouter.AddPermissionUserCv.href());
							}}
						>
							Set Permission
						</Button>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Name</div>,
								width: 200,
								dataIndex: 'user.full_name',
								key: 'user.full_name',
								render: (text, record) => {
									const data = _get(record, 'user.full_name', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 200,
								dataIndex: 'user.email',
								key: 'user.email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'user.email', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Role</div>,
								width: 100,
								dataIndex: 'user.role',
								key: 'user.role',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'user.role', 'Empty');
									return <StatusTag data={data} options={colorOptionsUserRole} />;
								},
							},
							{
								title: () => <div>Module</div>,
								width: 100,
								dataIndex: 'module',
								key: 'module',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'module', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								dataIndex: 'action',
								key: 'action',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'action', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');
									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Remove</div>,
								width: 100,
								dataIndex: 'remove',
								key: 'remove',
								align: 'center',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Popconfirm
															title='Are you sure to remove this user?'
															onConfirm={() => {
																const userUuid = _get(record, 'uuid', '');
																removeWhiteList.mutate(userUuid);
															}}
														>
															<Menu.Item danger>Remove User</Menu.Item>
														</Popconfirm>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={userPermissionQuery.data?.data || []}
						isLoading={userPermissionQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
