import { MenuOutlined } from '@ant-design/icons';
import { Descriptions, Dropdown, Menu, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saleSystemEmployerApi, saleSystemGroupApi } from '../../../core/apis';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { useTableUtil } from '../../../core/contexts';
import { GroupMember, IResponseList, colorOptionsUserRole } from '../../../core/models';
import { Employer } from '../../../core/models/employer';
import { saleAdminRouter } from '../../../core/routes';
import { FilterGroupSaleDrawer } from '../groupSale/components/FilterGroupSaleDrawer';

interface AddSaleToEmployerProps {}

export const AddSaleToEmployer: React.FC<AddSaleToEmployerProps> = () => {
	const navigate = useNavigate();
	const { employerId = '', groupId = '' } = useParams<{ employerId: string; groupId: string }>();
	const employerQuery = useQuery<Employer>(['employer', employerId], async () => {
		const res = await saleSystemEmployerApi.v1GetById(employerId);

		return res.data;
	});

	const addSaleServiceMutation = useMutation(
		(saleUuid: string) => {
			return saleSystemEmployerApi.v1AssignToSale(employerId, { groupUuid: groupId, saleUuid });
		},
		{
			onSuccess: (data) => {
				toast.success(data.message);
				employerQuery.refetch();
				memberQuery.refetch();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);

	const { limit, page, setTotalItems, filters } = useTableUtil();
	const memberQuery = useQuery<IResponseList<GroupMember>>(
		[
			'member-group',
			groupId,
			{
				limit,
				page,
				filters,
			},
		],
		async () => {
			const { data } = await saleSystemGroupApi.v1MemberPagingByGroupId(groupId, {
				limit,
				page,
				...filters,
			});
			const totalItems: number = _get(data, 'meta.totalItems', 0);

			setTotalItems(totalItems);
			return data;
		}
	);
	React.useEffect(() => {
		document.title = 'Sale Admin - Add Employer Management';
	}, []);
	return (
		<>
			<FilterGroupSaleDrawer />
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(
							saleAdminRouter.employerById.href({
								saleEmployerGroupUuid: groupId,
							})
						);
					}}
					title='Add Sale Service'
					extra={[]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<Space direction='vertical' className='w-full'>
						<Descriptions bordered title='Current Sale Service'>
							<Descriptions.Item label='Sale Name' span={2}>
								{_get(employerQuery.data?.sale, 'full_name', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Sale Email' span={1}>
								{_get(employerQuery.data?.sale, 'email', 'No Data')}
							</Descriptions.Item>
						</Descriptions>
						<div className='flex justify-end'></div>
					</Space>
				</div>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Name</div>,
								width: 200,
								dataIndex: 'user.full_name',
								key: 'user.full_name',
								render: (text, record) => {
									const data = _get(record, 'user.full_name', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 200,
								dataIndex: 'user.email',
								key: 'user.email',
								render: (text, record) => {
									const data = _get(record, 'user.email', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Role</div>,
								width: 200,
								dataIndex: 'user.role',
								key: 'user.role',
								render: (text, record) => {
									const data = _get(record, 'user.role', 'Empty');

									return (
										<>
											<StatusTag data={data} options={colorOptionsUserRole} />
										</>
									);
								},
							},
							{
								title: () => <div>Action</div>,
								width: 50,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																addSaleServiceMutation.mutate(record.user.uuid);
															}}
														>
															Assign Service
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={memberQuery.data?.data || []}
						isLoading={memberQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
