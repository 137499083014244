import * as React from 'react';
import { Outlet } from 'react-router-dom';

interface AuthLayoutProps {}

export const AuthLayout: React.FC<AuthLayoutProps> = ({}) => {
	return (
		<div className='flex items-center justify-center min-h-screen bg-gray-800'>
			<Outlet />
		</div>
	);
};
