import { FilterOutlined, MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { saleSystemGroupApi } from '../../../core/apis/saleSystemGroup.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal, useTableUtil } from '../../../core/contexts';
import { Group, IResponseList } from '../../../core/models';
import { adminRouter } from '../../../core/routes';
import { CreateGroupSaleDrawer } from './components/CreateGroupSaleDrawer';
import { FilterGroupSaleDrawer } from './components/FilterGroupSaleDrawer';

interface GroupSalePageProps {}

export const GroupSalePage: React.FC<GroupSalePageProps> = () => {
	const createModalMethods = useModal('create');
	const filterModalMethods = useModal('filter');
	const navigate = useNavigate();
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const groupQuery = useQuery<IResponseList<Group>>(
		['group', { limit, page, filters }],
		async () => {
			const { data } = await saleSystemGroupApi.v1GroupPaging({
				limit,
				page,
				...filters,
			});

			const totalItems: number = _get(data, 'data.meta.totalItems', 0);

			setTotalItems(totalItems);
			return data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	React.useEffect(() => {
		if (!createModalMethods.getIsOpen()) {
			groupQuery.refetch();
		}
	}, [createModalMethods.getIsOpen()]);

	React.useEffect(() => {
		document.title = 'Group Sale';
	}, []);
	return (
		<>
			<FilterGroupSaleDrawer />
			<CreateGroupSaleDrawer />
			<div className='fade-in'>
				<PageHeader
					title='Group Management'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
						<Button
							key='2'
							icon={<PlusCircleOutlined />}
							onClick={() => {
								createModalMethods.handleOpenModal({});
							}}
						>
							Create Group
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Name</div>,
								width: 100,
								dataIndex: 'name',
								key: 'name',
								render: (text, record) => {
									const data = _get(record, 'name', 'No One');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Manager Email</div>,
								width: 200,
								dataIndex: 'owner.email',
								key: 'owner.email',
								render: (text, record) => {
									const data = _get(record, 'owner.email', 'Empty');

									return <>{data}</>;
								},
							},

							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																navigate(adminRouter.viewGroupDetailById.href(record.uuid));
															}}
														>
															View Detail
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={groupQuery.data?.data || []}
						isLoading={groupQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
