/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pie } from '@ant-design/plots';
import { Card } from 'antd';
import React from 'react';
import '../chart.css';
interface PieChartComponentsProps {
	title: string;
	data: any;
	valueField: string;
	typeField: string;
}

const PieChartComponents: React.FC<PieChartComponentsProps> = ({ data, valueField, typeField, title }) => {
	const config = {
		tooltip: {
			formatter: (datum: any) => {
				return { name: datum.label, value: datum.value + '%' };
			},
		},
		appendPadding: 10,
		data,
		angleField: valueField,
		colorField: typeField,
		radius: 0.8,
		label: {
			type: 'outer',
			content: '{name} {percentage}',
		},
		interactions: [
			{
				type: 'pie-legend-active',
			},
			{
				type: 'element-active',
			},
		],
	};
	let chart;

	// Export Image
	const downloadImage = () => {
		chart?.downloadImage();
	};

	// Get chart base64 string
	const toDataURL = () => {
	};

	return (
		<Card title={title} bordered={false} style={{ width: '100%' }}>
			<Pie {...config} className='chart' />
		</Card>
	);
};
export default PieChartComponents;
