import { Descriptions, PageHeader } from 'antd';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { salePointApi } from '../../../core/apis';
import { StatusTag } from '../../../core/components/tag';
import { colorOptionsPointStatus, PointRequest } from '../../../core/models';
import { saleRouter } from '../../../core/routes';

interface ViewPointRequestByIdProps {}

export const ViewPointRequestById: React.FC<ViewPointRequestByIdProps> = () => {
	const { pointRequestId = '' } = useParams<{ pointRequestId: string }>();
	const navigate = useNavigate();
	const groupDetailQuery = useQuery<PointRequest>(['point-request-detail', pointRequestId], async () => {
		const { data } = await salePointApi.v1GetById(pointRequestId);
		return data;
	});
	React.useEffect(() => {
		document.title = 'Sale - Point Request Detail';
	}, []);
	return (
		<>
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(saleRouter.pointRequestManagement.href());
					}}
					title='Point Request Detail'
					extra={[]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className=''>
						<Descriptions bordered title='Request Detail'>
							<Descriptions.Item label='Point'>{groupDetailQuery.data?.point}</Descriptions.Item>
							<Descriptions.Item label='Status'>
								<StatusTag data={groupDetailQuery.data?.status} options={colorOptionsPointStatus} />
							</Descriptions.Item>
							<Descriptions.Item label='Create At'>
								{moment(groupDetailQuery.data?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
							</Descriptions.Item>
							<Descriptions.Item label='Reason'>{groupDetailQuery.data?.reason}</Descriptions.Item>
						</Descriptions>
					</div>
					<div className=''>
						<Descriptions bordered title='Employer Detail'>
							<Descriptions.Item label='Name'>
								{groupDetailQuery.data?.employer.full_name}
							</Descriptions.Item>
							<Descriptions.Item label='Email'>{groupDetailQuery.data?.employer.email}</Descriptions.Item>
							<Descriptions.Item label='Phone'>{groupDetailQuery.data?.employer.phone}</Descriptions.Item>
						</Descriptions>
					</div>
				</div>
			</div>
		</>
	);
};
