import _get from 'lodash/get';
import { stringify } from 'query-string';
import { IPagingProps } from '../models';
import { http } from './http';

export interface IV1ProfileFindNewPagingFilters {
	status: string;
	type_job: string;
	level: any;
	city: any;
}
export interface IV1ProfileFindNewPaging extends IPagingProps<IV1ProfileFindNewPagingFilters> {}

export interface IV1UserPagingFilter {
	role: any;
	name: string;
}
export interface IV1UserPaging extends IPagingProps<IV1UserPagingFilter> {}
export interface IV1AddPermissionUser {
	userUuid: string;
	module: string;
	action: string;
}
export interface IV1ApiPaging {
	page: number;
	limit: number;
}

export const ProfileFindNewApi = {
	v1ProfileById: async (cvId: string) => {
		const res = await http.get(`/v1/api/profile/${cvId}`);
		return res.data;
	},
	v1ProfileFindNew: async (query: IV1ProfileFindNewPaging) => {
		const res = await http.get(
			`/v1/api/profile/find-new?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
	v1ProfilePaging: async (limit: any, page: any, filters?: any, search?: string) => {
		const res = await http.get('/v1/api/profile/paging', {
			params: {
				limit,
				page,
				search,
				...filters,
			},
		});
		return res;
	},
	v1UserPermissionPaging: async (query: IV1UserPaging) => {
		const res = await http.get(
			`/v1/api/user-permission/paging?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
	v1AddPermissionUser: (input: IV1AddPermissionUser) => {
		return http.post('/v1/api/user-permission', input);
	},
	v1DeleteUserPermission: async (userUuid: string) => {
		const res = await http.delete(`/v1/api/user-permission/${userUuid}`);
		return _get(res, 'data.data');
	},
	v1GetJobLevelPaging: async (search?: string): Promise<any> => {
		const url = '/v1/api/job-level/paging';
		return http.get(url, {
			params: {
				search: search,
			},
		});
	},
	v1GetSkillTermPaging(): Promise<any> {
		const url = '/v1/api/skill-term/paging';
		return http.get(url, {
			params: {
				page: 1,
				limit: 1000,
			},
		});
	},
	getAllCity: async () => {
		const url = '/v1/api/city';
		return http.get(url);
	},
	getAllCityAreas: async () => {
		const url = '/v1/api/city/job-areas';
		return http.get(url);
	},
	getAllDistrictByID: async (id: string) => {
		const url = `/v1/api/district/city/${id}`;
		return http.get(url);
	},
	getAllWardByID: async (id: string) => {
		const res = http.get(`/v1/api/ward/district/${id}`);
		return res;
	},
	industry: async (id: string) => {
		const url = `/v1/api/industry/lang/${id}`;
		const res = await http.get(url);
		return res.data;
	},
	v1GetIndustryPaging(search?: string): Promise<any> {
		const url = '/v1/api/industry/paging';
		return http.get(url, {
			params: {
				search: search,
			},
		});
	},
	v1GetPointJobPaging(search?: string): Promise<any> {
		const url = '/v1/api/sale/point-requests/paging';
		return http.get(url, {
			params: {
				search: search,
			},
		});
	},
	v1PutStatusPoint: async (id: string, body: any) => {
		const url = `/v1/api/sale/point-requests/${id}`;
		const res = await http.put(url, body);
		return res.data;
	},
	v1GetPointRefundReportPaging: async (params: IV1ApiPaging, companyName?: string, email?: string) => {
		const url = '/v1/api/point-refund-reports/paging';
		return http.get(url, {
			params: {
				...params,
				...(companyName !== '' ? { ['filter.requester.company.name']: `$ilike:${companyName}` } : null),
				...(email !== '' ? { ['filter.requester.email']: `$ilike:${email}` } : null),
			},
		});
	},
	v1PostPointRefundReport: async (
		id: string,
		body: {
			status: number;
			approverAnswer: string;
		}
	) => {
		const url = `/v1/api/point-refund-reports/${id}/sale-system`;
		const res = await http.post(url, body);
		return res.data;
	},
	v1PutTickAndNote: async (
		id: string,
		body: {
			saleNote: string;
			isSaleTicked: boolean;
		}
	) => {
		const url = `/v1/api/profile/${id}/sale`;
		const res = await http.put(url, body);
		return res.data;
	},
};
