import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1SaleSystemCreateGroup, saleSystemGroupApi } from '../../../../core/apis/saleSystemGroup.api';
import { TextInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';

const defaultValues: IV1SaleSystemCreateGroup = {
	name: '',
};

const schema = yup.object().shape({
	name: yup.string().required('Name is required'),
});

interface CreateGroupSaleDrawerProps {}

export const CreateGroupSaleDrawer: React.FC<CreateGroupSaleDrawerProps> = () => {
	const createModalMethod = useModal('create');
	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});
	const createGroupSaleMutation = useMutation(
		(data: IV1SaleSystemCreateGroup) => saleSystemGroupApi.v1CreateGroup(data),
		{
			onSuccess: () => {
				toast.success('Create group sale successfully');
				formMethods.reset({ ...defaultValues });
				createModalMethod.handleCloseModal();
			},
			onError: () => {
				toast.error('Create sale employer is failed');
			},
		}
	);

	const _handleOnSubmit = (data: IV1SaleSystemCreateGroup) => createGroupSaleMutation.mutate(data);

	return (
		<Drawer
			open={createModalMethod.getIsOpen()}
			title='Create Group Sale'
			onClose={() => createModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='name' label='Name' />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Create
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
