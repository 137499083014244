import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IV1WhiteListFilters } from '../../../../core/apis/whileList.api';
import { TextInput } from '../../../../core/components/form';
import { useModal, useTableUtil } from '../../../../core/contexts';
import { Comparator, User } from '../../../../core/models';
import { filterMapper } from '../../../../core/utils';

interface FilterWhiteListDrawerProps {}

export const FilterWhiteListDrawer: React.FC<FilterWhiteListDrawerProps> = () => {
	const filterModalMethod = useModal<User>('filter');
	const tableMethods = useTableUtil();
	const formMethods = useForm<IV1WhiteListFilters>({
		defaultValues: {
			search: '',
		},
	});

	const _handleOnSubmit = (data: IV1WhiteListFilters) => {
		tableMethods.setFilters(
			filterMapper<IV1WhiteListFilters>(data, {
				search: Comparator.NONE,
			})
		);
	};

	return (
		<Drawer
			open={filterModalMethod.getIsOpen()}
			title='Filter Employer'
			onClose={() => filterModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='search' label='Search' />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Apply
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
