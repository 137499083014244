import * as React from 'react';
import _get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

interface FormErrorMessageProps {
	name: string;
	className: string;
	label: string;
}

export const FormErrorMessage: React.FC<FormErrorMessageProps> = ({
	name,
	className,
}) => {
	const { formState } = useFormContext();
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	React.useEffect(() => {
		const error = _get(formState.errors, `${name}.message`, '') as string;
		setErrorMessage(error);
	}, [formState.errors]);

	return (
		<>
			{Boolean(errorMessage) && <div className={className}>{errorMessage}</div>}
		</>
	);
};
