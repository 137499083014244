export enum EMailTemplate {
	INTRODUCE_NEW_FEATURE = 1,
	REMIND_CREATE_ACCOUNT = 2,
	REMIND_WRITE_FORM = 3,
	REMIND_USE_POINT = 4,
	INTRODUCE_OLD_EMPLOYER = 5,
	INVITE_RECUIT = 6,
	PRICE_TABLE_EMPLOYER = 7,
	PRICE_TABLE_OLD_EMPLOYER = 8,
}
