import { EStatusJobFeedback } from './feedback';
import { IOptionItem } from './interface';

export enum EEmployerScale {
	MIN_0_TO_10 = 'MIN_0_TO_10',
	NORMAL_10_TO_100 = 'NORMAL_10_TO_100',
	LARGE_MORE_THAN_100 = 'LARGE_MORE_THAN_100',
}
export enum EEmployerType {
	SMALL = 'SMALL',
	LEAD = 'LEAD',
	BIG = 'BIG',
}
export enum EEmployerStatus {
	NEW = 'NEW',
	CALL_TO_SUPPORT = 'CALL_TO_SUPPORT',
	CREATED_DEAL = 'CREATED_DEAL',
	IN_SUPPORT = 'IN_SUPPORT',
}
export enum EEmployerGroupStatus {
	NEW = 'NEW',
	ACTIVATED = 'ACTIVATED',
}

export interface Employer {
	createdAt: string;
	updatedAt: string;
	uuid: string;
	scale: EEmployerScale;
	type: EEmployerType;
	other_note: string;
	callNote: string;
	status: EEmployerStatus;
	employer: {
		uuid: string;
		email: string;
		full_name: string;
		phone: string;
	};
	group: {
		uuid: string;
		name: string;
	};
	sale: {
		uuid: string;
		email: string;
		full_name: string;
		phone: string;
	};
}

export interface EmployerId {
	createdAt: string;
	updatedAt: string;
	uuid: string;
	scale: any;
	type: any;
	other_note: any;
	status: string;
	callNote: any;
	lead: {
		uuid: string;
		companyName: string;
		email: string;
		phone: string;
		hotline: any;
		source: any;
		createdAt: string;
		updatedAt: string;
	};
	employer: any;
	group: {
		uuid: string;
		name: string;
	};
	sale: any;
	saleEmployerGroupNotes: [];
}

export const optionsEmployerScale: IOptionItem[] = [
	{
		value: EEmployerScale.MIN_0_TO_10,
		label: 'Min (0-10)',
		data: EEmployerScale.MIN_0_TO_10,
	},
	{
		value: EEmployerScale.NORMAL_10_TO_100,
		label: 'Normal (10-100)',
		data: EEmployerScale.NORMAL_10_TO_100,
	},
	{
		value: EEmployerScale.LARGE_MORE_THAN_100,
		label: 'Large (>100)',
		data: EEmployerScale.LARGE_MORE_THAN_100,
	},
];

export const optionsEmployerType: IOptionItem[] = [
	{
		value: EEmployerType.SMALL,
		label: 'Small',
		data: EEmployerType.SMALL,
	},
	{
		value: EEmployerType.LEAD,
		label: 'Lead',
		data: EEmployerType.LEAD,
	},
	{
		value: EEmployerType.BIG,
		label: 'Big',
		data: EEmployerType.BIG,
	},
];

export const optionsEmployerStatus: IOptionItem[] = [
	{
		value: EEmployerStatus.NEW,
		label: 'New',
		data: EEmployerStatus.NEW,
	},
	{
		value: EEmployerStatus.CALL_TO_SUPPORT,
		label: 'Call to support',
		data: EEmployerStatus.CALL_TO_SUPPORT,
	},
	{
		value: EEmployerStatus.CREATED_DEAL,
		label: 'Created deal',
		data: EEmployerStatus.CREATED_DEAL,
	},
	{
		value: EEmployerStatus.IN_SUPPORT,
		label: 'In support',
		data: EEmployerStatus.IN_SUPPORT,
	},
];

export const colorsEmployerStatus: IOptionItem[] = [
	{
		value: 'default',
		label: 'New',
		data: EEmployerStatus.NEW,
	},
	{
		value: 'blue',
		label: 'Call to support',
		data: EEmployerStatus.CALL_TO_SUPPORT,
	},
	{
		value: 'green',
		label: 'Created deal',
		data: EEmployerStatus.CREATED_DEAL,
	},
	{
		value: 'yellow',
		label: 'In support',
		data: EEmployerStatus.IN_SUPPORT,
	},
];

export const colorsEmployerScale: IOptionItem[] = [
	{
		value: 'default',
		label: 'Min (0-10)',
		data: EEmployerScale.MIN_0_TO_10,
	},
	{
		value: 'blue',
		label: 'Normal (10-100)',
		data: EEmployerScale.NORMAL_10_TO_100,
	},
	{
		value: 'geekblue',
		label: 'Large (>100)',
		data: EEmployerScale.LARGE_MORE_THAN_100,
	},
];

export const colorsEmployerType: IOptionItem[] = [
	{
		value: 'default',
		label: 'Small',
		data: EEmployerType.SMALL,
	},
	{
		value: 'blue',
		label: 'Lead',
		data: EEmployerType.LEAD,
	},
	{
		value: 'gold',
		label: 'Big',
		data: EEmployerType.BIG,
	},
];

export const colorFeedBackStatus: IOptionItem[] = [
	{
		value: 'default',
		label: 'NOT SEEN',
		data: EStatusJobFeedback.NOT_SEEN,
	},
	{
		value: 'red',
		label: 'CANDICATE NOT FOUND',
		data: EStatusJobFeedback.CANDICATE_NOT_FOUND,
	},
	{
		value: 'blue',
		label: 'CANDICATE FOUND',
		data: EStatusJobFeedback.CANDICATE_FOUND,
	},
];
