import { IOptionItem } from './interface';

export enum ProfileStatus {
	INACTIVE = 'P_INACTIVE',
	ACTIVE = 'P_ACTIVE',
	REJECT = 'P_REJECT',
	PENDING = 'P_PENDING',
	IN_PROCESS = 'P_IN_PROCESS',
	JOB_SEEKING = 'P_JOB_SEEKING',
	NOT_JOB_SEEKING = 'P_NOT_JOB_SEEKING',
	WAITING_APPROVE = 'P_WAITING_APPROVE',
	BLOCK = 'P_BLOCK',
}
export enum CvProps {
	REMOTE = 'remote',
	COMPANY = 'onsite',
	FULLTIME = 'fulltime',
	PART_TIME = 'parttime',
}
export enum Level {
	INTERN = 'intern',
	FRESHER = 'fresher',
	JUNIOR = 'junior',
	MIDDLE = 'middle',
	SENIOR = 'senior',
	PROJECT_MANAGER = 'project_manager',
}

export const colorsCvStatus: IOptionItem[] = [
	{
		value: 'yellow',
		label: 'peding',
		data: ProfileStatus.PENDING,
	},
	{
		value: 'red',
		label: 'reject',
		data: ProfileStatus.REJECT,
	},
	{
		value: 'green',
		label: 'active',
		data: ProfileStatus.ACTIVE,
	},
];

export const optionsCvTypeJob: IOptionItem[] = [
	{
		value: CvProps.REMOTE,
		label: 'remote',
		data: CvProps.REMOTE,
	},
	{
		value: CvProps.COMPANY,
		label: 'onsite',
		data: CvProps.COMPANY,
	},
	{
		value: CvProps.FULLTIME,
		label: 'fulltime',
		data: CvProps.FULLTIME,
	},
	{
		value: CvProps.PART_TIME,
		label: 'parttime',
		data: CvProps.PART_TIME,
	},
];

export const optionsCvStatusJob: IOptionItem[] = [
	{
		value: ProfileStatus.INACTIVE,
		label: 'inActive',
		data: ProfileStatus.INACTIVE,
	},
	{
		value: ProfileStatus.ACTIVE,
		label: 'ACTIVE',
		data: ProfileStatus.ACTIVE,
	},
	{
		value: ProfileStatus.REJECT,
		label: 'REJECT',
		data: ProfileStatus.REJECT,
	},
	{
		value: ProfileStatus.PENDING,
		label: 'PENDING',
		data: ProfileStatus.PENDING,
	},
	{
		value: ProfileStatus.IN_PROCESS,
		label: 'IN_PROCESS',
		data: ProfileStatus.IN_PROCESS,
	},
	{
		value: ProfileStatus.JOB_SEEKING,
		label: 'JOB_SEEKING',
		data: ProfileStatus.JOB_SEEKING,
	},
	{
		value: ProfileStatus.NOT_JOB_SEEKING,
		label: 'NOT_JOB_SEEKING',
		data: ProfileStatus.NOT_JOB_SEEKING,
	},
	{
		value: ProfileStatus.WAITING_APPROVE,
		label: 'WAITING_APPROVE',
		data: ProfileStatus.WAITING_APPROVE,
	},
	{
		value: ProfileStatus.BLOCK,
		label: 'BLOCK',
		data: ProfileStatus.BLOCK,
	},
];

export interface ListProps {
	label: string;
	value: string;
}

export const listLevel: IOptionItem[] = [
	{
		label: 'Project Manager',
		value: Level.PROJECT_MANAGER,
		data: Level.PROJECT_MANAGER,
	},
	{
		label: 'Senior',
		value: Level.SENIOR,
		data: Level.SENIOR,
	},
	{
		label: 'Middle',
		value: Level.MIDDLE,
		data: Level.MIDDLE,
	},
	{
		label: 'Junior',
		value: Level.JUNIOR,
		data: Level.JUNIOR,
	},
	{
		label: 'Fresher',
		value: Level.FRESHER,
		data: Level.FRESHER,
	},
	{
		label: 'Intern',
		value: Level.INTERN,
		data: Level.INTERN,
	},
];

export enum EJobExamQuestionType {
	ONE_ANSWER = 1,
	MULTI_ANSWERS = 2,
	TEXT_ANSWER = 3,
}

export enum EJobExamResultStatus {
	NOT_DO = 1,
	NOT_SUBMIT = 2,
	SUBMITTED = 3,
}

export enum EJobTag {
	FOR_YOU = 'for-you',
	HOT_HERE = 'hot-here',
	URGENT = 'urgent',
	NONE = 'none',
}

export enum EWorkingTimeType {
	OFFICE_HOUR = 1,
	CUSTOM = 2,
}

export enum EDateOfWeek {
	MON = 'mon',
	TUE = 'tue',
	WED = 'wed',
	THU = 'thu',
	FRI = 'fri',
	SAT = 'sat',
	SUN = 'sun',
}

export enum EPointRefundReportType {
	WRONG_CONTACT = 1,
	EMPTY_CONTENT = 2,
	OTHER = 3,
}

export enum EPointRefundReportStatus {
	PENDING = 1,
	APPROVED = 2,
	REJECTED = 3,
}

export const colorOptionsRefundReport: IOptionItem[] = [
	{ value: 'blue', label: 'Pending', data: EPointRefundReportStatus.PENDING },
	{ value: 'green', label: 'Approve', data: EPointRefundReportStatus.APPROVED },
	{ value: 'red', label: 'Reject', data: EPointRefundReportStatus.REJECTED },
];

export const colorOptionsRefundReportType: IOptionItem[] = [
	{ value: 'red', label: 'Wrong contact', data: EPointRefundReportType.WRONG_CONTACT },
	{ value: 'blue', label: 'Empty content', data: EPointRefundReportType.EMPTY_CONTENT },
	{ value: 'green', label: 'Other', data: EPointRefundReportType.OTHER },
];

export enum ECandidateFeedbackStatus {
	PENDING = 1,
	MATCHED = 2,
	SCREENING = 3,
	REJECTED = 4,
}

export enum EJobCommentStatus {
	APPROVED = 1,
	REJECTED = 2,
	PENDING = 3,
}
