import { Button, Descriptions, PageHeader, Tabs } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { saleSystemGroupApi } from '../../../core/apis/saleSystemGroup.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { useModal, useTableUtil } from '../../../core/contexts';
import { colorOptionsUserRole, Group, GroupMember, IResponseList } from '../../../core/models';
import { colorsEmployerScale, colorsEmployerStatus, colorsEmployerType } from '../../../core/models/employer';
import { saleRouter } from '../../../core/routes';
import { FilterGroupSaleForEmployer } from './components/FilterGroupSaleForEmployer';
import { DislikeOutlined, FilterOutlined, LikeFilled } from '@ant-design/icons';

interface SaleViewGroupDetailByIdPageProps { }

export const SaleViewGroupDetailByIdPage: React.FC<SaleViewGroupDetailByIdPageProps> = () => {
	const navigate = useNavigate();
	const { groupId = '' } = useParams<{ groupId: string }>();
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const updateModalMethods = useModal('update');
	const groupDetailQuery = useQuery<Group>(['group-detail', groupId, updateModalMethods.getIsOpen()], async () => {
		const { data } = await saleSystemGroupApi.v1GroupById(groupId);
		return data;
	});
	const filterModalMethods = useModal('filterEmployerGroup');

	const memberQuery = useQuery<IResponseList<GroupMember>>(
		[
			'member-group',
			groupId,
			{
				limit,
				page,
				filters,
			},
		],
		async () => {
			const { data } = await saleSystemGroupApi.v1MemberPagingByGroupId(groupId, {
				limit,
				page,
				...filters,
			});
			const totalItems: number = _get(data, 'meta.totalItems', 0);

			setTotalItems(totalItems);
			return data;
		}
	);
	const employerQuery = useQuery<IResponseList<GroupMember>>(
		[
			'employer-group',
			groupId,
			{
				limit,
				page,
				filters,
			},
		],
		async () => {
			const { data } = await saleSystemGroupApi.v1EmployeePagingByGroupId(groupId, {
				limit,
				page,
				...filters,
			});
			const totalItems: number = _get(data, 'meta.totalItems', 0);

			setTotalItems(totalItems);
			return data;
		}
	);
	React.useEffect(() => {
		document.title = 'Sale - Group Management Detail';
	}, []);

	const handleMemberTableReload = () => {
		memberQuery.refetch();
	};

	const handleEmployerTableReload = () => {
		employerQuery.refetch();
	};
	return (
		<>
			<FilterGroupSaleForEmployer />

			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(saleRouter.groupSaleManagement.href());
					}}
					title='Group Detail'
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className='max-w-4xl'>
						<Descriptions bordered title='Basic Information'>
							<Descriptions.Item label='Group Name' span={2}>
								{_get(groupDetailQuery.data, 'name', '-')}
							</Descriptions.Item>
							<Descriptions.Item label='Manage By'>
								{_get(groupDetailQuery.data, 'owner.email', '-')}
							</Descriptions.Item>
							<Descriptions.Item label='Create At'>
								{moment(_get(groupDetailQuery.data, 'createdAt', '-')).format(
									'DD/MM/YYYY HH:mm:ss'
								)}
							</Descriptions.Item>
						</Descriptions>
					</div>
					<div>
						<Tabs defaultActiveKey='1'>
							<Tabs.TabPane tab='Member' key='1'>
								<Button type='primary' onClick={handleMemberTableReload} className='my-4'>Reload Member Table</Button>
								<TableBuilder
									columns={[
										{
											title: () => <div>Name</div>,
											width: 200,
											dataIndex: 'user.full_name',
											key: 'user.full_name',
											render: (text, record) => {
												const data = _get(record, 'user.full_name', '-');

												return <>{data}</>;
											},
										},
										{
											title: () => <div>Email</div>,
											width: 200,
											dataIndex: 'user.email',
											key: 'user.email',
											render: (text, record) => {
												const data = _get(record, 'user.email', '-');

												return <>{data}</>;
											},
										},
										{
											title: () => <div>Role</div>,
											width: 200,
											dataIndex: 'user.role',
											key: 'user.role',
											render: (text, record) => {
												const data = _get(record, 'user.role', '-');

												return (
													<>
														<StatusTag data={data} options={colorOptionsUserRole} />
													</>
												);
											},
										},
									]}
									data={memberQuery.data?.data || []}
									isLoading={memberQuery.isLoading}
									rowKey='uuid'
								/>
							</Tabs.TabPane>
							<Tabs.TabPane tab='Employer' key='2'>
								<PageHeader
									extra={[
										<Button
											key='1'
											icon={<FilterOutlined />}
											type='primary'
											onClick={() => {
												filterModalMethods.handleOpenModal({});
											}}
										>
											Filter
										</Button>,
									]}
								/>
								<Button type='primary' onClick={handleEmployerTableReload} className='mb-4'>Reload Employer Table</Button>
								<TableBuilder
									columns={[
										{
											title: () => <div>Company Name</div>,
											width: 300,
											dataIndex: 'employer.company.name',
											key: 'employer.company.name',
											render: (text, record) => {
												const dataEmp = _get(record, 'employer.company.name');
												const dataLead = _get(record, 'lead.companyName');

												return <>{dataEmp ?? dataLead}</>;
											},
										},
										{
											title: () => <div>Employer Email</div>,
											width: 200,
											dataIndex: 'employer.emai',
											key: 'employer.emai',
											render: (text, record) => {
												const data = _get(record, 'employer.email', '-');

												return <>{data}</>;
											},
										},
										{
											title: () => <div>Employer Email Lead</div>,
											width: 200,
											dataIndex: 'lead.emai',
											key: 'lead.emai',
											render: (text, record) => {
												const data = _get(record, 'lead.email', '-');

												return <>{data}</>;
											},
										},
										{
											title: () => <div>Employer Phone</div>,
											width: 100,
											dataIndex: 'employer.phone',
											key: 'employer.phone',
											render: (text, record) => {
												const dataEmp = _get(record, 'employer.phone');
												const dataLead = _get(record, 'lead.phone');

												return <>{dataEmp ?? dataLead}</>;
											},
										},
										{
											title: () => <div>Group Service</div>,
											width: 100,
											dataIndex: 'group.name',
											key: 'group.name',
											render: (text, record) => {
												const data = _get(record, 'group.name', '-');

												return <>{data}</>;
											},
										},
										{
											title: () => <div>Points</div>,
											width: 100,
											dataIndex: 'employer.point.point',
											key: 'employer.point.point',
											align: 'center',
											render: (text, record) => {
												const data = _get(record, 'employer.point.point', '-');

												return <>{data}</>;
											},
										},
										{
											title: () => <div>Points</div>,
											width: 100,
											dataIndex: 'group.point.point',
											key: 'group.point.point',
											align: 'center',
											render: (text, record) => {
												const data = _get(record, 'group.point.point', '-');

												return <>{data}</>;
											},
										},
										{
											title: () => <div>Scale</div>,
											width: 50,
											dataIndex: 'scale',
											key: 'scale',
											render: (text, record) => {
												const data = _get(record, 'scale', '');

												return (
													<>
														<StatusTag data={data} options={colorsEmployerScale} />
													</>
												);
											},
										},
										{
											title: () => <div>Status</div>,
											width: 50,
											dataIndex: 'status',
											key: 'status',
											render: (text, record) => {
												const data = _get(record, 'status', '');

												return (
													<>
														<StatusTag data={data} options={colorsEmployerStatus} />
													</>
												);
											},
										},
										{
											title: () => <div>Type</div>,
											width: 50,
											dataIndex: 'type',
											key: 'type',
											render: (text, record) => {
												const data = _get(record, 'type', '');

												return (
													<>
														<StatusTag data={data} options={colorsEmployerType} />
													</>
												);
											},
										},
										{
											title: () => <div>Create At</div>,
											width: 100,
											dataIndex: 'createdAt',
											key: 'createdAt',
											render: (text, record) => {
												const data = _get(record, 'createdAt', '-');

												return <>{moment(data).format('DD/MM/YYYY HH:MM:SS')}</>;
											},
										},
										{
											title: () => <div>Activity (Buy CV, Post job 60đ, Post job free)</div>,
											width: 200,
											dataIndex: 'hasActivity',
											key: 'hasActivity',
											align: 'center',
											render: (text, record) => {
												const data = _get(record, 'hasActivity', false);

												return <>{data ? <LikeFilled style={{ color: '#096dd9', fontSize: 25 }} /> : <DislikeOutlined style={{ color: '#a6a6a6', fontSize: 25 }} />}</>;
											},
										},
									]}
									data={employerQuery.data?.data || []}
									isLoading={employerQuery.isLoading}
									rowKey='uuid'
								/>
							</Tabs.TabPane>
						</Tabs>
					</div>
				</div>
			</div>
		</>
	);
};
