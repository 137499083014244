import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1SaleSystemCreateGroup, saleSystemGroupApi } from '../../../../core/apis/saleSystemGroup.api';
import { TextInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import { useParams } from 'react-router-dom';
import { Group } from '../../../../core/models';

const defaultValues: IV1SaleSystemCreateGroup = {
	name: '',
};

const schema = yup.object().shape({
	name: yup.string().required('Name is required'),
});

interface UpdateGroupSaleDrawerProps {}

export const UpdateGroupSaleDrawer: React.FC<UpdateGroupSaleDrawerProps> = () => {
	const updateModalMethod = useModal('update');
	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});
	const { groupId = '' } = useParams<{ groupId: string }>();
	const groupDetailQuery = useQuery<Group>(
		['group-detail', groupId],
		async () => {
			const { data } = await saleSystemGroupApi.v1GroupById(groupId);
			return data;
		},
		{
			onSuccess: (data) => {
				formMethods.setValue('name', data.name);
			},
		}
	);

	const createGroupSaleMutation = useMutation(
		(data: IV1SaleSystemCreateGroup) => saleSystemGroupApi.v1UpdateGroup(groupDetailQuery.data?.uuid || '', data),
		{
			onSuccess: () => {
				toast.success('Update group sale successfully');
				formMethods.reset({ ...defaultValues });
				updateModalMethod.handleCloseModal();
			},
			onError: () => {
				toast.error('Update sale employer is failed');
			},
		}
	);

	const _handleOnSubmit = (data: IV1SaleSystemCreateGroup) => createGroupSaleMutation.mutate(data);

	return (
		<Drawer
			open={updateModalMethod.getIsOpen()}
			title='Update Group Sale'
			onClose={() => updateModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='name' label='Name' />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Create
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
