import { IOptionItem } from './interface';

export enum EEmployerPointRequestStatus {
	pending = 'pending',
	approved = 'approved',
	denied = 'denied',
}

export interface PointRequest {
	createdAt: string;
	uuid: string;
	point: number;
	reason: string;
	status: EEmployerPointRequestStatus;
	employer: {
		uuid: string;
		email: string;
		full_name: string;
		phone: string;
	};
	sale: {
		uuid: string;
		email: string;

		full_name: string;
		phone: string;
		role: string;
	};
	group: {
		uuid: string;
		name: string;
	};
	purchaseOrders: [];
}

export const pointStatusOptions: IOptionItem[] = [
	{ value: EEmployerPointRequestStatus.pending, label: 'Pending', data: EEmployerPointRequestStatus.pending },
	{ value: EEmployerPointRequestStatus.approved, label: 'Approved', data: EEmployerPointRequestStatus.approved },
	{ value: EEmployerPointRequestStatus.denied, label: 'Denied', data: EEmployerPointRequestStatus.denied },
];

export const colorOptionsPointStatus: IOptionItem[] = [
	{ value: 'blue', label: 'Pending', data: EEmployerPointRequestStatus.pending },
	{ value: 'green', label: 'Approve', data: EEmployerPointRequestStatus.approved },
	{ value: 'red', label: 'Reject', data: EEmployerPointRequestStatus.denied },
];
