/* eslint-disable @typescript-eslint/no-unused-vars */
import { EditOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Descriptions, Modal, PageHeader } from 'antd';
import _get from 'lodash/get';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { dataSaleApi } from '../../../core/apis/dataSale';
import { IV1UpdateEmployerGroup, groupEmployerApi } from '../../../core/apis/groupEmployer';
import { InputRichEditor } from '../../../core/components/form/InputRichEditor';
import { useModal } from '../../../core/contexts';
import { adminRouter } from '../../../core/routes';
import AddOrUpdateDataModal from './components/AddOrUpdateDataModal';

interface DataSaleByIdProps {}

function NoteModal({ refetchData }: { refetchData: () => void }) {
	const noteModalMethods = useModal<any>('note');
	const schema = yup.object().shape({});
	const formMethods = useForm<any>({
		defaultValues: {
			note: '',
		},
		resolver: yupResolver(schema),
	});
	const addNoteMutation = useMutation(
		(data: any) => groupEmployerApi.v1NoteById(noteModalMethods.modalValue?.id, data),
		{
			onSuccess: () => {
				toast.success('success');
				refetchData();
				noteModalMethods.handleCloseModal();
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const editNoteMutation = useMutation(
		(data: any) =>
			groupEmployerApi.v1EditById(noteModalMethods.modalValue?.id, noteModalMethods.modalValue?.noteId, data),
		{
			onSuccess: () => {
				toast.success('success');
				refetchData();
				noteModalMethods.handleCloseModal();
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const employerGroupNoteByIdQuery = useQuery<any>(
		['employerGroupNoteById', noteModalMethods.modalValue?.id, noteModalMethods.modalValue?.noteId],
		async () => {
			const res = await groupEmployerApi.v1NoteInGroupById(
				noteModalMethods.modalValue?.id,
				noteModalMethods.modalValue?.noteId
			);

			return res.data.data;
		},
		{
			enabled: noteModalMethods.modalValue != undefined && noteModalMethods.modalValue?.type === 'edit',
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				const { note } = data;
				formMethods.setValue('note', note);
			},
		}
	);
	const _handleSubmit = (data: any) => {
		if (noteModalMethods.modalValue?.type === 'edit') {
			editNoteMutation.mutate(data);
		} else {
			addNoteMutation.mutate(data);
		}
	};
	return (
		<>
			<Modal
				title={'Add Note'}
				open={noteModalMethods.getIsOpen()}
				onOk={formMethods.handleSubmit(_handleSubmit)}
				centered
				onCancel={() => {
					noteModalMethods.handleCloseModal();
				}}
				width={800}
			>
				<FormProvider {...formMethods}>
					<div className='w-full'>
						{noteModalMethods.modalValue?.type === 'edit' ? (
							employerGroupNoteByIdQuery.data && <InputRichEditor name='note' label='Note' placeholder='note..' />
						) : (
							<InputRichEditor name='note' label='Note' placeholder='note..' />
						)}
					</div>
				</FormProvider>
			</Modal>
		</>
	);
}

function UpdateCallNoteModal({ refetchData }: { refetchData: () => void }) {
	const updateCallNoteMethod = useModal<any>('callNote');
	const formMethods = useForm<any>({
		defaultValues: {
			groupUuid: undefined,
			saleUuid: undefined,
			scale: undefined,
			type: undefined,
			other_note: undefined,
			status: undefined,
			callNote: '',
		},
	});
	const employerGroupServiceIdQuery = useQuery<any>(
		['employer-group-service-id', updateCallNoteMethod.modalValue],
		async () => {
			const res = await groupEmployerApi.v1ById(updateCallNoteMethod.modalValue);
			return res.data.data;
		},
		{
			enabled: updateCallNoteMethod.modalValue != undefined,
			onSuccess: (data) => {
				const { callNote } = data;
				formMethods.setValue('callNote', callNote);
			},
		}
	);
	const updateCallNoteMutation = useMutation(
		async (data: IV1UpdateEmployerGroup) => {
			const res = await groupEmployerApi.v1UpdateById(updateCallNoteMethod.modalValue, data);

			return res;
		},
		{
			onSuccess: (data: any) => {
				refetchData();
				toast.success(data.message);
				updateCallNoteMethod.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);
	const _handleSubmit = (data: any) => {
		updateCallNoteMutation.mutate(data);
	};
	return (
		<>
			{employerGroupServiceIdQuery.data && (
				<Modal
					title={'Edit Call Note'}
					open={updateCallNoteMethod.getIsOpen()}
					onOk={formMethods.handleSubmit(_handleSubmit)}
					centered
					onCancel={() => {
						updateCallNoteMethod.handleCloseModal();
					}}
					width={800}
				>
					<FormProvider {...formMethods}>
						<div className='w-full'>
							<InputRichEditor name='callNote' label='Call Note' placeholder='note..' className='' />
						</div>
					</FormProvider>
				</Modal>
			)}
		</>
	);
}

const DataSaleById: React.FC<DataSaleByIdProps> = () => {
	const addOrUpdateDataModal = useModal<any>('addOrUpdateData');
	
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();
	const { id = '' } = useParams<{ id: string }>();
	const dataSaleByIdQuery = useQuery<any>(
		['dataSaleById', id],
		async () => {
			const res = await dataSaleApi.v1ById(id);
			return res.data.data;
		},
		{
			enabled: Boolean(id),
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		document.title = `Data Sale | ${dataSaleByIdQuery.data?.companyName}`;
	}, []);

	return (
		<>
			{addOrUpdateDataModal.getIsOpen() && (
				<AddOrUpdateDataModal refetchData={() => dataSaleByIdQuery.refetch()} record={dataSaleByIdQuery.data || {}} />
			)}
			<div className='fade-in'>
				<PageHeader
					title={`${dataSaleByIdQuery.data?.companyName}`}
					onBack={() => {
						const params = {};

						searchParams.forEach((value, key) => {
							params[key] = value;
						});

						navigate({
							pathname: adminRouter.dataSaleManagement.href(),
							search: createSearchParams(params).toString(),
						});
					}}
					extra={[
						<Button
							key='1'
							type='default'
							icon={<EditOutlined />}
							onClick={() => {
								addOrUpdateDataModal.handleOpenModal({});
							}}
						>
							Update
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4 '>
					<Descriptions bordered className='bg-white'>
						<Descriptions.Item label='Company Name' span={12}>
							{_get(dataSaleByIdQuery.data, 'companyName', '-')}
						</Descriptions.Item>
						<Descriptions.Item label='Email' span={12}>
							{dataSaleByIdQuery.data ? dataSaleByIdQuery.data.email : ''}
						</Descriptions.Item>
						<Descriptions.Item label='Phone' span={2}>
							{_get(dataSaleByIdQuery.data, 'phone', '-')}
						</Descriptions.Item>
						<Descriptions.Item label='Other Phone' span={2}>
							{_get(dataSaleByIdQuery.data, 'otherPhone', '-')}
						</Descriptions.Item>
						<Descriptions.Item label='Source' span={2}>
							{_get(dataSaleByIdQuery.data, 'source', '-')}
						</Descriptions.Item>
						<Descriptions.Item label='Data Type' span={2}>
							{_get(dataSaleByIdQuery.data, 'dataType', '-')}
						</Descriptions.Item>
						<Descriptions.Item label='PIC Name' span={12}>
							{_get(dataSaleByIdQuery.data, 'picName', '-')}
						</Descriptions.Item>
						<Descriptions.Item label='Note' span={12}>
							{_get(dataSaleByIdQuery.data, 'note', '-')}
						</Descriptions.Item>
						<Descriptions.Item label='Note Mail' span={12}>
							{_get(dataSaleByIdQuery.data, 'mailingNote', '-')}
						</Descriptions.Item>
					</Descriptions>
				</div>
			</div>
		</>
	);
};

export default DataSaleById;
