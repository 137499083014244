import { ImportOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Descriptions, Drawer, Modal, PageHeader } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IV1MailerUpdate, sendMailManagemntApi } from '../../../core/apis/sendMail.api';
import { TextInput, TextareaInput } from '../../../core/components/form';
import InputFile from '../../../core/components/form/FileInput';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal, useTableUtil } from '../../../core/contexts';
import { EMailTemplate } from '../../../core/models/sendMail';
import { adminRouter } from '../../../core/routes';

interface MailManagementByIdProps {}

const DrawerMailerImport = ({}: {}) => {
	const methodMailerImport = useModal<any>('mailerImport');
	const { id = '' } = useParams<{ id: string }>();
	const defaultValues = {
		file: '',
	};
	const formMethods = useForm<any>({
		defaultValues,
	});
	const updateMailerMutation = useMutation(
		(object: { file: any; uuid: string }) => {
			return sendMailManagemntApi.v1MailerUpdate(object.uuid, {
				file: object.file,
				name: methodMailerImport.modalValue?.name || '',
				template: methodMailerImport.modalValue?.template || 0,
				description: methodMailerImport.modalValue?.description || '',
			});
		},
		{
			onSuccess: () => {
				toast.success('Update Mailer Success');
				methodMailerImport.handleCloseModal();
				updateMailerMutation.isLoading = false;
			},
			onError: (error: any) => {
				toast.error(error.response.message);
				updateMailerMutation.isLoading = false;
			},
		}
	);

	return (
		<Drawer title='Import Mail' open={methodMailerImport.getIsOpen()} onClose={methodMailerImport.handleCloseModal}>
			<FormProvider {...formMethods}>
				<form
					className='space-y-4'
					onSubmit={formMethods.handleSubmit((data) => {
						if (data.file !== '') {
							updateMailerMutation.mutate({ file: data.file, uuid: id });
						} else {
							toast.error('please, import a file xlxs!');
						}
					})}
				>
					<InputFile
						name='file'
						accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						placeholder='Choose CV to upload'
						className=''
					/>
					<p>(Note: just file .xlxs )</p>
					<Button type='primary' htmlType='submit' loading={updateMailerMutation.isLoading}>
						Import
					</Button>
				</form>
			</FormProvider>
		</Drawer>
	);
};

const ModalMailerUpdate = ({}: {}) => {
	const methodMailerUpdate = useModal<any>('mailerUpdate');
	const { id = '' } = useParams<{ id: string }>();
	const defaultValues: IV1MailerUpdate = {
		name: '',
		template: 0,
		description: '',
		file: '',
	};
	const formMethods = useForm<IV1MailerUpdate>({
		defaultValues,
	});
	const updateMailerMutation = useMutation(
		(object: { data: IV1MailerUpdate; uuid: string }) => {
			return sendMailManagemntApi.v1MailerUpdate(object.uuid, object.data);
		},
		{
			onSuccess: () => {
				toast.success('Update Mailer Success');
				methodMailerUpdate.handleCloseModal();
			},
			onError: () => {
				toast.error('Update Mailer Failed, Please Try Again Later');
			},
		}
	);
	useEffect(() => {
		if (methodMailerUpdate.modalValue) {
			formMethods.setValue('name', methodMailerUpdate.modalValue?.name);
			formMethods.setValue('template', methodMailerUpdate.modalValue?.template);
			formMethods.setValue('description', methodMailerUpdate.modalValue?.description);
		}
	}, [methodMailerUpdate.modalValue]);
	return (
		<Modal
			title={'Mailer Update'}
			open={methodMailerUpdate.getIsOpen()}
			footer={null}
			centered
			onCancel={() => methodMailerUpdate.handleCloseModal()}
			width={800}
		>
			<FormProvider {...formMethods}>
				<form
					className='space-y-4'
					onSubmit={formMethods.handleSubmit((data) => {
						updateMailerMutation.mutate({ uuid: id, data: data });
					})}
				>
					<TextInput name='name' label='Name' />
					<TextareaInput name='description' label='Description' />
					<Button type='primary' htmlType='submit' loading={updateMailerMutation.isLoading}>
						Update
					</Button>
				</form>
			</FormProvider>
		</Modal>
	);
};

export const MailManagementById = ({}: MailManagementByIdProps) => {
	const navigate = useNavigate();
	const { id = '' } = useParams<{ id: string }>();
	const methodMailerUpdate = useModal('mailerUpdate');
	const methodMailerImport = useModal('mailerImport');
	const [email, setEmail] = useState('');
	let [searchParams] = useSearchParams();
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const formMethods = useForm<any>({
		defaultValues: {
			search: '',
		},
	});
	const mailerCampaignsByIdQuery = useQuery(
		['mailerCampaignsById', { id }],
		async () => {
			const { data } = await sendMailManagemntApi.v1MailerByID(id);
			return data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const mailerCampaignsByIdPagingQuery = useQuery(
		['mailerCampaigns', { limit, page, filters, id, email }],
		async () => {
			const { data } = await sendMailManagemntApi.v1MailerForAllMailPaging(id, {
				limit,
				page,
				email,
				...filters,
			});
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);
			setTotalItems(totalItems);
			return data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	useEffect(() => {
		setEmail(formMethods.watch('search'));
	}, [formMethods.watch('search')]);
	useEffect(() => {
		document.title = `Send Mail Management - ${mailerCampaignsByIdQuery.data?.name}`;
	}, []);
	const renderTemplate = (data: number) => {
		switch (data) {
			case EMailTemplate.INTRODUCE_NEW_FEATURE:
				return 'INTRODUCE NEW FEATURE';
			case EMailTemplate.INVITE_RECUIT:
				return 'INVITE RECUIT';
			case EMailTemplate.INTRODUCE_OLD_EMPLOYER:
				return 'INTRODUCE OLD EMPLOYER';
			case EMailTemplate.REMIND_CREATE_ACCOUNT:
				return 'REMIND CREATE ACCOUNT';
			case EMailTemplate.REMIND_USE_POINT:
				return 'REMIND USE POINT';
			case EMailTemplate.REMIND_WRITE_FORM:
				return 'REMIND WRITE FORM';
			case EMailTemplate.PRICE_TABLE_EMPLOYER:
				return 'PRICE TABLE EMPLOYER';
			case EMailTemplate.PRICE_TABLE_OLD_EMPLOYER:
				return 'PRICE TABLE OLD EMPLOYER';
			default:
				return '';
		}
	};
	return (
		<>
			<ModalMailerUpdate />
			<DrawerMailerImport />
			<div className='fade-in mb-4'>
				<PageHeader
					onBack={() => {
						const params = {};
						searchParams.forEach((value, key) => {
							params[key] = value;
						});
						navigate({
							pathname: adminRouter.sendMailManagement.href(),
							search: `?${createSearchParams(params)}`,
						});
					}}
					title={`Mailer Detail - ${mailerCampaignsByIdQuery.data?.name}`}
					extra={[
						<Button
							onClick={() => {
								methodMailerUpdate.handleOpenModal(mailerCampaignsByIdQuery.data);
							}}
						>
							Update mail
						</Button>,
						mailerCampaignsByIdQuery.data?.isSent ? null : (
							<Button
								onClick={() => {
									methodMailerImport.handleOpenModal(mailerCampaignsByIdQuery.data);
								}}
								type='primary'
							>
								<ImportOutlined /> Import mail
							</Button>
						),
					]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className='max-w'>
						<Descriptions bordered title={''} column={3} layout='vertical'>
							<Descriptions.Item label='Name'>{mailerCampaignsByIdQuery.data?.name}</Descriptions.Item>
							<Descriptions.Item label='Template'>
								{renderTemplate(mailerCampaignsByIdQuery.data?.template)}
							</Descriptions.Item>
							<Descriptions.Item label='Created At'>
								{moment(mailerCampaignsByIdQuery.data?.createdAt).format('DD/MM/YYYY')}
							</Descriptions.Item>
							<Descriptions.Item label='Description'>{mailerCampaignsByIdQuery.data?.description}</Descriptions.Item>
						</Descriptions>
					</div>
				</div>

				<div className='mt-4 p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>No.</div>,
								width: 50,
								dataIndex: 'No',
								key: 'No',
								render: (text, record, index) => {
									return <>{index + 1}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 100,
								dataIndex: 'email',
								key: 'email',
								render: (text, record) => {
									const data = _get(record, 'email', 'Empty');

									return <>{data}</>;
								},
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput name='search' label='Search' placeholder='search..' className='w-52' />
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Accepted At</div>,
								width: 200,
								dataIndex: 'acceptedAt',
								key: 'acceptedAt',
								render: (text, record) => {
									const data = _get(record, 'acceptedAt', 'Empty');

									return <>{data ? moment(data).format('DD/MM/YYYY - hh:mm A') : '-'}</>;
								},
							},
							{
								title: () => <div>Clicked At</div>,
								width: 200,
								dataIndex: 'clickedAt',
								key: 'clickedAt',
								render: (text, record) => {
									const data = _get(record, 'clickedAt', 'Empty');

									return <>{data ? moment(data).format('DD/MM/YYYY - hh:mm A') : '-'}</>;
								},
							},
							{
								title: () => <div>Delivered At</div>,
								width: 200,
								dataIndex: 'deliveredAt',
								key: 'deliveredAt',
								render: (text, record) => {
									const data = _get(record, 'deliveredAt', 'Empty');

									return <>{data ? moment(data).format('DD/MM/YYYY - hh:mm A') : '-'}</>;
								},
							},
							{
								title: () => <div>Failed At</div>,
								width: 200,
								dataIndex: 'failedAt',
								key: 'failedAt',
								render: (text, record) => {
									const data = _get(record, 'failedAt', 'Empty');

									return <>{data ? moment(data).format('DD/MM/YYYY - hh:mm A') : '-'}</>;
								},
							},
							{
								title: () => <div>Opened At</div>,
								width: 200,
								dataIndex: 'openedAt',
								key: 'openedAt',
								render: (text, record) => {
									const data = _get(record, 'openedAt', 'Empty');

									return <>{data ? moment(data).format('DD/MM/YYYY - hh:mm A') : '-'}</>;
								},
							},
							{
								title: () => <div>Sent At</div>,
								width: 200,
								dataIndex: 'sentAt',
								key: 'sentAt',
								render: (text, record) => {
									const data = _get(record, 'sentAt', 'Empty');

									return <>{data ? moment(data).format('DD/MM/YYYY - hh:mm A') : '-'}</>;
								},
							},
						]}
						data={mailerCampaignsByIdPagingQuery.data ? mailerCampaignsByIdPagingQuery.data.data : []}
						isLoading={mailerCampaignsByIdPagingQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
