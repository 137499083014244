import { Descriptions, PageHeader } from 'antd';
import _get from 'lodash/get';
import * as React from 'react';
import { useQuery } from 'react-query';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { purchaseOrderApi } from '../../../core/apis/purchaseOrder.api';
import { Employer } from '../../../core/models/employer';
import { adminRouter } from '../../../core/routes';

interface ViewInvoiceProps { }

export const ViewInvoice: React.FC<ViewInvoiceProps> = () => {
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();
	const { purchaseId = '' } = useParams<{ purchaseId: string }>();
	const invoiceQuery = useQuery<Employer>(['purchase', purchaseId], async () => {
		const res = await purchaseOrderApi.v1GetInvoiceById(purchaseId);

		return res.data;
	});
	React.useEffect(() => {
		document.title = 'Invoice';
	}, []);

	return (
		<>
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						const params = {}

						searchParams.forEach((value, key) => {
							params[key] = value;
						});

						navigate({
							pathname: adminRouter.purchaseOrderManagement.href(),
							search: createSearchParams(params).toString()
						});
					}}
					title='Invoice'
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className=''>
						<Descriptions bordered title='Invoice Information'>
							<Descriptions.Item label='Name' span={1}>
								{_get(invoiceQuery.data, 'fullName', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Phone' span={2}>
								{_get(invoiceQuery.data, 'phone', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Company name' span={1}>
								{_get(invoiceQuery.data, 'companyName', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Company address' span={2}>
								{_get(invoiceQuery.data, 'companyAddress', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Email' span={3}>
								{_get(invoiceQuery.data, 'email', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Country' span={3}>
								{_get(invoiceQuery.data, 'country.name', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='City' span={1}>
								{_get(invoiceQuery.data, 'city.name', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='District' span={2}>
								{_get(invoiceQuery.data, 'district.name', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Tax Code' span={2}>
								{_get(invoiceQuery.data, 'taxCode', 'No Data')}
							</Descriptions.Item>
						</Descriptions>
					</div>
				</div>
			</div>
		</>
	);
};
