import { Tag } from 'antd';
import React, { useEffect } from 'react';
import { IOptionItem } from '../../models';

interface StatusTagProps {
	data: any;
	options: IOptionItem[];
}

export const StatusTag: React.FC<StatusTagProps> = ({ data, options }) => {
	const [option, setOption] = React.useState<IOptionItem>({
		label: 'No Data',
		value: 'red',
		data: '',
	});

	useEffect(() => {
		const _option = options.find((item) => item.data === data);

		if (_option) {
			setOption(_option);
		}
	}, [data, options]);

	return <Tag color={option.value}>{option.label}</Tag>;
};
