import {
	CloudUploadOutlined,
	CustomerServiceOutlined,
	FileDoneOutlined,
	GroupOutlined,
	HomeFilled,
	LogoutOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	MoneyCollectOutlined,
	OrderedListOutlined,
	ProfileOutlined,
} from '@ant-design/icons';
import { faBuilding, faComments, faFlag } from '@fortawesome/free-regular-svg-icons';
import { faCartShopping, faEnvelopesBulk, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Layout, Menu } from 'antd';
import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Cookie from 'universal-cookie';
import { CONSTANTS } from '../../constants';
import { useAuthUser } from '../../contexts';
import { saleRouter } from '../../routes';

const { Sider, Content } = Layout;
// --- components

const menuList = [
	{
		icon: <HomeFilled />,
		label: 'Home',
		key: saleRouter.home.href(),
	},
	{
		icon: <CloudUploadOutlined />,
		label: 'Data Sale Management',
		key: saleRouter.dataSaleManagement.href(),
	},
	{
		icon: <GroupOutlined />,
		label: 'Group Sale',
		key: saleRouter.groupSaleManagement.href(),
	},
	{
		icon: <FontAwesomeIcon icon={faEnvelopesBulk} />,
		label: 'Mail Campaigns',
		key: saleRouter.sendMailManagement.href(),
	},
	{
		label: 'Point Request',
		key: saleRouter.pointRequestManagement.href(),
		icon: <MoneyCollectOutlined />,
	},
	{
		icon: <CustomerServiceOutlined />,
		label: 'Employer',
		key: saleRouter.employerSaleManagement.href(),
	},
	{
		icon: <LogoutOutlined />,
		label: 'Logout',
		key: 'logout',
	},
];

interface SaleLayoutProps {}

export const SaleLayout: React.FC<SaleLayoutProps> = ({}) => {
	const [collapsed, setCollapsed] = React.useState(false);
	const location = useLocation();
	const navigator = useNavigate();
	const userContext = useAuthUser();
	const [canSeeCv, setCanSeeCv] = React.useState<any>();
	const { currentUser } = useAuthUser();
	const _handleOnLogout = () => {
		const cookie = new Cookie();
		cookie.set(CONSTANTS.ACCESS_TOKEN_KEY, '', { maxAge: -999 });
		userContext.handleLogout();
	};
	React.useEffect(() => {
		const canViewCVs = localStorage.getItem('canViewCVs');
		if (canViewCVs) {
			setCanSeeCv(Boolean(canViewCVs));
		}
	}, []);
	const menuListOther = [
		{
			icon: <HomeFilled />,
			label: 'Home',
			key: saleRouter.home.href(),
		},
		{
			icon: <CloudUploadOutlined />,
			label: 'Data Sale Management',
			key: saleRouter.dataSaleManagement.href(),
		},
		{
			icon: <GroupOutlined />,
			label: 'Group Sale',
			key: saleRouter.groupSaleManagement.href(),
		},
		{
			label: 'Point Request',
			key: saleRouter.pointRequestManagement.href(),
			icon: <MoneyCollectOutlined />,
		},
		{
			icon: <CustomerServiceOutlined />,
			label: 'Employer',
			key: saleRouter.employerSaleManagement.href(),
		},
		{
			icon: <FontAwesomeIcon icon={faEnvelopesBulk} />,
			label: 'Mail Campaigns',
			key: saleRouter.sendMailManagement.href(),
		},
		{
			icon: <ProfileOutlined />,
			label: 'Cv Management',
			key: 'cv-management',
			children: [
				currentUser?.canViewCVs
					? {
							icon: <OrderedListOutlined />,
							label: 'List Cv',
							key: saleRouter.cvManagement.href(),
					  }
					: null,
				currentUser?.canViewJobList
					? {
							icon: <FileDoneOutlined />,
							label: 'Job list',
							key: saleRouter.CvApplied.href(),
					  }
					: null,
				{
					icon: <FontAwesomeIcon icon={faFlag} />,
					label: 'Resume Report',
					key: saleRouter.reportCv.href(),
				},
			],
		},
		currentUser?.canEditCompany
			? {
					icon: <FontAwesomeIcon icon={faBuilding as any} />,
					label: 'Company',
					key: saleRouter.companyPage.href(),
			  }
			: null,
		{
			icon: <FontAwesomeIcon icon={faMoneyBill as any} />,
			label: 'Purchase Order',
			key: saleRouter.purchaseOrderManagement.href(),
		},
		{
			icon: <FontAwesomeIcon icon={faCartShopping as any} />,
			label: 'Sale Order',
			key: saleRouter.saleOrderManagement.href(),
		},
		{
			icon: <FontAwesomeIcon icon={faComments as any} />,
			label: 'Feed Back',
			key: saleRouter.feedBackPage.href(),
		},
		{
			icon: <LogoutOutlined />,
			label: 'Logout',
			key: 'logout',
		},
	];

	return (
		<>
			<div className='root'>
				<Layout>
					<Sider width={250} theme='light' trigger={null} collapsible collapsed={collapsed} className='flex flex-col'>
						<div className='flex-1 w-full min-h-screen bg-white shadow-lg'>
							<div className='flex items-center justify-center w-full h-16 px-2 py-4 bg-gray-900 logo'>
								{collapsed ? (
									<div className='flex items-center justify-center text-white'>SD</div>
								) : (
									<div className='flex items-center justify-center text-white'>Sale Dashboard</div>
								)}
							</div>
							<Menu
								mode='inline'
								onClick={({ key }) => {
									if (key === 'logout') {
										_handleOnLogout();
										return;
									}
									navigator(key);
								}}
								items={canSeeCv ? (menuListOther as any) : (menuList as any)}
								selectedKeys={[location.pathname]}
							/>
						</div>
					</Sider>
					<Layout>
						<div className='flex items-center justify-between h-16 p-2 space-x-8 bg-white'>
							<Button
								onClick={() => setCollapsed(!collapsed)}
								type='primary'
								icon={collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
								size='middle'
							/>
							<div className='flex-1'></div>

							<div></div>
						</div>
						<Content>
							<div className='min-h-screen'>
								<Outlet />
							</div>
						</Content>
					</Layout>
				</Layout>
			</div>
		</>
	);
};
