import _get from 'lodash/get';
import { stringify } from 'query-string';
import { IPagingProps, User } from '../models';
import { http } from './http';

export interface IV1LoginDTO extends Pick<User, 'email' | 'password'> {}
export interface IV1UpdateMeDTO extends Pick<User, 'password' | 'phone' | 'gender' | 'full_name'> {}
export interface IV1UserPagingFilter {
	role: any;
	name: string;
}
export interface IV1CvPermissionFilter {
	role: any;
	name: string;
	module: string;
}
export interface IV1UserPaging extends IPagingProps<IV1UserPagingFilter> {}

export const userApi = {
	v1Paging: async (query: IV1UserPaging) => {
		return http.get(
			`/v1/api/user/paging?${stringify({
				...query,
				...query.filters,
			})}`
		);
	},
	v1PagingWhitelist: async (query: IV1UserPaging) => {
		return http.get(
			`/v1/api/user/admin/paging-white-list?${stringify({
				...query,
				...query.filters,
			})}`
		);
	},
	v1Login: (input: { email: string; password: string; recaptchaValue: string }) => {
		return http.post('/v2/api/auth/login', input);
	},
	v1About: async (): Promise<User> => {
		const res = await http.get('/v1/api/user/about');
		return _get(res, 'data.data');
	},
	v1UpdateUser: async (input: IV1UpdateMeDTO) => {
		const res = await http.put('/v1/api/user/update', input);
		return _get(res, 'data.data');
	},
	v1GetById: async (id: string) => {
		const res = await http.get(`/v1/api/user/about/${id}`);
		return _get(res, 'data.data');
	},
};
