import { stringify } from 'query-string';
import { IPagingProps } from '../models';
import { http } from './http';

export interface IV1DataSalePagingFilters {}
export interface IV1DataSalePaging extends IPagingProps<IV1DataSalePagingFilters> {}

export interface IV1GroupEmployerMeFilters {}
export interface IV1GroupEmployerMe {
	id: string;
}
export interface IV1UpdateEmployerGroup {
	groupUuid?: string;
	saleUuid?: string;
	scale: string;
	type: string;
	other_note: string;
	status: string;
}

export const dataSaleApi = {
	v1Paging: async (query: IV1DataSalePaging) => {
		const res = await http.get(
			`/v1/api/potential-candidates/paging?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
	v1AddData: async (body: {
		companyName: string;
		email: string;
		phone: string;
		otherPhone: string;
		source: string;
		dataType: string;
		picName: string;
		note: string;
		mailingNote: string;
	}) => {
		const res = await http.post('/v1/api/potential-candidates', body);
		return res.data;
	},
	v1ById: async (id: string) => {
		const res = await http.get(`/v1/api/potential-candidates/${id}`);
		return res;
	},
	v1Import: async (file: any) => {
		const res = await http.post('/v1/api/potential-candidates/import', file, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return res;
	},
	v1EditById: async (id: string,  body: {
		companyName: string;
		email: string;
		phone: string;
		otherPhone: string;
		source: string;
		dataType: string;
		picName: string;
		note: string;
		mailingNote: string;
	}) => {
		const res = await http.put(`/v1/api/potential-candidates/${id}`, body);
		return res;
	},
	v1DeleteById: async (id: string) => {
		const res = await http.delete(`/v1/api/potential-candidates/${id}`);
		return res;
	},
};
