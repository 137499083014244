import { configs } from '../../../../../core/config';

const AnswerCandidateItem = ({
	answerItem,
	statusCommentText,
	statusCommentClass
}: {
	answerItem: any,
	statusCommentText: any,
	statusCommentClass: any
}) => {

	return (
		<>
			<div className='flex ml-[50px] mt-[10px]'>
				<img
					src={`${configs.REACT_APP_IMAGE_URL}${answerItem?.user?.avatar || ''}`}
					alt='avatar'
					className='object-cover rounded w-[40px] h-[40px]'
				/>
				<div className='ml-[16px]'>
					<div className='flex items-center mb-1'>
						<h3 className='text-[#787878] text-[14px] mb-0'>{answerItem?.user?.full_name}</h3>
						{answerItem?.status && <div className={`w-fit ml-[10px] rounded px-[8px] py-[2px] ${statusCommentClass[answerItem?.status]}`}>
							{statusCommentText[answerItem?.status]}
						</div>}
					</div>
					<p className='text-[16px]'>{answerItem?.comment}</p>
				</div>
			</div>
		</>
	);
};
export default AnswerCandidateItem;
