import { stringify } from 'query-string';
import { IPagingProps } from '../models';
import { http } from './http';

export interface IV1ActivityByEmployerIdFilters {}
export interface IV1ActivityByEmployerId extends IPagingProps<IV1ActivityByEmployerIdFilters> {}

export const activityApi = {
	v1Paging: async (employerId: string, query: IV1ActivityByEmployerId) => {
		const res = await http.get(
			`/v1/api/history-activity/paging/${employerId}?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
	v1GetById: async (activityId: string) => {
		const res = await http.get(`/v1/api/history-activity/${activityId}`);
		return res;
	},
};
