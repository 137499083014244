/* eslint-disable @typescript-eslint/no-unused-vars */
export enum SortOrder {
	ASC = 'asc',
	DESC = 'desc',
}

export enum Comparator {
	EQUAL = '$eq:',
	GREATER_THAN = '$gt:',
	GREATER_THAN_OR_EQUAL = '$gte:',
	LESS_THAN = '$lt:',
	LESS_THAN_OR_EQUAL = '$lte:',
	LIKE = '$ilike:',
	NONE = '',
	IN = '$in:',
}

//this T type will be used in the future please ignore it for now
export interface IPagingProps<T> {
	limit: number;
	page: number;

	[key: string]: any;
}

export interface IResponseList<T> {
	data: T[];
	meta: {
		page: number;
		limit: number;
		pageTotal: number;
		totalItems: number;
	};
}

export interface ListResponse {
	data: any;
	status: string;
	message: string;
}

export interface IOptionItem {
	data?: any;
	value: any;
	label: any;
}

export interface IBadgeItem {
	color: Color;
	value: any;
	label: string;
}

export enum Color {
	PRIMARY = 'badge-light-primary',
	SECONDARY = 'badge-light-secondary',
	SUCCESS = 'badge-light-success',
	DANGER = 'badge-light-danger',
	WARNING = 'badge-light-warning',
	INFO = 'badge-light-info',
	DARK = 'badge-light-dark',
}
