import { FilterOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Popconfirm, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saleSystemEmployerApi } from '../../../core/apis';
import { groupEmployerApi } from '../../../core/apis/groupEmployer';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { useModal, useTableUtil } from '../../../core/contexts';
import { Comparator, IResponseList } from '../../../core/models';
import { Employer, colorsEmployerScale, colorsEmployerStatus, colorsEmployerType } from '../../../core/models/employer';
import { saleAdminRouter } from '../../../core/routes';
import { FilterEmployerDrawer } from './components/FilterSaleEmployerDrawer';

interface SaleAdminEmployerSaleManagementByGroupIdProps {}

export const SaleAdminEmployerSaleManagementByGroupId: React.FC<SaleAdminEmployerSaleManagementByGroupIdProps> = () => {
	const filterModalMethods = useModal('filter');
	const navigate = useNavigate();
	const { groupId = '' } = useParams<{ groupId: string }>();
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const employerQuery = useQuery<IResponseList<Employer>>(
		[
			'employer',

			{
				limit,
				page,
				filters,
				groupId,
			},
		],
		async () => {
			const { data } = await groupEmployerApi.v1Paging({
				limit,
				page,
				'filter.group.uuid': `${Comparator.EQUAL}${groupId}`,
				...filters,
			});
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);

			setTotalItems(totalItems);
			return data.data;
		}
	);

	const leaveEmployerMutation = useMutation(
		async (employerId: string) => {
			const { data } = await saleSystemEmployerApi.v1LeaveGroupById(employerId, groupId);

			return data;
		},
		{
			onSuccess: (data) => {
				toast.success(data?.message);
				employerQuery.refetch();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);
	React.useEffect(() => {
		document.title = 'Sale Admin - Employer Management';
	}, []);
	return (
		<>
			<FilterEmployerDrawer />
			<div className='fade-in'>
				<PageHeader
					title='Employer Management'
					onBack={() => {
						navigate(saleAdminRouter.viewGroupDetailById.href(groupId));
					}}
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Employer Email</div>,
								width: 200,
								dataIndex: 'employer.emai',
								key: 'employer.emai',
								render: (text, record) => {
									const data = _get(record, 'employer.email', 'Empty');

									return <>{data}</>;
								},
							},

							{
								title: () => <div>Sale Service</div>,
								width: 100,
								dataIndex: 'sale.email',
								key: 'sale.email',
								render: (text, record) => {
									const data = _get(record, 'sale.email', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Scale</div>,
								width: 50,
								dataIndex: 'scale',
								key: 'scale',
								render: (text, record) => {
									const data = _get(record, 'scale', '');

									return (
										<>
											<StatusTag data={data} options={colorsEmployerScale} />
										</>
									);
								},
							},
							{
								title: () => <div>Status</div>,
								width: 50,
								dataIndex: 'status',
								key: 'status',
								render: (text, record) => {
									const data = _get(record, 'status', '');

									return (
										<>
											<StatusTag data={data} options={colorsEmployerStatus} />
										</>
									);
								},
							},
							{
								title: () => <div>Type</div>,
								width: 50,
								dataIndex: 'type',
								key: 'type',
								render: (text, record) => {
									const data = _get(record, 'type', '');

									return (
										<>
											<StatusTag data={data} options={colorsEmployerType} />
										</>
									);
								},
							},
							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY HH:MM:SS')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 50,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																navigate(
																	saleAdminRouter.viewEmployerDetailById.href({
																		employerId: record.employer.uuid,
																		groupId: record.group.uuid,
																	})
																);
															}}
														>
															View Detail
														</Menu.Item>
														<Popconfirm
															title='Are you sure to remove this employer from group?'
															onConfirm={() => {
																leaveEmployerMutation.mutate(record.employer.uuid);
															}}
														>
															<Menu.Item>Remove from group</Menu.Item>
														</Popconfirm>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={employerQuery.data?.data || []}
						isLoading={employerQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
