import { stringify } from 'query-string';
import { IPagingProps } from '../models';
import { http } from './http';

export interface IV1EmployerPagingFilters {
	company: string;
	price: string;
	employerEmail: string;
	employerPhone: string;
}

export interface IV1SaleEmployerPagingFilters {}
export interface IV1SaleEmployerPaging extends IPagingProps<IV1SaleEmployerPagingFilters> {}

export interface IV1SaleEmployerMeFilters {}
export interface IV1SaleEmployerMe extends IPagingProps<IV1SaleEmployerMeFilters> {}

export const purchaseOrderApi = {
	v1Paging: async (query: IV1SaleEmployerPaging) => {
		const res = await http.get(
			`/v1/api/purchase-order//paging?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
	v1GetInvoiceById: async (invoiceUuid: string): Promise<any> => {
		const res = await http.get(`/v1/api/purchase-order/invoice/${invoiceUuid}`);
		return res.data;
	},
};
