import { Button, Drawer } from 'antd';
import * as React from 'react';
import { useModal } from '../../../../core/contexts';
import { IV1AddGroupMember, groupMailApi } from '../../../../core/apis';
import { FormProvider, useForm } from 'react-hook-form';
import { TextInput } from '../../../../core/components/form';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

const defaultValues: IV1AddGroupMember = {
	email: '',
	name: '',
};

interface AddGroupMailMemberDrawerProps {}

export const AddGroupMailMemberDrawer: React.FC<AddGroupMailMemberDrawerProps> = () => {
	const addGroupMailMemberMethods = useModal<string>('addGroupMailMember');
	const formMethods = useForm<IV1AddGroupMember>({
		defaultValues,
	});

	const addGroupMailMemberMutation = useMutation(
		(data: IV1AddGroupMember) => {
			return groupMailApi.v1AddGroupMember(addGroupMailMemberMethods.modalValue, data);
		},
		{
			onSuccess: () => {
				toast.success('Add Group Mail Member Success');
				addGroupMailMemberMethods.handleCloseModal();
			},
			onError: () => {
				toast.error('Add Group Mail Member Failed, Please Try Again Later');
			},
		}
	);

	return (
		<Drawer
			title='Add Member'
			open={addGroupMailMemberMethods.getIsOpen()}
			onClose={addGroupMailMemberMethods.handleCloseModal}
		>
			<FormProvider {...formMethods}>
				<form
					className='space-y-4'
					onSubmit={formMethods.handleSubmit((data) => {
						addGroupMailMemberMutation.mutate(data);
					})}
				>
					<TextInput name='name' label='Name' />
					<TextInput name='email' label='Email' />
					<Button type='primary' htmlType='submit' loading={addGroupMailMemberMutation.isLoading}>
						Add Member
					</Button>
				</form>
			</FormProvider>
		</Drawer>
	);
};
