import { FilterOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Descriptions, PageHeader, Popconfirm, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saleSystemEmployerApi } from '../../../core/apis';
import { groupEmployerApi } from '../../../core/apis/groupEmployer';
import { saleSystemGroupApi } from '../../../core/apis/saleSystemGroup.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal, useTableUtil } from '../../../core/contexts';
import { Group, IResponseList } from '../../../core/models';
import { Employer } from '../../../core/models/employer';
import { adminRouter } from '../../../core/routes';
import { FilterGroupSaleDrawer } from '../groupSale/components/FilterGroupSaleDrawer';

interface AddEmployerToGroupProps {}

export const AddEmployerToGroup: React.FC<AddEmployerToGroupProps> = () => {
	const navigate = useNavigate();
	const filterModalMethods = useModal('filter');
	const { id = '' } = useParams<{ id: string }>();
	const employerQuery = useQuery<Employer>(['employer-group-service-id', id], async () => {
		const res = await groupEmployerApi.v1ById(id);

		return res.data;
	});

	const leaveGroupMutation = useMutation(
		async (groupId: string) => {
			const { data } = await saleSystemEmployerApi.v1LeaveGroupById(id, groupId);
			return data;
		},
		{
			onSuccess: (data) => {
				toast.success(data.message);
				employerQuery.refetch();
				groupQuery.refetch();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);

	const joinGroupMutation = useMutation(
		(groupUuid: string) => {
			return saleSystemGroupApi.v1JoinGroup(id, { groupUuid });
		},
		{
			onSuccess: (data) => {
				toast.success(data.message);
				employerQuery.refetch();
				groupQuery.refetch();
				navigate({
					pathname: adminRouter.employerById.href(id),
				});
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);

	const { limit, page, setTotalItems, filters } = useTableUtil();
	const groupQuery = useQuery<IResponseList<Group>>(['group', { limit, page, filters }], async () => {
		const { data } = await saleSystemGroupApi.v1GroupPaging({
			limit,
			page,
			isHaveOwner: true,
			...filters,
		});

		const totalItems: number = _get(data, 'data.meta.totalItems', 0);

		setTotalItems(totalItems);
		return data.data;
	});
	React.useEffect(() => {
		document.title = 'Add Employer Sale Management';
	}, []);
	return (
		<>
			<FilterGroupSaleDrawer />
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(adminRouter.employerById.href(id));
					}}
					title='Add Group Service'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
					]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<Space direction='vertical' className='w-full'>
						<Descriptions bordered title='Current Group Service'>
							<Descriptions.Item label='Group Name' span={2}>
								{_get(employerQuery.data?.group, 'name', 'No Data')}
							</Descriptions.Item>
						</Descriptions>
						<div className='flex justify-end'>
							{Boolean(employerQuery.data?.group) && (
								<Popconfirm
									title='Are you sure to leave this group?'
									onConfirm={async () => {
										leaveGroupMutation.mutate(_get(employerQuery.data, 'group.uuid', ''));
									}}
								>
									<Button danger icon={<MinusCircleOutlined />}>
										Leave Group
									</Button>
								</Popconfirm>
							)}
						</div>
					</Space>
				</div>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Name</div>,
								width: 100,
								dataIndex: 'name',
								key: 'name',
								render: (text, record) => {
									const data = _get(record, 'name', 'No One');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Manager Email</div>,
								width: 200,
								dataIndex: 'owner.email',
								key: 'owner.email',
								render: (text, record) => {
									const data = _get(record, 'owner.email', 'Empty');

									return <>{data}</>;
								},
							},

							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 100,
								dataIndex: 'action',
								key: 'action',
								align: 'center',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Button
												type='primary'
												icon={<PlusCircleOutlined />}
												onClick={() => {
													joinGroupMutation.mutate(_get(record, 'uuid', ''));
												}}
												disabled={employerQuery.data?.group ? true : false}
											>
												Join Group
											</Button>
										</Space>
									);
								},
							},
						]}
						data={groupQuery.data?.data.filter((item) => item.uuid !== employerQuery.data?.group?.uuid || '') || []}
						isLoading={groupQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
