import { stringify } from 'query-string';
import { IPagingProps } from '../models';
import { http } from './http';

export interface IV1EmployerPagingFilters {
	type: string;
	employerEmail: string;
	employerPhone: string;
}

export interface IV1SaleEmployerPagingFilters {}
export interface IV1SaleEmployerPaging extends IPagingProps<IV1SaleEmployerPagingFilters> {}

export interface IV1SaleEmployerMeFilters {}
export interface IV1SaleEmployerMe extends IPagingProps<IV1SaleEmployerMeFilters> {}

export const saleOrderApi = {
	v1Paging: async (query: IV1SaleEmployerPaging) => {
		const res = await http.get(
			`/v1/api/sale-order/paging?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
	v1Me: async (query: IV1SaleEmployerMe) => {
		const res = await http.get(
			`/v1/api/sale/employers/me?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
};
