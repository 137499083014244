/* eslint-disable @typescript-eslint/no-unused-vars */
import { PageHeader } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { CvAppliedCandidatesApi } from '../../../../core/apis/cvApplied.api';
import { useAuthUser } from '../../../../core/contexts';
import { UserRole } from '../../../../core/models';
import { adminRouter, saleAdminRouter, saleRouter } from '../../../../core/routes';
import QuestionCandidateItem from './components/QuestionCandidateItem';
import { Pagination as AntdPagination } from 'antd';
import { EJobCommentStatus } from '../../../../core/models/cv';
interface JobCommentByIdProps { }

export const JobCommentById: React.FC<JobCommentByIdProps> = () => {
	const navigate = useNavigate();
	const { currentUser } = useAuthUser();
	const { cvId = '' } = useParams<{ cvId: string }>();
	const [totalPageQuestion, setTotalPageQuestion] = useState<number>(1);
	const [pageQuestion, setPageQuestion] = useState<number>(1);

	const jobCommentById = useQuery<any>(['job-comment', cvId, pageQuestion], async () => {
		const { data } = await CvAppliedCandidatesApi.v1JobCommentById(5, pageQuestion, cvId);
		setTotalPageQuestion(data?.data?.meta?.totalPages);
		return data?.data;
	});

	React.useEffect(() => {
		document.title = 'Cv Applied - Job Comment';
	}, []);

	const statusCommentText: any = useMemo(
		() => ({
			[EJobCommentStatus.APPROVED]: 'Approved',
			[EJobCommentStatus.PENDING]: 'Pending',
			[EJobCommentStatus.REJECTED]: 'Rejected',
		}),
		[]
	);
	const statusCommentClass: any = useMemo(
		() => ({
			[EJobCommentStatus.APPROVED]: 'bg-[#cdffc8]',
			[EJobCommentStatus.PENDING]: 'bg-[#f9ffb6]',
			[EJobCommentStatus.REJECTED]: 'bg-[#ffadad]',
		}),
		[]
	);

	return (
		<>
			<div className='fade-in mb-4'>
				<PageHeader
					onBack={() => {
						switch (currentUser?.role) {
							case UserRole.SALE:
								navigate(saleRouter.CvApplied.href());
								break;
							case UserRole.SALE_ADMIN:
								navigate(saleAdminRouter.CvApplied.href());
								break;
							case UserRole.ADMIN_OF_SALE:
								navigate(adminRouter.CvApplied.href());
								break;
							default:
								break;
						}
					}}
					title={'Back'}
					extra={[]}
				/>
				<>
					<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
						<h2>Question from Candidate</h2>
						<div className='flex flex-col items-end'>
							{
								jobCommentById?.data?.data?.map((questionItem, index) => (
									<QuestionCandidateItem key={`questionItem-${index}`} questionItem={questionItem} statusCommentText={statusCommentText} statusCommentClass={statusCommentClass}/>
								))
							}
							<AntdPagination
								current={pageQuestion}
								total={totalPageQuestion * 5}
								pageSize={5}
								showSizeChanger={false}
								onChange={(page) => {
									setPageQuestion(page);
								}}
							/>
						</div>
					</div>
				</>
			</div>
		</>
	);
};
