import { EditOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Descriptions, PageHeader } from 'antd';
import { truncate } from 'lodash';
import _get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { saleSystemGroupApi } from '../../../core/apis';
import { groupEmployerApi } from '../../../core/apis/groupEmployer';
import { StatusTag } from '../../../core/components/tag';
import { useModal } from '../../../core/contexts';
import { Group } from '../../../core/models';
import {
	EmployerId,
	colorsEmployerScale,
	colorsEmployerStatus,
	colorsEmployerType,
} from '../../../core/models/employer';
import { saleRouter } from '../../../core/routes';
import ClaimEmployerGroupModal from './components/ClaimEmployerGroup';
import { CreatePointRequestDrawer } from './components/CreatePointRequestDrawer';
import { UpdateEmployerDrawer } from './components/UpdateEmployerDrawer';

interface SaleViewEmployerByIdProps {}

export const SaleViewEmployerById: React.FC<SaleViewEmployerByIdProps> = () => {
	const navigate = useNavigate();
	const { saleEmployerGroupUuid = '' } = useParams<{ saleEmployerGroupUuid: string }>();
	const updateModalMethods = useModal('update');
	const createPointModalMethods = useModal('createPoint');
	const userId = localStorage.getItem('userId') || '';
	const claimEmployerGroupModal = useModal<any>('claimEmployerGroup');
	const [showLeadCallNote, setShowLeadCallNote] = React.useState(false);
	const [showEmpNote, setShowEmpNote] = React.useState(false);

	const employerQuery = useQuery<EmployerId>(
		['employerById', saleEmployerGroupUuid, updateModalMethods.getIsOpen()],
		async () => {
			const res = await groupEmployerApi.v1ById(saleEmployerGroupUuid);

			return res.data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const groupDetailQuery = useQuery<Group>(
		['group-detail', employerQuery.data?.group?.uuid],
		async () => {
			const { data } = await saleSystemGroupApi.v1GroupById(employerQuery.data?.group?.uuid || '');
			return data;
		},
		{
			enabled: Boolean(employerQuery.data?.group),
			refetchOnWindowFocus: false,
		}
	);
	React.useEffect(() => {
		document.title = 'Sale - Employer Management';
	}, []);
	return (
		<>
			<CreatePointRequestDrawer />
			<UpdateEmployerDrawer refreshData={() => employerQuery.refetch()} />
			{claimEmployerGroupModal.getIsOpen() && <ClaimEmployerGroupModal refetchData={() => employerQuery.refetch()} />}
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(saleRouter.employerSaleManagement.href());
					}}
					title='Employer Detail'
					extra={[
						<Button
							key='1'
							type='default'
							icon={<EditOutlined />}
							onClick={() => {
								updateModalMethods.handleOpenModal({});
							}}
						>
							Update
						</Button>,
						<Button
							key='2'
							type='primary'
							icon={<PlusCircleOutlined />}
							onClick={() => {
								createPointModalMethods.handleOpenModal(employerQuery.data);
							}}
							disabled={!employerQuery.data?.employer ? true : false}
						>
							Create Point Request
						</Button>,
						<Button
							key='3'
							type='primary'
							icon={<PlusCircleOutlined />}
							onClick={() =>
								claimEmployerGroupModal.handleOpenModal({ saleGroupId: saleEmployerGroupUuid, userId: userId })
							}
						>
							Claim Employer
						</Button>,
						<Button
							key='4'
							type='primary'
							icon={<SearchOutlined />}
							onClick={() => {
								navigate(saleRouter.logActivityByEmployerId.href(employerQuery.data?.employer?.uuid || ''));
							}}
							disabled={!employerQuery.data?.employer ? true : false}
						>
							View Log Activity
						</Button>,
					]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className=''>
						<Descriptions bordered title='Lead Information'>
							<Descriptions.Item label='Lead Email'>{employerQuery.data?.lead?.email}</Descriptions.Item>
							<Descriptions.Item label='Lead Phone'>{employerQuery.data?.lead?.phone}</Descriptions.Item>
							<Descriptions.Item label=''>
								<></>
							</Descriptions.Item>
							<Descriptions.Item label='Call Note' span={3}>
								<div className='flex flex-row justify-between content-center w-full'>
									<div
										className={`w-3/4 ${showLeadCallNote ? 'see' : 'hide'}`}
										dangerouslySetInnerHTML={{
											__html: employerQuery.data
												? !showLeadCallNote
													? truncate(employerQuery.data.callNote, { length: 100 })
													: employerQuery.data.callNote
												: '',
										}}
									></div>
									<div className='w-1/4 flex justify-end'>
										<Button className='' onClick={() => setShowLeadCallNote(!showLeadCallNote)}>
											{showLeadCallNote ? <>hide note</> : <>see more</>}
										</Button>
									</div>
								</div>
							</Descriptions.Item>
						</Descriptions>
					</div>

					<div className=''>
						<Descriptions bordered title='Employer Information'>
							<Descriptions.Item label='Employer Name'>
								{_get(employerQuery.data, 'employer.full_name', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Employer Status'>
								<StatusTag data={_get(employerQuery.data, 'status', '')} options={colorsEmployerStatus} />
							</Descriptions.Item>
							<Descriptions.Item label='Employer Scale'>
								<StatusTag data={_get(employerQuery.data, 'scale', '')} options={colorsEmployerScale} />
							</Descriptions.Item>
							<Descriptions.Item label='Employer Type'>
								<StatusTag data={_get(employerQuery.data, 'type', '')} options={colorsEmployerType} />
							</Descriptions.Item>
							<Descriptions.Item label='Create At' span={1}>
								{moment(employerQuery.data?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
							</Descriptions.Item>
							<Descriptions.Item label='Update At' span={2}>
								{moment(employerQuery.data?.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
							</Descriptions.Item>
							<Descriptions.Item label='Employer Note' span={3}>
								<div className='flex flex-row justify-between content-center w-full'>
									<div
										className={`w-3/4 ${showEmpNote ? 'see' : 'hide'}`}
										dangerouslySetInnerHTML={{
											__html: employerQuery.data
												? !showEmpNote
													? truncate(employerQuery.data.other_note, { length: 100 })
													: employerQuery.data.other_note
												: '',
										}}
									></div>
									<div className='w-1/4 flex justify-end'>
										<Button className='' onClick={() => setShowEmpNote(!showEmpNote)}>
											{showEmpNote ? <>hide note</> : <>see more</>}
										</Button>
									</div>
								</div>
							</Descriptions.Item>
						</Descriptions>
					</div>

					<div>
						<Descriptions bordered title='Group Service'>
							<Descriptions.Item label='Group Name' span={2}>
								{_get(groupDetailQuery.data, 'name', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Manage By'>
								{_get(groupDetailQuery.data, 'owner.email', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Create At'>
								{moment(_get(groupDetailQuery.data, 'createdAt', 'No Data')).format('DD/MM/YYYY HH:mm:ss')}
							</Descriptions.Item>
						</Descriptions>
					</div>
				</div>
			</div>
		</>
	);
};
