import { stringify } from 'query-string';
import { IPagingProps } from '../models';
import { http } from './http';

export interface IV1MailerCreate {
	name: string;
	template: number;
	description: string;
}
export interface IV1MailerUpdate {
	name: string;
	template: number;
	description: string;
	file?: any;
}
export interface IV1MailerPagingFilters {}
export interface IV1MailerPaging extends IPagingProps<IV1MailerPagingFilters> {
	name?: string;
	email?: string;
}

export const sendMailManagemntApi = {
	v1MailerPaging: async (query: IV1MailerPaging) => {
		const res = await http.get(
			`/v1/api/mailer/campaigns?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res.data;
	},
	v1MailerCreate: async (object: IV1MailerCreate) => {
		const res = await http.post('/v1/api/mailer/campaigns', object, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return res;
	},
	v1MailerUpdate: async (uuid: string, object: IV1MailerUpdate) => {
		const formData = new FormData();
		formData.append('file', object.file);
		formData.append('name', object.name);
		formData.append('template', object.template as any);
		formData.append('description', object.description);

		const res = await http.put(`/v1/api/mailer/campaigns/${uuid}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return res;
	},
	v1MailerByID: async (uuid: string) => {
		const res = await http.get(`/v1/api/mailer/campaigns/${uuid}`);
		return res;
	},
	v1MailerDelete: async (uuid: string) => {
		const res = await http.delete(`/v1/api/mailer/campaigns/${uuid}`);
		return res;
	},
	v1MailerForAllMailPaging: async (uuid: string, query: IV1MailerPaging) => {
		const res = await http.get(
			`/v1/api/mailer/campaigns/${uuid}/mails?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
};
