import { Modal } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { saleSystemGroupApi } from '../../../../core/apis';
import { groupEmployerApi } from '../../../../core/apis/groupEmployer';
import { SelectInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';

interface V1LeadEmployer {
	companyName: string;
	email: string;
	phone: string;
	hotline: string;
	source: string;
}

function ClaimEmployerGroupModal({ refetchData }: { refetchData: () => void }) {
	const claimEmployerGroupModal = useModal<any>('claimEmployerGroup');
	const formMethods = useForm<V1LeadEmployer>({});
	const groupMeQuery = useQuery(
		['group-me'],
		async () => {
			const { data } = await groupEmployerApi.v1GroupMe();
			return data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const addNewLeadEmpMutation = useMutation(
		(data: any) => {
			return saleSystemGroupApi.v1ClaimEmpGroup(claimEmployerGroupModal.modalValue?.saleGroupId, {
				saleUuid: claimEmployerGroupModal.modalValue?.userId,
				groupUuid: data.groupUuid,
			});
		},
		{
			onSuccess: (data) => {
				toast.success(data.message);
				refetchData();
				claimEmployerGroupModal.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);
	const _handleSubmit = (data: any) => {
		addNewLeadEmpMutation.mutate(data);
	};
	return (
		<>
			{groupMeQuery.data && (
				<Modal
					title={'Add New Lead Employer'}
					open={claimEmployerGroupModal.getIsOpen()}
					onOk={formMethods.handleSubmit(_handleSubmit)}
					centered
					onCancel={() => claimEmployerGroupModal.handleCloseModal()}
					width={800}
				>
					<FormProvider {...formMethods}>
						<div className='w-full'>
							<SelectInput
								name='groupUuid'
								label='Group'
								options={groupMeQuery.data?.data?.map((item: any) => {
									return {
										label: item.group.name,
										value: item.group.uuid,
									};
								})}
							/>
						</div>
					</FormProvider>
				</Modal>
			)}
		</>
	);
}

export default ClaimEmployerGroupModal;
