import { IOptionItem } from './interface';

export enum UserStatus {
	ACTIVE = 'U_ACTIVE',
	REQ_PASSWORD = 'U_REQ_PASSWORD',
	VERIF_OTP = 'U_VERIF_OTP',
	BLOCK = 'U_BLOCK',
	REQUEST_CHANGE_PASSWORD = 'U_REQ_CHANGE_PASSWORD',
}

export enum UserRole {
	USER = 'U_USER',
	RECRUITER = 'U_RECRUITER',
	STAFF_RECEIPT = 'U_STAFF_RECEIPT',
	STAFF_TECH = 'U_STAFF_TECH',
	STAFF = 'U_STAFF',
	SALE = 'U_SALE',
	ADMIN = 'U_ADMIN',
	ADMIN_OF_SALE = 'U_ADMIN_OF_SALE',
	SALE_ADMIN = 'U_SALE_ADMIN',
	SUPER_ADMIN = 'U_SUPER_ADMIN',
}
export enum UserGender {
	MALE = 'U_MALE',
	FEMALE = 'U_FEMALE',
	OTHER = 'U_OTHER',
}
export interface User {
	uuid: string;
	full_name: string;
	first_name: string;
	last_name: string;
	birthday: string;
	phone: string;
	canViewCVs: boolean;
	canViewJobList: boolean;
	role: UserRole;
	gender: UserGender;
	avatar: string;
	background: string;
	country_name: string;
	country_code: string;
	city_name: string;
	district_name: string;
	ward_name: string;
	language: string;
	status: UserStatus;
	email: string;
	password: string;
	joinedGroups: {
		createdAt: string;
		updatedAt: string;
		uuid: string;
		role: string;
		group: {
			createdAt: string;
			updatedAt: string;
			uuid: string;
			name: string;
		};
	}[];
	canEditCompany: boolean;
}

export const optionsUserRole: IOptionItem[] = [
	{ value: UserRole.USER, label: 'User', data: UserRole.USER },
	{ value: UserRole.RECRUITER, label: 'Recruiter', data: UserRole.RECRUITER },
	{ value: UserRole.STAFF, label: 'Staff', data: UserRole.STAFF },
	{ value: UserRole.SALE, label: 'Sale', data: UserRole.SALE },
	{ value: UserRole.SALE_ADMIN, label: 'Sale Admin', data: UserRole.SALE_ADMIN },
	{ value: UserRole.ADMIN, label: 'Admin', data: UserRole.ADMIN },
	{
		value: UserRole.ADMIN_OF_SALE,
		label: 'Admin of Sale',
		data: UserRole.ADMIN_OF_SALE,
	},
	{ value: UserRole.SUPER_ADMIN, label: 'Super Admin', data: UserRole.SUPER_ADMIN },
];
export const optionsUserStatus = [
	{
		label: 'Active',
		value: UserStatus.ACTIVE,
	},
	{
		label: 'Block',
		value: UserStatus.BLOCK,
	},
	{
		label: 'User Request Password',
		value: UserStatus.REQ_PASSWORD,
	},
	{
		label: 'User Verification OTP',
		value: UserStatus.VERIF_OTP,
	},
	{
		label: 'Request Change Password',
		value: UserStatus.REQUEST_CHANGE_PASSWORD,
	},
];

export const optionsUserGender = [
	{
		label: 'Male',
		value: UserGender.MALE,
	},
	{
		label: 'Female',
		value: UserGender.FEMALE,
	},
	{
		label: 'Other',
		value: UserGender.OTHER,
	},
];

export const colorOptionsUserRole = [
	{ value: 'green', label: 'Sale Admin', data: UserRole.SALE_ADMIN },
	{
		value: 'blue',
		label: 'Sale',
		data: UserRole.SALE,
	},
];
