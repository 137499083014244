import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { activityApi } from '../../../core/apis';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useTableUtil } from '../../../core/contexts';
import { saleRouter } from '../../../core/routes';

interface SaleLogActivityByIdProps {}

export const SaleLogActivityById: React.FC<SaleLogActivityByIdProps> = () => {
	const { limit, page, setTotalItems, filters } = useTableUtil();
	const { employerId = '', id = '' } = useParams<{ employerId: string; id: string }>();
	const navigate = useNavigate();
	const employerQuery = useQuery<any>(
		[
			'activity',

			{
				limit,
				page,
				filters,
			},
			id,
		],
		async () => {
			const { data } = await activityApi.v1GetById(id);
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);

			setTotalItems(totalItems);
			return data.data;
		}
	);
	React.useEffect(() => {
		document.title = 'Sale - Log Active Employer Management';
	}, []);
	return (
		<>
			<div className='fade-in'>
				<PageHeader
					title='Activity By Date'
					extra={[]}
					onBack={() => {
						navigate(saleRouter.logActivityByEmployerId.href(employerId));
					}}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Message</div>,
								width: 200,
								dataIndex: 'employer.emai',
								key: 'employer.emai',
								render: (text, record) => {
									const data = _get(record, 'message', 'Empty');

									return <>{data}</>;
								},
							},

							{
								title: () => <div>Time</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const date = _get(record, 'date', 'Empty');
									const time = _get(record, 'time', 'Empty');

									return <>{`${date} ${time}`}</>;
								},
							},
							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY HH:MM:SS')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 50,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: () => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																navigate(
																	saleRouter.logActivityById.href({
																		employerId,
																	})
																);
															}}
														>
															View Detail
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={employerQuery.data?.logActivity || []}
						isLoading={employerQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
