/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuOutlined } from '@ant-design/icons';
import { Descriptions, Dropdown, Input, Menu, Modal, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FeedBackApi } from '../../../core/apis/feedBack.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { StatusTag } from '../../../core/components/tag';
import { TableUtilProvider, useModal, useTableUtil } from '../../../core/contexts';
import { IResponseList, ListResponse } from '../../../core/models';
import { colorFeedBackStatus } from '../../../core/models/employer';

interface FeedBackProps {}

const FeedBackDetailCv = () => {
	const modalMethods = useModal<any>('FeedBackDetail');
	const feedBackByIdQuery = useQuery<ListResponse>(
		['feedBackById', {}],
		async () => {
			const { data } = await FeedBackApi.v1FeedBackById(modalMethods.modalValue);
			return data;
		},
		{
			enabled: modalMethods.modalValue != undefined,
		}
	);
	return (
		<>
			{feedBackByIdQuery.data && (
				<Modal
					title={''}
					open={modalMethods.getIsOpen()}
					footer={null}
					centered
					onCancel={() => modalMethods.handleCloseModal()}
					width={800}
				>
					<Descriptions bordered title={feedBackByIdQuery.data.data?.job.title} column={2} layout='vertical'>
						<Descriptions.Item label='Job Title'>{feedBackByIdQuery.data.data?.job.title}</Descriptions.Item>
						<Descriptions.Item label='Email'>{feedBackByIdQuery.data.data?.email}</Descriptions.Item>
						<Descriptions.Item label='Rating'>{feedBackByIdQuery.data.data?.rating}</Descriptions.Item>
						<Descriptions.Item label='Status'>{feedBackByIdQuery.data.data?.status}</Descriptions.Item>
					</Descriptions>
				</Modal>
			)}
		</>
	);
};

export const FeedBack: React.FC<FeedBackProps> = () => {
	const navigate = useNavigate();
	const modalMethods = useModal('FeedBackDetail');
	const [searchParams, setSearchParams] = useSearchParams();
	const { limit, page, setTotalItems, handleOnReset, setPage } = useTableUtil();
	const [search, setSearch] = useState('');
	const feedBackQuery = useQuery<IResponseList<any>>(
		[
			'feedBack',
			{
				limit,
				page,
				search,
			},
		],
		async () => {
			const { data } = await FeedBackApi.v1FeedBack(limit, page, search);
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);
			setTotalItems(totalItems);
			return data.data;
		}
	);
	React.useEffect(() => {
		document.title = 'Feeb Back';
	}, []);

	return (
		<>
			<TableUtilProvider>
				<FeedBackDetailCv />
			</TableUtilProvider>
			<div className='fade-in'>
				<PageHeader
					title='List Feeb Back'
					extra={[
						<Input
							className='w-full'
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							placeholder='search...'
						/>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Job</div>,
								width: 100,
								dataIndex: 'job.title',
								key: 'job.title',
								render: (text, record) => {
									const data = _get(record, 'job.title', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 100,
								dataIndex: 'email',
								key: 'email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'email', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Rating</div>,
								width: 100,
								dataIndex: 'rating',
								key: 'rating',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'rating', 'Empty');
									return <>{data}</>;
								},
							},
							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Status</div>,
								width: 100,
								dataIndex: 'status',
								key: 'status',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'status', 'Empty');
									return (
										<>
											<StatusTag data={data} options={colorFeedBackStatus} />
										</>
									);
								},
							},
							// {
							// 	title: () => <div>Action</div>,
							// 	width: 50,
							// 	align: 'center',
							// 	dataIndex: 'action',
							// 	key: 'action',
							// 	render: (text, record) => {
							// 		const uuid = _get(record, 'uuid', 'Empty');
							// 		return (
							// 			<Space size='middle' key={record.uuid}>
							// 				<Dropdown
							// 					overlay={
							// 						<Menu>
							// 							<Menu.Item
							// 								onClick={() => {
							// 									const params = {};
							// 									searchParams.forEach((value, key) => {
							// 										params[key] = value;
							// 									});
							// 									if (uuid) {
							// 										modalMethods.handleOpenModal(uuid);
							// 									}
							// 								}}
							// 								key={record.uuid}
							// 							>
							// 								View Detail
							// 							</Menu.Item>
							// 						</Menu>
							// 					}
							// 				>
							// 					<a>
							// 						<MenuOutlined />
							// 					</a>
							// 				</Dropdown>
							// 			</Space>
							// 		);
							// 	},
							// },
						]}
						data={feedBackQuery.data?.data || []}
						isLoading={feedBackQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
