import { IOptionItem } from './interface';

export enum ESaleOrder {
	BUY_CV = 'BUY_CV',
	POST_NEWS = 'POST_NEWS',
	SUGGEST = 'SUGGEST',
	POST_JOB = 'POST_JOB',
	TRADING = 'TRADING',
	TAG = 'TAG',
}
export const typeSaleOrder: IOptionItem[] = [
	{
		value: ESaleOrder.BUY_CV,
		label: 'Buy CV',
		data: ESaleOrder.BUY_CV,
	},
	{
		value: ESaleOrder.POST_NEWS,
		label: 'Post News',
		data: ESaleOrder.POST_NEWS,
	},
	{
		value: ESaleOrder.SUGGEST,
		label: 'Suggest',
		data: ESaleOrder.SUGGEST,
	},
	{
		value: ESaleOrder.POST_JOB,
		label: 'Post job',
		data: ESaleOrder.POST_JOB,
	},
	{
		value: ESaleOrder.TRADING,
		label: 'Trading',
		data: ESaleOrder.TRADING,
	},
	{
		value: ESaleOrder.TAG,
		label: 'Tag',
		data: ESaleOrder.TAG,
	},
];

export enum EPaymentMethod {
    TRANSFER = 1,
    VNPAY = 2,
    POINT_REQUEST_APPROVED = 3,
    REFERRAL = 4,
	POINT_REFUND_REPORT_APPROVED = 5,
  }