import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IV1StaffCreate, IV1StaffUpdate, staffApi } from '../../../../core/apis/staff.api';
import { SelectInput, TextInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import { User, UserRole } from '../../../../core/models';
import { optionsUserRole } from '../models';

const defaultValues: IV1StaffUpdate = {
	email: '',
	phone: '',
	role: UserRole.SALE,
	last_name: '',
	first_name: '',
};

const schema = yup.object().shape({
	email: yup.string().email().required('Email is required'),
	phone: yup.string().max(10).required('Phone is required'),
	role: yup.string().required('Role is required'),
	last_name: yup.string().required('Last name is required'),
	first_name: yup.string().required('First name is required'),
});

interface EditSaleEmployerDrawerProps {}

export const EditSaleEmployerDrawer: React.FC<EditSaleEmployerDrawerProps> = () => {
	const editModalMethod = useModal<User>('edit');
	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});
	const createStaffMutation = useMutation(
		(data: IV1StaffCreate) => staffApi.v1UpdateById(editModalMethod.modalValue?.uuid, data),
		{
			onSuccess: () => {
				toast.success('Update sale employer successfully');
				formMethods.reset({ ...defaultValues });
				editModalMethod.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);

	React.useEffect(() => {
		if (editModalMethod.modalValue) {
			formMethods.setValue('email', editModalMethod.modalValue?.email);
			formMethods.setValue('phone', editModalMethod.modalValue?.phone);
			formMethods.setValue('role', editModalMethod.modalValue?.role);
			formMethods.setValue('last_name', editModalMethod.modalValue?.last_name);
			formMethods.setValue('first_name', editModalMethod.modalValue?.first_name);
		}
	}, [editModalMethod.modalValue]);

	const _handleOnSubmit = (data: IV1StaffCreate) => createStaffMutation.mutate(data);

	return (
		<Drawer
			open={editModalMethod.getIsOpen()}
			title='Update Sale Employer'
			onClose={() => editModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='email' label='Email' />
							<TextInput name='last_name' label='Last Name' />
							<TextInput name='first_name' label='First Name' />
							<TextInput name='phone' label='Phone' />
							<SelectInput name='role' label='Role' options={optionsUserRole} />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Update
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};
