/* eslint-disable @typescript-eslint/no-unused-vars */
import { FilterOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { purchaseOrderApi } from '../../../core/apis/purchaseOrder.api';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal, useTableUtil } from '../../../core/contexts';
import { IResponseList } from '../../../core/models';
import { typeSaleOrder } from '../../../core/models/saleOrder';
import { FilterEmployerDrawer } from './components/FilterSaleEmployerDrawer';
import { formMoneyVnd } from '../../../core/utils';
import { EPaymentMethod } from '../../../core/models/purchaseOrder';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { adminRouter } from '../../../core/routes';

interface PurchaseOrderAdminMgt { }

export const PurchaseOrderAdminMgt: React.FC<PurchaseOrderAdminMgt> = () => {
	const filterModalMethods = useModal('filter');
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { limit, page, setTotalItems, filters } = useTableUtil();

	const purchaseOrderQuery = useQuery<IResponseList<any>>(
		[
			'purchaseOrder',
			{
				limit,
				page,
				filters,
			},
		],
		async () => {
			const { data } = await purchaseOrderApi.v1Paging({
				limit,
				page,
				...filters,
			});
			const totalItems: number = _get(data, 'data.meta.totalItems', 0);

			setTotalItems(totalItems);
			return data.data;
		}
	);
	React.useEffect(() => {
		document.title = 'Sale Admin - Purchase Order';
	}, []);

	return (
		<>
			<FilterEmployerDrawer />

			<div className='fade-in'>
				<PageHeader
					title='Purchase Order Management'
					extra={[
						<Button
							key='1'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>Package</div>,
								width: 100,
								dataIndex: 'package.id',
								key: 'package.id',
								render: (text, record) => {
									const value = _get(record, 'value', 'Empty') || 0;
									const price = _get(record, 'price', 'Empty') || 0;
									const formatValue = `${value}  (${formMoneyVnd(price)})`
									return <>{formatValue}</>;
								},
							},
							{
								title: () => <div>Company Name</div>,
								width: 100,
								dataIndex: 'company_name',
								key: 'company_name',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'company_name', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email user</div>,
								width: 100,
								dataIndex: 'user.email',
								key: 'user.email',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'user.email', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Status</div>,
								width: 100,
								dataIndex: 'uuid',
								key: 'uuid',
								align: 'center',
								render: (text, record) => {
									const isApprove = _get(record, 'is_approved', 'Empty');
									const isDelete = _get(record, 'is_deleted', 'Empty');
									const formatValue = isApprove ? 'Approved' : isDelete ? 'Deleted' : 'Pending'

									return (
										<>
											{formatValue}
										</>
									);
								},
							},
							{
								title: () => <div>Approve Date</div>,
								width: 100,
								dataIndex: 'approved_date',
								key: 'approved_date',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'approved_date', 'Empty') || 'Not approve yet';

									return (
										<>
											{data}
										</>
									);
								},
							},
							{
								title: () => <div>PO NO</div>,
								width: 100,
								dataIndex: 'po_no',
								key: 'po_no',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'po_no', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Payment method</div>,
								width: 100,
								dataIndex: 'paymentMethod',
								key: 'paymentMethod',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'paymentMethod', 'Empty');

									return <>{
										Number(data) === EPaymentMethod.TRANSFER
											? 'TRANSFER'
											: Number(data) === EPaymentMethod.VNPAY
												? 'VNPAY'
												: Number(data) === EPaymentMethod.REFERRAL
													? 'REFERRAL'
													: Number(data) === EPaymentMethod.POINT_REFUND_REPORT_APPROVED
														? 'REPORT CV'
														: 'SALE'
									}</>;
								},
							},
							{
								title: () => <div>Created At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 50,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																const params = {};

																searchParams.forEach((value, key) => {
																	params[key] = value;
																});
																if (record?.purchaseOrderInvoice) {
																	navigate({
																		pathname: adminRouter.viewInvoicePurchaseDetailById.href(record?.purchaseOrderInvoice.uuid),
																		search: createSearchParams({ ...params }).toString(),
																	});
																}
															}}
														>
															View Invoice
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={purchaseOrderQuery.data?.data || []}
						isLoading={purchaseOrderQuery.isLoading}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};
