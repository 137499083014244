/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'antd';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ProfileFindNewApi } from '../../../../core/apis/cvManagement.api';
import { TextInput } from '../../../../core/components/form';
import CheckBox from '../../../../core/components/form/CheckBox';
import { useModal } from '../../../../core/contexts';

interface TickAndNoteModalProps {
	refetchData: () => void;
}

function TickAndNoteModal({ refetchData }: TickAndNoteModalProps) {
	const tickAndNoteMethods = useModal<any>('tickAndNote');
	const schema = yup.object().shape({});

	const formMethods = useForm<any>({
		defaultValues: {
			saleNote: '',
			isSaleTicked: true,
		},
		resolver: yupResolver(schema),
	});
	const tickAndNoteMutiation = useMutation(
		(data: any) => ProfileFindNewApi.v1PutTickAndNote(tickAndNoteMethods.modalValue?.uuid, data),
		{
			onSuccess: () => {
				toast.success('success');
				refetchData();
				tickAndNoteMethods.handleCloseModal();
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const _handleSubmit = (data: any) => {
		tickAndNoteMutiation.mutate(data);
	};
	useEffect(() => {
		if (tickAndNoteMethods.modalValue !== undefined) {
			formMethods.setValue('saleNote', tickAndNoteMethods.modalValue?.saleNote);
			formMethods.setValue('isSaleTicked', tickAndNoteMethods.modalValue?.isSaleTicked);
		}
	}, [tickAndNoteMethods.modalValue]);
	return (
		<div>
			{tickAndNoteMethods.modalValue !== undefined && (
				<Modal
					title={'Tick & Note'}
					open={tickAndNoteMethods.getIsOpen()}
					onOk={formMethods.handleSubmit(_handleSubmit)}
					centered
					onCancel={() => tickAndNoteMethods.handleCloseModal()}
					width={800}
				>
					<FormProvider {...formMethods}>
						<form className='w-full'>
							<TextInput name='saleNote' label='Note' placeholder='note..' />
							<CheckBox
								name='isSaleTicked'
								content={'Tick'}
								checkBoxProps={{
									value: tickAndNoteMethods.modalValue?.isSaleTicked,
									defaultChecked: tickAndNoteMethods.modalValue?.isSaleTicked,
									onChange: (e) => {
										formMethods.setValue('isSaleTicked', e.target.checked);
									},
								}}
							/>
						</form>
					</FormProvider>
				</Modal>
			)}
		</div>
	);
}
export default TickAndNoteModal;
