import { Descriptions, PageHeader } from 'antd';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ProfileFindNewApi } from '../../../core/apis/cvManagement.api';
import { adminRouter } from '../../../core/routes';

interface ViewCvManamentByIdProps {}

export const ViewCvManamentById: React.FC<ViewCvManamentByIdProps> = () => {
	const navigate = useNavigate();
	const { cvId = '' } = useParams<{ cvId: string }>();
	let [searchParams] = useSearchParams();
	const profileByIdQuery = useQuery<any>(
		['cv-detail', cvId],
		async () => {
			const { data } = await ProfileFindNewApi.v1ProfileById(cvId);
			return data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	React.useEffect(() => {
		document.title = 'Cv Management - Detail';
	}, []);
	const formatLevel = (data: number) => {
		switch (data) {
			case 0:
				return 'Mới học';
			case 1:
				return 'Mới bắt đầu';
			case 2:
				return 'Trung bình';
			case 3:
				return 'Nâng cao';
			case 4:
				return 'Chuyên gia';
			default:
				break;
		}
	};
	return (
		<>
			<div className='fade-in mb-4'>
				<PageHeader
					onBack={() => {
						const params = {};

						searchParams.forEach((value, key) => {
							params[key] = value;
						});

						navigate({
							pathname: adminRouter.cvManagement.href(),
							search: `?${createSearchParams(params)}`,
						});
					}}
					title='Cv Management Detail'
					extra={[]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className='max-w'>
						<Descriptions bordered title='Basic Information'>
							<Descriptions.Item label='Name'>{profileByIdQuery.data?.full_name}</Descriptions.Item>
							<Descriptions.Item label='Birth Day'>
								{moment(profileByIdQuery.data?.birth_day).format('DD/MM/YYYY')}
							</Descriptions.Item>
							<Descriptions.Item label='Gender'>{profileByIdQuery.data?.gender}</Descriptions.Item>
							<Descriptions.Item label='Email'>{profileByIdQuery.data?.email}</Descriptions.Item>
							<Descriptions.Item label='Phone'>{profileByIdQuery.data?.phone}</Descriptions.Item>
							<Descriptions.Item label='Salary'>{profileByIdQuery.data?.salary}</Descriptions.Item>
							<Descriptions.Item label='Address'>{profileByIdQuery.data?.address}</Descriptions.Item>
							<Descriptions.Item label='Industry sectors'>{profileByIdQuery.data?.specialized}</Descriptions.Item>
							<Descriptions.Item label='Level'>{profileByIdQuery.data?.level}</Descriptions.Item>
							<Descriptions.Item label='Type Job'>{profileByIdQuery.data?.type_job}</Descriptions.Item>
						</Descriptions>
						<Descriptions bordered title='' className='mt-4'>
							<Descriptions.Item label='Career Goals'>
								<div dangerouslySetInnerHTML={{ __html: profileByIdQuery.data?.career_goals }}></div>
							</Descriptions.Item>
						</Descriptions>
						<Descriptions bordered title='' className='mt-4'>
							<Descriptions.Item label='Educations'>
								{profileByIdQuery.data?.educations?.map((item: any, index: number) => {
									return (
										<div key={index}>
											<div>
												<span className='font-medium'>School: </span>
												{item?.school_name}
											</div>
											<div>
												<span className='font-medium'>Major: </span> {item?.major}
											</div>
											<div
												dangerouslySetInnerHTML={{
													__html: item?.description,
												}}
												className='mt-2'
											></div>
										</div>
									);
								})}
							</Descriptions.Item>
						</Descriptions>
						<Descriptions bordered title='' className='mt-4'>
							<Descriptions.Item label='Work Experience'>
								{profileByIdQuery.data?.work_experience?.map((item: any, index: number) => {
									return (
										<div
											key={index}
											className={
												profileByIdQuery?.data?.work_experience?.length < 2
													? ''
													: 'border-b border-solid border-slate-100 p-2 mt-2'
											}
										>
											<div>
												<span className='font-medium'>Position: </span>
												{item?.position}
											</div>
											<div>
												<span className='font-medium'>Time: </span> {moment(item?.startAt).format('MM/YYYY')} -{' '}
												{item?.isCurrent == 1 ? 'Now' : moment(item?.endAt).format('MM/YYYY')}
											</div>
											<div
												dangerouslySetInnerHTML={{
													__html: item?.description,
												}}
												className='mt-2'
											></div>
										</div>
									);
								})}
							</Descriptions.Item>
						</Descriptions>
						<Descriptions bordered title='' className='mt-4'>
							<Descriptions.Item label='Certifications'>
								{profileByIdQuery.data?.certifications?.map((item: any, index: number) => {
									return (
										<div
											key={index}
											className={
												profileByIdQuery?.data?.certifications?.length < 2
													? ''
													: 'border-b border-solid border-slate-100 p-2 mt-2'
											}
										>
											<div
												dangerouslySetInnerHTML={{
													__html: item?.certification,
												}}
												className='mt-2'
											></div>
										</div>
									);
								})}
							</Descriptions.Item>
						</Descriptions>
						<Descriptions bordered title='' className='mt-4'>
							<Descriptions.Item label='Skills'>
								{profileByIdQuery.data?.skills?.map((item: any, index: number) => {
									return (
										<div
											key={index}
											className={
												profileByIdQuery?.data?.skills?.length < 2
													? ''
													: 'border-b border-solid border-slate-100 p-2 mt-2'
											}
										>
											<div>
												<span className='font-medium'>Name: </span>
												{item?.name}
											</div>
											<div>
												<span className='font-medium'>Level: </span> {formatLevel(item?.level)}
											</div>
											<div
												dangerouslySetInnerHTML={{
													__html: item?.description,
												}}
												className='mt-2'
											></div>
										</div>
									);
								})}
							</Descriptions.Item>
						</Descriptions>
						<Descriptions bordered title='' className='mt-4'>
							<Descriptions.Item label='Languages'>
								{profileByIdQuery.data?.languages?.map((item: any, index: number) => {
									return (
										<div
											key={index}
											className={
												profileByIdQuery?.data?.languages?.length < 2
													? ''
													: 'border-b border-solid border-slate-100 p-2 mt-2'
											}
										>
											<div>
												<span className='font-medium'>Name: </span>
												{item?.language.value}
											</div>
											<div>
												<span className='font-medium'>Level: </span> {item?.languageLevel.name}
											</div>
										</div>
									);
								})}
							</Descriptions.Item>
						</Descriptions>
					</div>
				</div>
			</div>
		</>
	);
};
