import { Button, Drawer } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IV1UpdateGroupMail, groupMailApi } from '../../../../core/apis';
import { TextInput, TextareaInput } from '../../../../core/components/form';
import { useModal } from '../../../../core/contexts';
import { GroupMail } from '../../../../core/models';

const defaultValues: IV1UpdateGroupMail = {
	name: '',
	description: '',
};

interface UpdateGroupMailDrawerProps {}

export const UpdateGroupMailDrawer: React.FC<UpdateGroupMailDrawerProps> = () => {
	const formMethods = useForm<IV1UpdateGroupMail>({
		defaultValues,
	});
	const updateGroupMailMethods = useModal<GroupMail>('updateGroupMail');
	const params = useParams<{
		groupMailId: string;
	}>();

	React.useEffect(() => {
		if (updateGroupMailMethods.modalValue) {
			formMethods.setValue('name', updateGroupMailMethods.modalValue.name);
			formMethods.setValue('description', updateGroupMailMethods.modalValue.description);
		}
	}, [updateGroupMailMethods.modalValue]);

	const updateGroupMailMutation = useMutation(
		(data: IV1UpdateGroupMail) => {
			return groupMailApi.v1UpdateGroupMail(params?.groupMailId || '', data);
		},
		{
			onSuccess: () => {
				toast.success('Update Group Mail Success');
				updateGroupMailMethods.handleCloseModal();
			},
			onError: () => {
				toast.error('Update Group Mail Failed, Please Try Again Later');
			},
		}
	);

	return (
		<Drawer
			title='Update Group Mail'
			open={updateGroupMailMethods.getIsOpen()}
			onClose={updateGroupMailMethods.handleCloseModal}
		>
			<FormProvider {...formMethods}>
				<form
					className='space-y-4'
					onSubmit={formMethods.handleSubmit((data) => {
						updateGroupMailMutation.mutate(data);
					})}
				>
					<TextInput name='name' label='Name' />
					<TextareaInput name='description' label='Description' />
					<Button type='primary' htmlType='submit' loading={updateGroupMailMutation.isLoading}>
						Update
					</Button>
				</form>
			</FormProvider>
		</Drawer>
	);
};
