import { IOptionItem, UserRole } from '../../../../core/models';

export const optionsUserRole: IOptionItem[] = [
	{
		data: UserRole.SALE,
		label: 'Sale',
		value: UserRole.SALE,
	},
	{
		data: UserRole.SALE_ADMIN,
		label: 'Sale Admin',
		value: UserRole.SALE_ADMIN,
	},
];
