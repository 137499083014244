import { User } from '../models';
import { http } from './http';

export interface IV1StaffCreate extends Pick<User, 'email' | 'first_name' | 'last_name' | 'phone' | 'role'> {}
export interface IV1StaffUpdate extends Pick<User, 'email' | 'first_name' | 'last_name' | 'phone' | 'role'> {}

export const staffApi = {
	v1Create: async (data: IV1StaffCreate) => {
		return http.post('/v1/api/user/staff', data);
	},
	v1UpdateById: async (id: string, data: IV1StaffUpdate) => {
		return http.put(`/v1/api/user/update/${id}`, data);
	},
};
