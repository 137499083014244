import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'antd';
import Cookies from 'js-cookie';
import _get from 'lodash/get';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { IV1LoginDTO, userApi } from '../../core/apis';
import { TextInput } from '../../core/components/form';
import { CONSTANTS } from '../../core/constants';
import { useAuthUser } from '../../core/contexts';
import { UserRole } from '../../core/models';
import { adminRouter, saleAdminRouter, saleRouter } from '../../core/routes';
import { authRoleUser } from '../../core/utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const defaultValues: IV1LoginDTO = {
	email: '',
	password: '',
};

const schema: SchemaOf<IV1LoginDTO> = yup.object().shape({
	email: yup.string().required('Email is required'),
	password: yup.string().required('Password is required'),
});

interface AuthLoginPageProps {}

export const AuthLoginPage: React.FC<AuthLoginPageProps> = () => {
	const formMethods = useForm<IV1LoginDTO>({
		defaultValues,
		resolver: yupResolver(schema),
	});
	const navigate = useNavigate();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const auth = useAuthUser();
	const _handleOnSubmit = async (input: IV1LoginDTO) => {
		if (!executeRecaptcha) {
			return;
		}
		executeRecaptcha('signIn').then((gReCaptchaToken) => {
			const objectLogin = {
				email: input.email,
				password: input.password,
				recaptchaValue: gReCaptchaToken,
			};
			mutation.mutate(objectLogin);
		});
	};
	const mutation = useMutation(
		(input: { email: string; password: string; recaptchaValue: string }) => userApi.v1Login(input),
		{
			onSuccess: (data) => {
				const accessToken = _get(data, 'data.data.access_token', '');
				const refreshToken = _get(data, 'data.data.refresh_token', '');
				const role = _get(data, 'data.data.role', '');
				Cookies.set(CONSTANTS.ACCESS_TOKEN_KEY, accessToken);
				Cookies.set(CONSTANTS.REFRESH_TOKEN_KEY, refreshToken);
				Cookies.set(CONSTANTS.ROLE, role);
				auth.getCurrentUser();
				authRoleUser(role);
			},
			onError: () => {
				toast.error('Username or password is incorrect');
			},
		}
	);
	useEffect(() => {
		if (auth.isTryLogin && auth.currentUser) {
			if (auth.currentUser.role === UserRole.SALE_ADMIN) {
				window.location.pathname = saleAdminRouter.home.href();
			}
			if (auth.currentUser.role === UserRole.ADMIN_OF_SALE) {
				window.location.pathname = adminRouter.home.href();
			}
			if (auth.currentUser.role === UserRole.SALE) {
				window.location.pathname = saleRouter.home.href();
			}
		}
	}, [auth.isTryLogin, auth.currentUser, navigate]);
	useEffect(() => {
		const accessToken = Cookies.get(CONSTANTS.ACCESS_TOKEN_KEY);
		const roleUser = Cookies.get(CONSTANTS.ROLE);
		if (accessToken && roleUser) {
			if (roleUser === UserRole.SALE_ADMIN) {
				window.location.pathname = saleAdminRouter.home.href();
			}
			if (roleUser === UserRole.ADMIN_OF_SALE) {
				window.location.pathname = adminRouter.home.href();
			}
			if (roleUser === UserRole.SALE) {
				window.location.pathname = saleRouter.home.href();
			}
		}
	}, []);

	return (
		<div className=''>
			<div className='p-6 bg-white rounded-lg w-80'>
				<h1>Login Account</h1>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)} className='space-y-4'>
						<TextInput name='email' label='Email' />
						<TextInput name='password' label='Password' type='password' />
						<Button loading={mutation.isLoading} htmlType='submit'>
							Login
						</Button>
					</form>
				</FormProvider>
			</div>
		</div>
	);
};
